(function () {
  'use strict';

  angular
    .module('support')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.support', {
        url: '/support',
        templateUrl: 'support/views/support.tpl.html',
        controller: 'SupportCtrl',
        controllerAs: 'support'
      });
  }
}());
