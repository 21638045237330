(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name editvenue.controller:EditvenueCtrl
   *
   * @description
   *
   */
  angular
    .module('editvenue')
    .controller('EditvenueCtrl', EditvenueCtrl);

  function EditvenueCtrl(Api, $modal, $state, $stateParams, $log) {
    var vm = this;
    vm.ctrlName = 'EditvenueCtrl';

    vm.venue = {};
    vm.submitted = false;
    var venueid = $stateParams.venueid;
    var orgid = $stateParams.orgid;

    vm.socialMediaAccounts = [{
      title: 'Facebook Page',
      example: 'eg. https://www.facebook.com/Liquidedge/',
      value: '',
      key: 'fbpage'
    }];

    vm.status = {
      isopen: false
    };

    // vm.selectImage = function() {
    //
    //   vm.modalMessage = {
    //     orgid: orgid
    //   };
    //
    //   var modalInstance = $modal.open({
    //     templateUrl: 'selectimage/views/selectimage.tpl.html',
    //     controller: 'SelectimageCtrl as selectimage',
    //     resolve: {
    //       message: function() {
    //         return vm.modalMessage;
    //       }
    //     }
    //   });
    //
    //   modalInstance.result.then(function(image) {
    //     var thisImage = angular.fromJson(image);
    //     vm.venue.imageUrl = thisImage.imageuri;
    //     vm.venue.imageId = thisImage.id;
    //   }, function() {
    //     console.log('canceled');
    //   });
    // };

    vm.uploadImage = function() {
      vm.modalMessage = {
        orgid: orgid
      };

      var modalInstance = $modal.open({
        templateUrl: 'uploadorgimg/views/uploadorgimg.tpl.html',
        controller: 'UploadorgimgCtrl as modal',
        resolve: {
          message: function() {
            return vm.modalMessage;
          }
        }
      });

      modalInstance.result.then(function(image) {
        console.log(image);
        vm.venue.imageUrl = image.imageUri;
        vm.venue.imageId = image.id;
        console.log(vm.venue);
        console.log('success');
      }, function() {
        console.log('canceled');
      });
    };

    vm.updateVenue = function(valid) {
      vm.submitted = true;
      if (valid) {
        vm.venue.details = {};
        vm.socialMediaAccounts.forEach(function(item){
          if(item.value.length > 0) {
            vm.venue.details[item.key] = item.value;
          }
        });
        console.log(vm.venue);
        Api.updateVenue(venueid, vm.venue).success(function(data) {
          console.log(data);
          $state.go('admin.venues');
        }, function(err) {
          console.log(err);
        });
      }
    };

    function getVenue(venueid) {
      Api.getVenue(venueid).then(function(resp) {
        $log.info(resp);
        vm.venue.venueTitle = resp.data.venue.venuetitle;
        vm.venue.imageUrl = resp.data.venue.imageuri;
        vm.venue.imageId = resp.data.venue.imageid;
        vm.venue.contactEmail = resp.data.venue.contactemail;
        vm.venue.contactPhone = resp.data.venue.contactphone;
        vm.venue.description = resp.data.venue.description;
        vm.venue.location = resp.data.venue.location;
        vm.venue.website =  resp.data.venue.website;
        vm.venue.details = resp.data.venue.details;
        vm.venue.siteid = resp.data.venue.siteid;
        vm.venue.trapid = resp.data.venue.trapid;
        vm.socialMediaAccounts.forEach(function(item){
          if(vm.venue.details[item.key]) {
            item.value = vm.venue.details[item.key];
          }
        });
      }, function(err) {
        $log.info(err);
      });
    }

    getVenue(venueid);
  }
}());
