(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:Tandc
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .factory('Tandc', Tandc);

  function Tandc(baseurl, baseendpoint, $http, Auth) {
    var TandcBase = {},
        token = Auth.getAuthData().token;

    TandcBase.createTandC = function (tandc) {
      var data = {
        tandc: tandc.tandc
      };
      if (tandc.venue) {
        return $http.post(baseurl + baseendpoint + '/tandc/org/' + tandc.org + '?venue=' + tandc.venue, data, {
          headers: {
            'x-access-token': token
          }
        });
      }
      return $http.post(baseurl + baseendpoint + '/tandc/org/' + tandc.org, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TandcBase.getTandCbyVenue = function (org, venue) {
      return $http.get(baseurl + baseendpoint + '/tandc/org/' + org + '/venue/' + venue, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TandcBase.getTandCbyOrg = function (org) {
      return $http.get(baseurl + baseendpoint + '/tandc/org/' + org, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TandcBase.updateTandC = function (tandc) {
      return $http.put(baseurl + baseendpoint + '/tandc/' + tandc.id + '/org/' + tandc.org, tandc, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TandcBase.deleteTandC = function (tandc) {
      return $http.delete(baseurl + baseendpoint + '/tandc/' + tandc.id + '/org/' + tandc.org, {
        headers: {
          'x-access-token': token
        }
      });
    };

    return TandcBase;
  }
}());
