(function () {
  'use strict';

  /* @ngdoc object
   * @name mailListModal
   * @description
   *
   */
  angular
    .module('mailListModal', [
      'ui.router'
    ]);
}());
