(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name twilioModal.controller:TwilioModalCtrl
   *
   * @description
   *
   */
  angular
    .module('twilioModal')
    .controller('TwilioModalCtrl', TwilioModalCtrl);

  function TwilioModalCtrl($modalInstance, config) {
    var vm = this;
    vm.config = config;
    console.log(vm.config);
    vm.message = '';
    vm.remainingLength = 140;
    vm.setLimit = function(){
      vm.remainingLength = 140 - vm.message.length;
    };


    vm.ok = function () {
      $modalInstance.close(vm.message);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
