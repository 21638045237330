<div id="editsurvey">
  <h2>Edit Survey</h2>
  <div class="panel panel-default evcConf">
    <!-- <div class="panel-heading">
    </div> -->
    <div class="panel-body">
        <div ng-show="!editsurvey.surveyLoaded">
          <p> No Survey found for this event. </p>
          <button ng-click="editsurvey.addSurvey()" class="btn btn-danger" tooltip-placement="right" tooltip="Add Survey"><i class="fa fa-plus" aria-hidden="true">Add Survey</i></button>
        </div>
        <div class="form-group">
          <div ng-show="editsurvey.surveyLoaded">
          Survey Enabled <input type="checkbox" id="surveyCheckbox" name="surveyCheckbox" ng-model="editsurvey.isActive"/>
          <div ng-repeat="survey in editsurvey.surveyObjects">
            <div class="panel panel-default panel-form" ng-show="editsurvey.surveyObjects[survey.id].show">
              <div class="panel-heading clearfix">
                <h5>Question:</h5>
                <input type="text" class="form-control" ng-model="editsurvey.surveyObjects[survey.id].survey.question" placeholder="eg. Which event are you on?" required>
              </div>
              <div class="panel-body">
                <div class="form-group">
                  <input type="text" class="form-control" ng-model="editsurvey.answers[survey.id].answers[0].answer" placeholder="Option A">
                  <input type="text" class="form-control" ng-model="editsurvey.answers[survey.id].answers[1].answer" placeholder="Option B">
                  <input type="text" class="form-control" ng-model="editsurvey.answers[survey.id].answers[2].answer" placeholder="Option C">
                  <input type="text" class="form-control" ng-model="editsurvey.answers[survey.id].answers[3].answer" placeholder="Option D">
                </div>
              </div>
            </div>
          </div>
          <div>
            <button ng-if="editsurvey.numberOfQuestions < editsurvey.numberOfQuestionsTotal" ng-click="editsurvey.addQuestion()" class="btn btn-danger" tooltip-placement="right" tooltip="Add Question"><i class="fa fa-plus" aria-hidden="true"></i></button>
            <button ng-if="editsurvey.numberOfQuestions > 0" ng-click="editsurvey.removeQuestion()" class="btn btn-danger" tooltip-placement="right" tooltip="Remove Question"><i class="fa fa-minus" aria-hidden="true"></i></button>
            <button ng-click="editsurvey.updateSurvey()" class="btn btn-danger" tooltip-placement="right" tooltip="Update Survey"><span class="fa fa-pencil-square-o" aria-hidden="true"></span></button>
            <button class="btn btn-danger btn-md" ng-click="editsurvey.deleteSurvey()" tooltip-placement="left" tooltip="Delete Survey">
              <span class="glyphicon glyphicon-trash" aria-hidden="true"></span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
