(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisations.controller:OrgdeletemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('modalservice')
    .controller('BulkApCreateModalCtrl', BulkApCreateModalCtrl);

  function BulkApCreateModalCtrl($modalInstance, Api, infrastructure, aps, $cookies) {
    var vm = this;
    vm.ctrlName = 'BulkApCreateModalCtrl';
    vm.controller = infrastructure;
    vm.accesspoints = [];
    vm.aps = [];
    vm.zones = [];
    vm.selected = [];
    vm.error = '';
    vm.selectAllButton = 'Select All';

    vm.getZones = function () {
      Api.getZones(vm.controller.venue).success(function(data){
        data.zones.forEach(function(item){
          if(item.details.wlangroup){
            vm.zones.push(item);
          } else if (item.details.siteid){
            item.details.wlangroup = item.details.siteid;
            vm.zones.push(item);
          }
        });
      }).error(function(error){
        vm.error = 'There was a problem Creating your Accesspoints. Please check you have create all the required Zones.';
      });
    };

    vm.getAps= function () {
      Api.getAccessPoints(vm.controller.venue).success(function(data){
        data.apoints.forEach(function(item){
            vm.accesspoints.push(item);
        });
        vm.getApoints();
      }).error(function(error){
        vm.error = 'There was a problem Creating your Accesspoints. Please check you have create all the required Zones.';
      });
    };

    vm.getApoints = function () {
      if (aps.length > 1) {
        var siteIndex;
        for(siteIndex = 0; siteIndex < aps.length; siteIndex++) {
          var ap = aps[siteIndex];
          ap.status = 'Select';
          vm.accesspoints.forEach(function(item){
            if (item.hwaddr === ap.mac) {
              ap.status = 'Created';
            }
          });
          vm.aps.push(ap);
        }
      } else {
        Api.getApoints(vm.controller.provider, vm.controller).success(function(data){
          var cookie = $cookies.get('unifitoken');
          if (cookie === undefined) {
            var expireDate =  new Date((new Date()).getTime() + 10*60000);
            $cookies.put('unifitoken', data.cookie, {'expires': expireDate});
          }
          var siteIndex;
          for(siteIndex = 0; siteIndex < data.data.length; siteIndex++) {
            var ap = data.data[siteIndex];
            if (ap.type === 'uap') {
              ap.status = 'Select';
              vm.accesspoints.forEach(function(item){
                if (item.hwaddr === ap.mac) {
                  ap.status = 'Created';
                }
              });
              vm.aps.push(ap);
            }
          }
        }).error(function(error){
          vm.error = 'There was a problem Creating your Accesspoints. Please check you have create all the required Zones.';
        });
      }
    };
    vm.getZones();
    vm.getAps();

    vm.selectedSite = function(ap) {
      vm.selected.push(ap);
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.aps.length; siteIndex++) {
        if (ap.mac ===  vm.aps[siteIndex].mac) {
          vm.aps[siteIndex].status = 'Selected';
        }
      }
    };

    vm.deselectSite = function(ap) {
      vm.selectAllButton = 'Select All';
      var selectIndex;
      for(selectIndex = 0; selectIndex < vm.selected.length; selectIndex++) {
        if (ap.mac ===  vm.selected[selectIndex].mac) {
          vm.selected.splice(selectIndex, selectIndex+1);
        }
      }
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.aps.length; siteIndex++) {
        if (ap.mac ===  vm.aps[siteIndex].mac) {
          vm.aps[siteIndex].status = 'Select';
        }
      }
    };

    vm.selectAll = function() {
      var siteIndex;
      if (vm.selectAllButton === 'Select All') {
        for(siteIndex = 0; siteIndex < vm.aps.length; siteIndex++) {
          if (vm.aps[siteIndex].status === 'Select') {
            vm.selected.push(vm.aps[siteIndex]);
            vm.aps[siteIndex].status = 'Selected';
          }
        }
        vm.selectAllButton = 'Deselect All';
      } else {
        for(siteIndex = 0; siteIndex < vm.aps.length; siteIndex++) {
          if (vm.aps[siteIndex].status === 'Selected') {
            vm.selected = [];
            vm.aps[siteIndex].status = 'Select';
          }
        }
        vm.selectAllButton = 'Select All';
      }
    };

    vm.bulkCreate = function() {
      var bulk = {
        venueId: vm.controller.venue,
        accesspoints: []
      };
      var siteIndex;
      var ap = {};
      console.log(vm.selected);
      vm.selected.forEach(function(item){
        for(siteIndex = 0; siteIndex < vm.zones.length; siteIndex++) {
          if (item.wlangroup_id_na === vm.zones[siteIndex].details.wlangroup || item.wlangroup_id_ng === vm.zones[siteIndex].details.wlangroup || item.wlangroup === vm.zones[siteIndex].details.wlangroup) {
            ap = {
              hwAddr: item.mac,
              ipAddress: item.ip,
              sshUsername: '',
              sshPassword: '',
              name: item.name,
              zoneId: vm.zones[siteIndex].id
            };
            bulk.accesspoints.push(ap);
          }
        }
      });
      Api.bulkAccessPoint(vm.controller.venue, vm.controller.provider, bulk).success(function(data){
          data = {};
          $modalInstance.close();
        }).error(function(error){
          vm.error = 'There was a problem Creating your Accesspoints. Please check you have create all the required Zones.';
        });
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
