(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.constant:baseurl
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .constant('baseurl', 'https://api.liquidedge.io/operator-api');

}());
