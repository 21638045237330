<h3>Conent Options</h3>
<div class="row">
  <div class="col-xs-9 col-sm-6 col-ms-6">
    <a href="#" class="thumbnail img-caption">
      <img src="http://placehold.it/250x150" alt="...">
    </a>
    <div class="caption">
      <p class="text-center">Text Box</p>
    </div>
  </div>
  <div class="col-xs-9 col-sm-6 col-ms-6">
    <a href="#" class="thumbnail img-caption">
      <img src="http://placehold.it/250x150" alt="...">
    </a>
    <div class="caption">
      <p class="text-center">Image</p>
    </div>
  </div>
  <div class="col-xs-9 col-sm-6 col-ms-6">
    <a href="#" class="thumbnail img-caption">
      <img src="http://placehold.it/250x150" alt="...">
    </a>
    <div class="caption">
      <p class="text-center">Adverisment</p>
    </div>
  </div>
  <div class="col-xs-9 col-sm-6 col-ms-6">
    <a href="#" class="thumbnail img-caption">
      <img src="http://placehold.it/250x150" alt="...">
    </a>
    <div class="caption">
      <p class="text-center">Banner Advertisment</p>
    </div>
  </div>
</div>