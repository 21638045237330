(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:Auth
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .factory('Auth', Auth)
    .run(function ($rootScope, Auth, $cookies, baseurl, adminbase) {
      var rootScope = $rootScope;
      //static url for images
      rootScope.staticUrl = baseurl + '/';
      rootScope.adminStaticUrl = adminbase + '/';

      rootScope.$on('$stateChangeStart', function (event, toState) {
        var requireLogin = toState.data.requireLogin;
        if ($cookies.get('leData')) {
          Auth.setIslogged(true);
        }
        if (requireLogin && Auth.getIslogged() === false) {
          event.preventDefault();
        }
      });
    });

  function Auth($cookies, $http, baseurl, baseendpoint) {
    var AuthBase = {},
        isLogged = false;

    AuthBase.factoryName = 'Auth';

    AuthBase.getIslogged = function () {
      return isLogged;
    };

    AuthBase.setIslogged = function (state) {
      isLogged = state;
    };

    AuthBase.getAuthData = function () {
      var data;

      if ($cookies.get('unifitoken')) {
        $cookies.remove('unifitoken');
      }

      if ($cookies.get('leData')) {
        data = $cookies.getObject('leData');
      }

      return data;
    };

    AuthBase.login = function (data) {
      return $http.post(baseurl + baseendpoint + '/operators/session', data);
    };
    return AuthBase;
  }
}());
