<div class="panel panel-default admin">
  <div class="panel-heading">
    <div class="row row-align">
      <h3>Cloud Service
        <button class="btn btn-md btn-danger pull-right"
        data-ui-sref="admin.addclservice({venueid: cloudservices.venueid})"
        tooltip-placement="left" tooltip="Add CloudService">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
    </div>
  </div>
  <div class="panel-body">
    <div class="row tabrow">
      <div class="col-md-3 col-md-offset-9">
        <div class="input-group">
          <div class="input-group-addon">
            <i class="fa fa-search"></i>
          </div>
          <input type="text" class="form-control"
          placeholder="Search Service" ng-model="categorySearch">
        </div>
      </div>
    </div>
    <table class="table table-responsive">
      <tbody>
        <tr dir-paginate="services in cloudservices.cloudservices | filter:categorySearch | itemsPerPage: 10">
          <td>
            <h4>Type:</h4>
            <p>{{services.type}}</p>
          </td>
          <td>
            <h4>APIKEY:</h4>
            <p>{{services.apikey}}</p>
          </td>
          <td>
            <div class="pull-right">
              <button class="btn btn-danger btn-md"
              ng-click="cloudservices.deleteEvent(services.id)" tooltip-placement="left" tooltip="Delete Cloud Service">
                <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
              </button>
              <a class="btn btn-danger btn-md"
              ui-sref="admin.editclservice({venueid: cloudservices.venueid, orgid: cloudservices.orgid, cloudservicesid: services.id})" tooltip-placement="left" tooltip="Edit Cloud Service">
                <i class="fa fa-pencil-square-o"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</div>
