(function () {
  'use strict';

  /* @ngdoc object
   * @name twilioModal
   * @description
   *
   */
  angular
    .module('twilioModal', [
      'ui.router'
    ]);
}());
