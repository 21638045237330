<div class="modal-header">
  <img class="modal-img" src="./images/login/modal_image.png">
</div>
<div class="modal-body">
  <div class="panel panel-default panel-form">
    <div class="panel-heading clearfix">
      <h4>PASSWORD</h4>
    </div>
    <div class="panel-body">
      <div class="form-group">
        <p>{{modal.message}}</p>
        <input type="password" class="form-control" id="password" name="password" ng-model="modal.password" placeholder="******" required>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button" ng-click="modal.cancel()">Cancel</button>
  <button class="btn btn-primary" type="button" ng-click="modal.ok()">OK</button>
</div>
