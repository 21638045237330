(function () {
  'use strict';

  angular
    .module('mailListModal')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('mailListModal', {
        url: '/mail-list-modal',
        templateUrl: 'mail-list-modal/views/mail-list-modal.tpl.html',
        controller: 'MailListModalCtrl',
        controllerAs: 'mailListModal'
      });
  }
}());
