(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name modalservice.controller:NetworktypemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('modalservice')
    .controller('NetworktypemodalCtrl', NetworktypemodalCtrl);

  function NetworktypemodalCtrl($modalInstance, $log, Api, infrastructure) {
    var vm = this;
    vm.ctrlName = 'NetworktypemodalCtrl';
    vm.selected = '';
    vm.infrastructure = infrastructure;
    vm.disabled = true;
    vm.error = '';

    vm.ok = function () {
      var data = {
        choice: true,
        infrastructure: vm.infrastructure
      };
      $modalInstance.close(data);
    };

    vm.no = function () {
      var data = {
        choice: false
      };
      $modalInstance.close(data);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
