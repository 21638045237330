(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.controller:HomeCtrl
   *
   * @description
   *
   */
  angular
    .module('home')
    .controller('HomeCtrl', HomeCtrl);

  function HomeCtrl($rootScope, Auth, $cookies, $state) {
    var vm = this;
    vm.ctrlName = 'HomeCtrl';

    $rootScope.bodyClass = 'home';
    vm.loginDetails={};

    vm.login = function () {
      console.log('called');
      Auth.login(vm.loginDetails).success(function (data) {
        $cookies.putObject('leData', data);
        Auth.setIslogged(true);
        $state.go('admin.events');
      })
      .error(function () {
        vm.message = {
          type: 'err',
          msg: 'Invalid Credentials. Please try again'
        };
      });
    };
  }
}());
