<div class="modal-header">
  <h4>{{bulkNetworkTypeCreateModal.controller.controlleraddress}}</h4>
</div>
<div class="modal-body">
  <div ng-show="bulkNetworkTypeCreateModal.error" class="center-error">
    <h5>{{bulkVenueCreateModal.error}}</h5>
  </div>
  <div ng-if="bulkNetworkTypeCreateModal.userGroups.length < 1 && !bulkNetworkTypeCreateModal.error" class="centered">
    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    <span class="sr-only">Loading...</span>
  </div>
  <div ng-show="bulkNetworkTypeCreateModal.userGroups.length > 0">
    <div class="scrollbox">
      <table class="table table-responsive eventsTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>User Group</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody ng-repeat="userGroup in bulkNetworkTypeCreateModal.userGroups">
          <tr>
            <td>
              {{userGroup._id}}
            </td>
            <td>
              {{userGroup.name}}
            </td>
            <td>
              <button class="btn btn-danger" ng-show="userGroup.status === 'Select'" ng-click="bulkNetworkTypeCreateModal.selectedSite(userGroup)">{{userGroup.status}}</button>
              <button class="btn btn-success" ng-show="userGroup.status === 'Selected'" ng-click="bulkNetworkTypeCreateModal.deselectSite(userGroup)">Select <i class="fa fa-check" aria-hidden="true"></i></button>
              <button class="btn btn-success" ng-show="userGroup.status === 'Created'" disabled>{{userGroup.status}}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr width="100%">
    <button class="btn btn-danger pull-right" ng-click="bulkNetworkTypeCreateModal.selectAll()">
      {{bulkNetworkTypeCreateModal.selectAllButton}}
    </button>
    </br>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button" ng-click="bulkNetworkTypeCreateModal.bulkCreate()" ng-disabled="bulkNetworkTypeCreateModal.selected.length < 1">Create Selected</button>
  <button class="btn btn-warning" type="button" ng-click="bulkNetworkTypeCreateModal.cancel()">Cancel</button>
</div>
