<div class="eventanalytics">
  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="row row-align">
        <h3>Event Analytics: {{eventanalytics.event.name}}</h3>
      </div>
    </div>
    <div class="panel-body">
      <ul class="nav nav-tabs">
        <li ng-class="{'active': (eventanalytics.tab === 'live')}" ng-hide="eventanalytics.event.eventstatus === 2"><a href="" ng-click="eventanalytics.switchTab('live')">Live</a></li>
        <li ng-class="{'active': (eventanalytics.tab === 'historic')}"><a href="" ng-click="eventanalytics.switchTab('historic')">Historic</a></li>
       <li ng-class="{'active': (eventanalytics.tab === 'users')}"><a href="" ng-click="eventanalytics.switchTab('users')">Customer Profiles</a></li>
      </ul>
      <div ng-show="eventanalytics.tab === 'live'">
        <div class="row">
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Activity</h3>
            <canvas id="line" class="chart chart-line" chart-data="eventanalytics.liveDataChart.data" chart-labels="eventanalytics.liveDataChart.labels" chart-series="eventanalytics.liveDataChart.series" chart-options="eventanalytics.liveDataChart.options" chart-colors="eventanalytics.chartColors">
            </canvas>
          </div>
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Activity By Zone
              <button ng-click="eventanalytics.showActivityZoneTable=!eventanalytics.showActivityZoneTable" class="btn btn-danger pull-right">
                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="eventanalytics.showActivityZoneTable"></i>
                <i class="fa fa-table" aria-hidden="true" ng-show="!eventanalytics.showActivityZoneTable"></i>
              </button>
            </h3>
            <div class="cWrap">
              <div ng-if="!eventanalytics.showActivityZoneTable" class="animated-class chartFront">
                <canvas id="base" class="chart chart-bar" chart-data="eventanalytics.connectedUsersBarChart.data" chart-labels="eventanalytics.connectedUsersBarChart.labels" chart-options="eventanalytics.connectedUsersBarChart.options" chart-series="eventanalytics.connectedUsersBarChart.series" chart-colors="eventanalytics.chartColors">
              </div>
              <div class="animated-class" ng-if="eventanalytics.showActivityZoneTable">
                <table class="table table-responsive table-striped table-bordered">
                  <thead>
                    <tr>
                      <th ng-repeat="labels in eventanalytics.connectedTable.zoneheaders">{{labels}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="item in eventanalytics.connectedTable.zones | orderBy:'-count'">
                      <td>{{item.name}}</td>
                      <td>{{item.count}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ng-show="eventanalytics.tab === 'historic'">
        <div class="form-row margin_top dateFix">
          <div class="form-group text-box long cell cell-pad">
            <label for="startdate" class="control-label pull-left">START DATE</label>
            <input ng-change="eventanalytics.refresh()" ng-click="eventanalytics.openCalSt()" type="text" class="form-control" min-date="eventanalytics.minDate" max-date="eventanalytics.endtime" name="startDate" datepicker-popup="{{eventanalytics.dateFormat}}" ng-model="eventanalytics.starttime" is-open="eventanalytics.status.openedSt" datepicker-options="eventanalytics.dateOptions" ng-required="true" close-text="Close" />
          </div>
          <div class="short cell">
            <button type="button" class="btn btn-date" ng-click="eventanalytics.openCalSt()">
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </div>
          <div class="form-group text-box long cell cell-pad">
            <label for="endtime" class="control-label pull-left">END DATE</label>
            <input ng-change="eventanalytics.refresh()" ng-click="eventanalytics.openCalEnd()" type="text" class="form-control" min-date="eventanalytics.starttime" max-date="eventanalytics.today" datepicker-popup="{{eventanalytics.dateFormat}}" ng-model="eventanalytics.endtime" is-open="eventanalytics.status.openedEnd" datepicker-options="eventanalytics.dateOptions" ng-required="true" close-text="Close" />
          </div>
          <div class="short cell">
            <button type="button" class="btn btn-date" ng-click="eventanalytics.openCalEnd()">
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Footfall
              <button ng-click="eventanalytics.showFootfallTable=!eventanalytics.showFootfallTable" class="btn btn-danger pull-right">
                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="eventanalytics.showFootfallTable"></i>
                <i class="fa fa-table" aria-hidden="true" ng-show="!eventanalytics.showFootfallTable"></i>
              </button>
            </h3>
            <div ng-show="eventanalytics.loading" class="loader">
              <img src="./images/gears.gif" alt="">
              <p>Generating Visitor Data</p>
              </dev>
            </div>
            <div class="cWrap" ng-hide="eventanalytics.loading">
              <div ng-if="!eventanalytics.showFootfallTable" class="animated-class chartFront">
                <canvas id="base" class="chart chart-bar" chart-data="eventanalytics.visitorsLoginData.data" chart-labels="eventanalytics.visitorsLoginData.labels" chart-colors="eventanalytics.chartColors" chart-dataset-override="eventanalytics.visitorsLoginData.datasetOverride" chart-options="eventanalytics.mixedChartOptions">
                </canvas>
              </div>
              <div class="animated-class" ng-if="eventanalytics.showFootfallTable">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th ng-repeat="header in eventanalytics.footfallTable.headers">{{header}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="item in eventanalytics.footfallTable.rows">
                      <td>{{item.type}}</td>
                      <td>{{item.newVisitors}}</td>
                      <td>{{item.returningVisitors}}</td>
                      <td>{{item.total}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-sm-2 col-sm-offset-3 col-xs-offset-2 analytics-block-sm">
            <div class="row margin-analytics-xs margin-analytics-top">
              <p>Gender</p>
              <ul class="list-unstyled gender-list">
                <li class="blueicon">
                  <strong><i class="fa fa-male" aria-hidden="true"></i> : {{eventanalytics.genderMale}}</strong>
                </li>
                <li class="pinkicon">
                  <strong><i class="fa fa-female" aria-hidden="true"></i> : {{eventanalytics.genderFemale}}</strong>
                </li>
              </ul>
            </div>
            <div class="row margin-analytics-xs">
              <p>Unique Visitors</p>
              <p class="analytictext greenicon">
                <strong><i class="fa fa-users" aria-hidden="true"></i> : {{eventanalytics.uniqueUsers}}</strong>
              </p>
            </div>
            <div class="row margin-analytics-xs">
              <p>Total Logins</p>
              <p class="analytictext darkicon">
                <strong><i class="fa fa-users" aria-hidden="true"></i> : {{eventanalytics.totalLogin}}</strong>
              </p>
            </div>
            <div class="row margin-analytics-xs">
              <p>Total Impressions</p>
              <p class="analytictext darkicon">
                <strong><i class="fa fa-mobile" aria-hidden="true"></i> : {{eventanalytics.totalImpressions}}</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Login Method
              <button ng-click="eventanalytics.showLoginTable=!eventanalytics.showLoginTable" class="btn btn-danger pull-right">
                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="eventanalytics.showLoginTable"></i>
                <i class="fa fa-table" aria-hidden="true" ng-show="!eventanalytics.showLoginTable"></i>
              </button>
            </h3>
            <div class="cWrap">
              <div ng-if="!eventanalytics.showLoginTable" class="animated-class chartFront">
                <canvas id="base" class="chart-horizontal-bar" chart-data="eventanalytics.horizontal.data" chart-labels="eventanalytics.horizontal.labels" chart-options="eventanalytics.horizontal.options" chart-series="eventanalytics.horizontal.series" chart-colors="eventanalytics.typeChartColors">
                </canvas>
              </div>
              <div class="animated-class" ng-if="eventanalytics.showLoginTable">
                <table class="table table-responsive table-striped table-bordered">
                  <thead>
                    <tr>
                      <th ng-repeat="header in eventanalytics.loginTable.headers">{{header}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="row in eventanalytics.loginTable.rows">
                      <td>{{row.type}}</td>
                      <td>{{row.count}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Gender Demographics
              <button ng-click="eventanalytics.showgenderTable=!eventanalytics.showgenderTable" class="btn btn-danger pull-right">
                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="eventanalytics.showgenderTable"></i>
                <i class="fa fa-table" aria-hidden="true" ng-show="!eventanalytics.showgenderTable"></i>
              </button>
            </h3>
            <div class="cWrap">
              <div ng-if="!eventanalytics.showgenderTable" class="animated-class chartFront">
                <canvas id="base" class="chart chart-bar" chart-data="eventanalytics.genderBarChart.data" chart-labels="eventanalytics.genderBarChart.labels" chart-options="eventanalytics.genderBarChart.options" chart-series="eventanalytics.genderBarChart.series" chart-colors="eventanalytics.genderChartColors">
              </div>
              <div class="animated-class" ng-if="eventanalytics.showgenderTable">
                <table class="table table-responsive table-striped table-bordered">
                  <thead>
                    <tr>
                      <th ng-repeat="header in eventanalytics.genderTable.headers">{{header}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="row in eventanalytics.genderTable.rows">
                      <td>{{row.type}}</td>
                      <td>{{row.count}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Splashpage Impressions
              <button ng-click="eventanalytics.showImpressionsTable=!eventanalytics.showImpressionsTable" class="btn btn-danger pull-right">
                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="eventanalytics.showImpressionsTable"></i>
                <i class="fa fa-table" aria-hidden="true" ng-show="!eventanalytics.showImpressionsTable"></i>
              </button>
            </h3>
            <div class="cWrap">
              <div ng-if="!eventanalytics.showImpressionsTable" class="animated-class chartFront">
                <canvas id="line" class="chart chart-line" chart-data="eventanalytics.splashLoadChart.data" chart-labels="eventanalytics.splashLoadChart.labels" chart-series="eventanalytics.splashLoadChart.series" chart-options="eventanalytics.splashLoadChart.options" chart-colors="eventanalytics.chartColors"></canvas>
              </div>
              <div class="animated-class" ng-if="eventanalytics.showImpressionsTable">
                <table class="table table-responsive table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Date/Time</th>
                      <th ng-repeat="series in eventanalytics.splashLoadChart.series">{{series}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="label in eventanalytics.splashLoadChart.labels">
                      <td>{{label}}</td>
                      <td ng-repeat="item in eventanalytics.splashLoadChart.data"> {{item[$parent.$index]}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <h3 class="margin-analytics-xs">Average Dwell Time
              <button ng-click="eventanalytics.showDwellTable=!eventanalytics.showDwellTable" class="btn btn-danger pull-right">
                <i class="fa fa-bar-chart" aria-hidden="true" ng-show="eventanalytics.showImpressionsTable"></i>
                <i class="fa fa-table" aria-hidden="true" ng-show="!eventanalytics.showImpressionsTable"></i>
              </button>
            </h3>
            <div ng-show="eventanalytics.dwellLoading" class="loader">
              <img src="./images/gears.gif" alt="">
              <p>Generating Visitor Data</p>
              </dev>
            </div>
            <div class="cWrap" ng-hide="eventanalytics.dwellLoading">
              <div ng-if="!eventanalytics.showDwellTable" class="animated-class chartFront">
                <canvas id="dwell" class="chart chart-bar" chart-data="eventanalytics.dwellChart.data" chart-labels="eventanalytics.dwellChart.labels" chart-options="eventanalytics.dwellChart.options" chart-series="eventanalytics.dwellChart.series" chart-colors="eventanalytics.chartColors">
                </canvas>
              </div>
              <div class="animated-class" ng-if="eventanalytics.showDwellTable">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th ng-repeat="header in eventanalytics.dwellTable.zoneheaders">{{header}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr ng-repeat="item in eventanalytics.dwellTable.zones | orderBy:'-time'">
                      <td>{{item.name}}</td>
                      <td>{{item.time}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ng-show="eventanalytics.tab === 'users'">
        <div class="form-row margin_top dateFix">
          <div class="form-group text-box long cell cell-pad">
            <label for="startdate" class="control-label pull-left">START DATE</label>
            <input ng-change="eventanalytics.refresh()" ng-click="eventanalytics.openUserCalSt()" type="text" class="form-control" min-date="eventanalytics.minDate" max-date="eventanalytics.endtime" name="startDate" datepicker-popup="{{eventanalytics.dateFormat}}" ng-model="eventanalytics.starttime" is-open="eventanalytics.status.openedUsrSt" datepicker-options="eventanalytics.dateOptions" ng-required="true" close-text="Close" />
          </div>
          <div class="short cell">
            <button type="button" class="btn btn-date" ng-click="eventanalytics.openUserCalSt()">
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </div>
          <div class="form-group text-box long cell cell-pad">
            <label for="endtime" class="control-label pull-left">END DATE</label>
            <input ng-change="eventanalytics.refresh()" ng-click="eventanalytics.openUserCalEnd()" type="text" class="form-control" min-date="eventanalytics.starttime" max-date="eventanalytics.today" datepicker-popup="{{eventanalytics.dateFormat}}" ng-model="eventanalytics.endtime" is-open="eventanalytics.status.openedUsrEnd" datepicker-options="eventanalytics.dateOptions" ng-required="true" close-text="Close" />
          </div>
          <div class="short cell">
            <button type="button" class="btn btn-date" ng-click="eventanalytics.openUserCalEnd()">
              <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="margin_top col-sm-12">
            <div class="row tabrow">
              <div class="col-sm-2">
                <button ng-click="eventanalytics.showProfileTable=!eventanalytics.showProfileTable" class="btn btn-danger">
                  <i class="fa fa-list" aria-hidden="true" ng-show="!eventanalytics.showProfileTable"></i>
                  <i class="fa fa-th-large" aria-hidden="true" ng-show="eventanalytics.showProfileTable"></i>
                </button>
                <button type="button" ng-click="eventanalytics.downloadCSV()" class="btn btn-danger">Export</button>
              </div>
              <div class="col-sm-10">
                <div class="col-sm-4">
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="fa fa-search"></i>
                    </span>
                    <input type="text" class="form-control" placeholder="Search Users" ng-model="userSearch">
                  </div>
                </div>
                <div class="col-sm-4">
                  <select class="form-control" ng-model="eventanalytics.limit" ng-options="limit for limit in eventanalytics.limits">
                    <option value="">Limit Results</option>
                  </select>
                </div>
                <div class="col-sm-4">
                  <select class="form-control" ng-model="activity" ng-options="filter.value as filter.label for filter in  eventanalytics.activityFilter" ng-change="eventanalytics.setLimit(activity)">
                    <option value>Limit by Activity</option>
                  </select>
                </div>
              </div>
            </div>
            <div ng-if="!eventanalytics.showProfileTable" class="animated-class fix">
              <div class="col-md-2 col-sm-3 cardFrame" ng-repeat="user in eventanalytics.userProfiles | orderBy:'-logins' | filter:userSearch | limitTo:eventanalytics.limit | filter: {isLive :eventanalytics.activity || undefined}">
                <div class="col-xs-12 border card">
                  <div class="row">
                    <div ng-show="user.socialdata.provider == 'google'">
                      <div class="col-xs-8 col-xs-offset-2" style="background-image: url(user.socialdata.photos[0].value); background-size : cover;">
                        <div class="liveAlert">
                          <div class="alert alert-success" role="alert" ng-if="user.isLive === 'true'">Online</div>
                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === 'false'">Offline</div>
                        </div>
                      </div>
                    </div>
                    <div ng-show="user.socialdata.provider == 'facebook'">
                      <div class="col-xs-12 bckIMG" style="background-image: url(https://graph.facebook.com/{{user.socialdata.id}}/picture?type=large); background-size : cover;">
                        <div class="liveAlert">
                          <div class="alert alert-success" role="alert" ng-if="user.isLive === 'true'">Online</div>
                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === 'false'">Offline</div>
                        </div>
                      </div>
                    </div>
                    <div ng-show="user.socialdata.provider == 'twitter'">
                      <div class="col-xs-12 bckIMG" style="background-image: url(https://twitter.com/{{user.socialdata.username}}/profile_image?size=original); background-size : cover;">
                        <div class="liveAlert">
                          <div class="alert alert-success" role="alert" ng-if="user.isLive === 'true'">Online</div>
                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === 'false'">Offline</div>
                        </div>
                      </div>
                    </div>
                    <div ng-show="!user.socialdata.provider">
                      <div class="col-xs-12 bckIMG" style="background-image: url(images/usericon.jpg); background-size : cover;">
                        <div class="liveAlert">
                          <div class="alert alert-success" role="alert" ng-if="user.isLive === 'true'">Online</div>
                          <div class="alert alert-danger" role="alert" ng-if="user.isLive === 'false'">Offline</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row rowbtm">
                    <div class="col-xs-12">
                      <a href="#/admin/userprofile/{{eventanalytics.venueid}}/{{user.id}}/{{eventanalytics.venue.infrastructure.provider}}">Name: {{user.name}}</a>
                      <div class="row">
                        <div class="col-xs-8">
                          <p>Visits: {{user.logins}}</p>
                        </div>
                        <div class="col-xs-4">
                          <a ng-show="user.socialdata.provider == 'facebook'" class="btn btn-primary btn-social-link pull-right" ng-href="{{user.socialdata.profileUrl}}" target="-_blank"><i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i></a>
                          <a ng-show="user.socialdata.provider == 'google'" class="btn btn-danger btn-social-link" ng-href="https://plus.google.com/u/0/{{user.socialdata.id}}" target="-_blank">
                            <i class="fa fa-google-plus-square fa-2x" aria-hidden="true"></i>
                            </a>
                          <a ng-show="user.socialdata.provider == 'linkedin'" class="btn btn-primary btn-social-link" ng-href="https://www.linkedin.com/in/{{user.socialdata.id}}" target="-_blank">
                            <i class="fa fa-linkedin-square fa-2x" aria-hidden="true"></i>
                            </a>
                          <a ng-show="user.socialdata.provider == 'twitter'" class="btn btn-primary btn-social-link" ng-href="https://twitter.com/{{user.socialdata.username}}" target="-_blank">
                            <i class="fa fa-twitter-square fa-2x" aria-hidden="true"></i>
                            </a>
                          <a ng-show="!user.socialdata.provider" class="btn btn-warning btn-social-link pull-right" ng-href="https://twitter.com/{{user.socialdata.username}}" target="-_blank">
                                <i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>
                                </a>
                        </div>
                      </div>
                      <div class="row">
                        <ul class="list-group tagList">
                          <li ng-repeat="tag in user.tag" class="list-group-item list-group-item-success">{{tag}}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div ng-if="eventanalytics.showProfileTable" class="animated-class">
              <table class="table table-responsive eventsTable">
                <thead>
                  <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Visits</th>
                    <th>Social Media Profile</th>
                    <th>Tags</th>
                  </tr>
                </thead>
                <tbody ng-repeat="user in eventanalytics.userProfiles | orderBy:'-logins' | filter:userSearch | limitTo:eventanalytics.limit | filter: {isLive :activity || undefined}">
                  <tr>
                    <td class="profileList">
                      <div ng-show="user.socialdata.provider == 'google'">
                        <a href="#/admin/userprofile/{{eventanalytics.venueid}}/{{user.id}}/{{eventanalytics.venue.infrastructure.provider}}">
                          <img ng-src="{{user.socialdata.photos[0].value}}" class="img-responsive" />
                        </a>
                      </div>
                      <div ng-show="user.socialdata.provider == 'facebook'">
                        <a href="#/admin/userprofile/{{eventanalytics.venueid}}/{{user.id}}/{{eventanalytics.venue.infrastructure.provider}}">
                          <img ng-src="https://graph.facebook.com/{{user.socialdata.id}}/picture?type=large" class="scale-down" />
                        </a>
                      </div>
                      <div ng-show="user.socialdata.provider == 'twitter'">
                        <a href="#/admin/userprofile/{{eventanalytics.venueid}}/{{user.id}}/{{eventanalytics.venue.infrastructure.provider}}">
                          <img ng-src="https://twitter.com/{{user.socialdata.username}}/profile_image?size=original" class="img-responsive" />
                        </a>
                      </div>
                      <div ng-show="!user.socialdata.provider">
                        <img ng-src="images/usericon.jpg" class="img-responsive" />
                      </div>
                    </td>
                    <td>
                      <a href="#/admin/userprofile/{{eventanalytics.venueid}}/{{user.id}}/{{eventanalytics.venue.infrastructure.provider}}">
                        {{user.name}}
                      </a>
                      <ul class="list-group tagList">
                        <li class="list-group-item list-group-item-success" ng-if="user.isLive === 'true'">Online</li>
                        <li class="list-group-item list-group-item-danger" ng-if="user.isLive === 'false'">Offline</li>
                      </ul>
                    </td>
                    <td>
                      <p>Visits: {{user.logins}}</p>
                    </td>
                    <td>
                      <div ng-show="user.socialdata.provider == 'facebook'">
                        <a class="btn btn-primary btn-social-link" ng-href="{{user.socialdata.profileUrl}}" target="-_blank">
                          <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div ng-show="user.socialdata.provider == 'google'">
                        <a class="btn btn-danger btn-social-link" ng-href="https://plus.google.com/u/0/{{user.socialdata.id}}" target="-_blank">
                          <i class="fa fa-google-plus-square fa-2x" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div ng-show="user.socialdata.provider == 'linkedin'">
                        <a class="btn btn-primary btn-social-link" ng-href="https://www.linkedin.com/in/{{user.socialdata.id}}" target="-_blank">
                          <i class="fa fa-linkedin-square fa-2x" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div ng-show="user.socialdata.provider == 'twitter'">
                        <a class="btn btn-primary btn-social-link" ng-href="https://twitter.com/{{user.socialdata.username}}" target="-_blank">
                          <i class="fa fa-twitter-square fa-2x" aria-hidden="true"></i>
                        </a>
                      </div>
                      <div ng-show="!user.socialdata.provider">
                        <a class="btn btn-warning btn-social-link" ng-href="" target="-_blank">
                        <i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>
                        </a>
                      </div>
                    </td>
                    <td>
                      <ul class="list-group tagList">
                        <li ng-repeat="tag in user.tag" class="list-group-item list-group-item-success">{{tag}}</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
