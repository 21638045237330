(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name tags.controller:TagmodalCtrl
   *
   * @description
   *
   */
  angular
    .module('tags')
    .controller('TagmodalCtrl', TagmodalCtrl);

  function TagmodalCtrl(data, $modalInstance) {
    var vm = this;
    vm.zones = data.zones;
    vm.aps = data.aps;
    vm.tagDescrtiption = '';
    vm.tagDuration= 0;
    vm.zoneList = [];

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

    vm.ok = function(){
      var tag = {
        name: vm.tagName,
        description: vm.tagDescription,
        duration: vm.tagDuration,
        zones: vm.zoneList
      };
      $modalInstance.close(tag);
    };
  }
}());
