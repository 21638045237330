(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name operators.controller:PasswordmodalCtrl
   *
   * @description
   *
   */
  angular
    .module('operators')
    .controller('PasswordmodalCtrl', PasswordmodalCtrl);

  function PasswordmodalCtrl($modalInstance, message) {
    var vm = this;
    vm.message = message.message;
    vm.password = '';
    vm.ok = function () {
      $modalInstance.close(vm.password);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
