(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editzone.controller:EditzoneCtrl
   *
   * @description
   *
   */
  angular
    .module('editzone')
    .controller('EditzoneCtrl', EditzoneCtrl);

  function EditzoneCtrl(Api, $stateParams, $state, $log, Auth) {
    var vm = this;
    vm.ctrlName = 'EditzoneCtrl';

    var orgid = Auth.getAuthData().operator.organisation;

    var zoneid = $stateParams.zoneid;
    var venueid = $stateParams.venueid;

    console.log('orgidedit', orgid);

    vm.zone = {};
    vm.accesspoints = [];
    vm.selectedaccesspoints = [];
    vm.originalaccesspoints = [];
    vm.provider = $stateParams.provider;

    Api.getAccessPoints($stateParams.venueid).success(function(data) {
      $log.info(data);
      vm.accesspoints = data.apoints;
      $log.info(vm.accesspoints);
    }).error(function() {
      vm.accesspoints = [];
      $log.info('get Zones error');
    });

    vm.removeAp = function(key){
      vm.selectedaccesspoints.splice(key, 1);
    };

    Api.getZone(zoneid, venueid).then(function(resp){
      vm.zone = resp.data.zone;
      vm.selectedaccesspoints = vm.zone.accesspoints;
      vm.zone.accesspoints.forEach(function(element) {
        vm.originalaccesspoints.push(element.id);
      });
      $log.info(vm.zone);
    }, function(err){
      $log.info(err);
    });

    vm.updateZone = function(){
      vm.zone.orgid = orgid;
      var addArray = [];
      vm.selectedaccesspoints.forEach(function(element) {
        addArray.push(element.id);
      });
      console.log(addArray);
      vm.apsToRemove = {
        orgId: orgid,
        venueId: venueid,
        zoneId: zoneid,
        accessPoints: vm.originalaccesspoints
      };
      vm.apsToAdd = {
        orgId: orgid,
        venueId: venueid,
        zoneId: zoneid,
        accessPoints: addArray
      };
      if (vm.apsToRemove.accessPoints.length < 1) {
        if(vm.apsToAdd.accessPoints.length < 1){
          Api.updateZone(zoneid, venueid, vm.zone).then(function(resp){
            $log.info(resp);
            $state.go('admin.managenetwork', {
              venueid: venueid,
              orgid: orgid
            });
          }, function(err){
            $log.info(err);
          });
        } else {
          Api.updateApsZone(vm.apsToAdd).then(function(resp){
            $log.info(resp);
            Api.updateZone(zoneid, venueid, vm.zone).then(function(resp){
              $log.info(resp);
              $state.go('admin.managenetwork', {
                venueid: venueid,
                orgid: orgid
              });
            }, function(err){
              $log.info(err);
            });
          }, function(err){
            $log.info(err);
          });
        }
      } else {
        Api.removeApsZone(vm.apsToRemove).then(function(resp){
          $log.info(resp);
          if(vm.apsToAdd.accessPoints.length >= 1){
            Api.updateApsZone(vm.apsToAdd).then(function(resp){
              $log.info(resp);
              Api.updateZone(zoneid, venueid, vm.zone).then(function(resp){
                $log.info(resp);
                $state.go('admin.managenetwork', {
                  venueid: venueid,
                  orgid: orgid
                });
              }, function(err){
                $log.info(err);
              });
            }, function(err){
              $log.info(err);
            });
          } else {
            Api.updateZone(zoneid, venueid, vm.zone).then(function(resp){
              $log.info(resp);
              $state.go('admin.managenetwork', {
                venueid: venueid,
                orgid: orgid
              });
            }, function(err){
              $log.info(err);
            });
          }
        }, function(err){
          $log.info(err);
        });
      }
    };

    vm.cancel = function(){
      $state.go('admin.managenetwork', {
        venueid: venueid,
        orgid: orgid
      });
    };
  }
}());
