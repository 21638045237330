(function () {
  'use strict';

  angular
    .module('events')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.events', {
        url: '/events',
        templateUrl: 'events/views/events.tpl.html',
        controller: 'EventsCtrl',
        cache: false,
        controllerAs: 'events'
      });
  }
}());
