(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name ppolicy.controller:PpolicyCtrl
   *
   * @description
   *
   */
  angular
    .module('ppolicy')
    .controller('PpolicyCtrl', PpolicyCtrl);

  function PpolicyCtrl($rootScope) {
    var vm = this;
    vm.ctrlName = 'PpolicyCtrl';
    $rootScope.bodyClass = 'home';

    var setBodyclass = function () {
      $rootScope.bodyClass = 'home';
    };

    setBodyclass();
  }
}());
