<div class="panel panel-default">
  <div class="eventPadding">
    <div class="row">
      <!-- <div class="col-xs-11 col-sm-7 col-ms-7"> -->
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Network Name</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error' : createEvent.networkName.$error}">
              <input type="text" class="form-control" id="networkname" name="networkName" ng-model="eventnetworkmodal.params.ssidname" ng-pattern="/^[a-zA-Z0-9]*$/" placeholder="eg. nowhitespace" required>
            </div>
            <div ng-messages="createEvent.networkName.$error" ng-show="createEvent.networkName.$error">
              <p class="text-danger" ng-message="required">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong> Network Name is required</strong>
              </p>
              <p class="text-danger" ng-message="pattern">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong> Network Name must not contain spaces</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Network Type</h4>
          </div>
          <div class="panel-body">
            <select class="form-control" ng-model="eventnetworkmodal.params.networkType" ng-change="eventnetworkmodal.setNetworkType(eventnetworkmodal.params.networkType)" ng-options="type.id as type.name for type in eventnetworkmodal.networkTypes">
            </select>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="eventnetworkmodal.params.provider === 1">
          <div class="panel-heading clearfix">
            <h4>Network Optimization</h4>
          </div>
          <div class="panel-body">
            <ul class="">
              <li class="checkbox" ng-repeat="category in eventnetworkmodal.optCatList">
                <label>
                  <input type="checkbox" checklist-model="eventnetworkmodal.params.categories" checklist-value="category.id"> {{category.name}}
                  <a href="" tooltip-placement="right" tooltip="{{category.description}}"> <i class="fa fa-question-circle"></i></a>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="eventnetworkmodal.provider === 1">
          <div class="panel-heading clearfix">
            <h4>Set Bandwidth Limit</h4>
          </div>
          <div class="panel-body">
            <div>
              <rzslider rz-slider-model="eventnetworkmodal.priceSlider.value" rz-slider-options="eventnetworkmodal.priceSlider.options"></rzslider>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Network Zones</h4>
            <div class="input-group">
                <div class="input-group-addon"><i class="fa fa-search"></i></div>
                <input type="text" class="form-control" placeholder="Search Zones" ng-model="zoneSearch">
            </div>
          </div>
          <div class="panel-body">
            <ul class="">
              <!-- <li class="checkbox" ng-repeat="zone in eventnetworkmodal.zoneList"> -->
              <li class="checkbox" dir-paginate="zone in eventnetworkmodal.zoneList | orderBy: 'name' | filter:zoneSearch | itemsPerPage: 10">
                <label>
                  <input type="checkbox" checklist-model="eventnetworkmodal.params.zones" checklist-value="zone.id"> {{zone.name}}
                </label>
              </li>
            </ul>
            <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>
            <div ng-show="eventnetworkmodal.zoneList.length < 1">
              <p class="text-danger">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong> Please select at least one zone</strong>
              </p>
            </div>
          </div>
        </div>
    <!--  </div> -->
      <!-- <div class="col-xs-8 col-sm-5 col-ms-5">
        <h3>Managing Application Settings</h3>
        <p>Optimize your WiFi network to suit your event</p>
        <a href="">Learn More</a>
      </div> -->
    </div>
    </div>
    <div class="panel-footer">
      <div class="pull-right">
        <button class="btn btn-default" ng-click="eventnetworkmodal.cancel()">Cancel</button>
        <button class="btn btn-default btn-danger" ng-click="eventnetworkmodal.ok()">Submit</button>
      </div>
      <div class="clearfix"></div>
    </div>

  </div>
</div>
