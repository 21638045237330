<div class="row">
  <div class="col-xs-11 col-sm-7 col-ms-7">
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Network Name</h4>
      </div>
      <div class="panel-body">
        <div class="form-group" ng-class="{ 'has-error' : createEvent.networkName.$error && duplicateevent.stepTwo}">
          <input type="text" class="form-control" id="networkname" name="networkName" ng-model="duplicateevent.event.ssidname" ng-pattern="/^[a-zA-Z0-9]*$/" placeholder="eg. nowhitespace" required>
        </div>
        <div ng-messages="createEvent.networkName.$error" ng-show="createEvent.networkName.$error && duplicateevent.stepTwo">
          <p class="text-danger" ng-message="required">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>
              Network Name is required</strong>
          </p>
          <p class="text-danger" ng-message="pattern">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>
              Network Name must not contain spaces</strong>
          </p>
        </div>
        <div ng-show="duplicateevent.ssidExists && duplicateevent.stepTwo">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>Network Name clash during selected time period</strong>
            <button class="btn btn-default btn-primary pull-right" ng-click="duplicateevent.nameCheckResult()"><i class="fa fa-question-circle-o"></i>  More Info</button>
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Network Type</h4>
      </div>
      <div class="panel-body">
        <select class="form-control" ng-model="duplicateevent.event.networkType" ng-change="duplicateevent.setNetworkType(duplicateevent.event.networkType)" ng-options="type.id as type.name for type in duplicateevent.networkTypes">
        </select>
      </div>
    </div>
    <div class="panel panel-default panel-form" ng-show="duplicateevent.provider === 1">
      <div class="panel-heading clearfix">
        <h4>Network Optimization</h4>
      </div>
      <div class="panel-body">
        <ul class="">
          <li class="checkbox" ng-repeat="category in duplicateevent.optCatList">
            <label>
              <input type="checkbox" checklist-model="duplicateevent.categories" checklist-value="category.id"> {{category.name}}
              <a href="" tooltip-placement="right" tooltip="{{category.description}}"> <i class="fa fa-question-circle"></i></a>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <div class="panel panel-default panel-form" ng-show="duplicateevent.provider === 1">
      <div class="panel-heading clearfix">
        <h4>Set Bandwidth Limit</h4>
      </div>
      <div class="panel-body">
        <div>
          <rzslider rz-slider-model="duplicateevent.priceSlider.value" rz-slider-options="duplicateevent.priceSlider.options"></rzslider>
          <p ng-show="duplicateevent.priceSlider.value === 0">Setting Zero gives the user unlimited bandwidth. </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Network Zones</h4>
      </div>
      <div class="panel-body">
        <ul class="">
          <li class="checkbox" dir-paginate="zone in duplicateevent.zoneList | orderBy: 'name' | filter:zoneSearch | itemsPerPage: 10">
            <label>
              <input type="checkbox" checklist-model="duplicateevent.zones" checklist-value="zone.id"> {{zone.name}}
            </label>
          </li>
        </ul>
        <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>
        <div ng-show="duplicateevent.zones.length < 1 && duplicateevent.stepTwo">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>
              Please select at least one zone</strong>
          </p>
        </div>
        <div ng-show="duplicateevent.ssidCount && duplicateevent.stepTwo">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>Network count exceeded</strong>
            <button class="btn btn-default btn-primary pull-right" ng-click="duplicateevent.ssidCountResult()"><i class="fa fa-question-circle-o"></i>  More Info</button>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-8 col-sm-5 col-ms-5">
    <h3>Managing Application Settings</h3>
    <p>Optimize your WiFi network to suit your event</p>
    <a href="">Learn More</a>
  </div>
</div>
</div>
<div class="panel-footer">
<div class="pull-right">
  <button class="btn btn-default" ng-click="duplicateevent.cancel()">Cancel</button>
  <button class="btn btn-default btn-danger" ng-click="duplicateevent.nextStep({network : createEvent.networkName.$error}, 'admin.duplicateevent.duplicatesplash')">Next Step</button>
</div>
<div class="clearfix"></div>
</div>
