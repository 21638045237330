(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:Tags
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .factory('Tags', Tags);

  function Tags(baseurl, baseendpoint, $http, Auth) {
    var TagsBase = {};
    var token = Auth.getAuthData().token;

    TagsBase.createTag = function (tag) {
      return $http.post(baseurl + baseendpoint + '/tags', tag, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.getTags = function (venue) {
      return $http.get(baseurl + baseendpoint + '/tags/venue/' + venue, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.getTag = function (tagid) {
      return $http.get(baseurl + baseendpoint + '/tags/' + tagid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.updateTag = function (tagid, tag) {
      return $http.put(baseurl + baseendpoint + '/tags/' + tagid, tag, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.deleteTag = function (tagid) {
      return $http.delete(baseurl + baseendpoint + '/tags/' + tagid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.getTagsByUser = function (venueid, usermac) {
      return $http.get(baseurl + baseendpoint + '/tags/venue/' + venueid + '/user/' + usermac, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.tagUser = function (tagid, usermac) {
      return $http.put(baseurl + baseendpoint + '/tags/' + tagid + '/user/' + usermac, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.getEmailList = function (venueid, data) {
      return $http.post(baseurl + baseendpoint + '/tags/venue/' + venueid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    TagsBase.bulkMailChimp = function (listid, apikey, data) {
      return $http.post(baseurl + baseendpoint + '/mailchimp/lists/' + listid + '/' + apikey, data, {
        headers: {
          'x-access-token': token
        }
      });

    };

    TagsBase.getMocks = function () {
      return $http.get('./images/mockdata/MOCK_DATA.json');
    };
    
    return TagsBase;
  }
}());
