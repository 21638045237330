(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:DeletemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('events')
    .controller('DeletemodalCtrl', DeletemodalCtrl);

  function DeletemodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'DeletemodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
