(function () {
  'use strict';

  angular
    .module('addvenuemap')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addvenuemap', {
        url: '/addvenuemap/:venueid',
        templateUrl: 'addvenuemap/views/addvenuemap.tpl.html',
        controller: 'AddvenuemapCtrl',
        controllerAs: 'addvenuemap'
      });
  }
}());
