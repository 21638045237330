(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editeventnew.controller:EventnetworkmodalCtrl
   *
   * @description
   *
   */
  angular
    .module('editeventnew')
    .controller('EventnetworkmodalCtrl', EventnetworkmodalCtrl);

  function EventnetworkmodalCtrl(params, $modalInstance, Api) {
    var vm = this;
    vm.ctrlName = 'EventnetworkmodalCtrl';
    vm.params = params;

    Api.getNetworkTypes(vm.params.venueid).then(function (response) {
      vm.networkTypes = response.data.networkTypes;
    }, function (err) {
      $log.info(err);
    });

    vm.setNetworkType = function (typeid) {
      vm.params.networktype = typeid;
      vm.categories = [];
      Api.getNetworkType(typeid, vm.params.venueid).then(function (response) {
        $log.info(response);
        if (response.data.networkType.networkStrategies.length > 0) {
          for (var i = 0; i < response.data.networkType.networkStrategies.length; i++) {
            vm.categories.push(response.data.networkType.networkStrategies[i].id);
          }
        }
      }, function (err) {
        $log.info(err);
      });
    };
    // categories setup
    function getCategories() {
      Api.getCategories(vm.provider).success(function (data) {
        vm.optCatList = data.categories;
        $log.info(vm.optCatList);
      }).error(function () {
        $log.info('get categories error');
      });
    }

    vm.getZones = function (id) {
      vm.zoneList = [];
      Api.getZones(id).success(function (data) {
        vm.zoneList = data.zones;
        var newList = [];
        for (var i = 0; i < vm.zoneList.length; i++) {
          for (var j = 0; j < vm.params.zones.length; j++) {
            if (vm.zoneList[i].id === vm.params.zones[j].zone) {
              newList.push(vm.zoneList[i]);
            }
          }
        }
        vm.params.zones = newList;
      }).error(function () {
        $log.info('get ap error');
      });
    };

    vm.getZones(vm.params.venueid);

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

    vm.ok = function () {
      $modalInstance.close(vm.params);
    };
  }
}());
