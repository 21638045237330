<div class="twilioModal">
  <div class="modal-header">
    <img class="modal-img" src="./images/login/modal_image.png">
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Message:</label>
      <textarea class="form-control" rows="5" id="comment" ng-model="TwilioModalCtrl.message" maxlength="140" ng-change="TwilioModalCtrl.setLimit()"></textarea>
    </div>
    <div class="pull-right">
      <p ng-class="{warningText:TwilioModalCtrl.remainingLength < 30 && TwilioModalCtrl.remainingLength > 10, redText:TwilioModalCtrl.remainingLength < 10, greenText:TwilioModalCtrl.remainingLength > 30}">Remaining Characters: {{TwilioModalCtrl.remainingLength}}</p>
    </div>
  </div>
  <div class="modal-footer">
    <div>
      <button class="btn btn-warning" type="button" ng-click="TwilioModalCtrl.cancel()">Cancel</button>
      <button class="btn btn-danger" type="button" ng-click="TwilioModalCtrl.ok()">Send</button>
    </div>
  </div>
</div>
