(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:Api
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .factory('Api', Api);

  function Api(baseurl, baseendpoint, adminbase, $http, $base64, Auth, $cookies) {
    var ApiBase = {};
    var adminEndpoint = '/admin-web/v1';

    ApiBase.factoryName = 'Api';

    // Images

    ApiBase.updateImage = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(baseurl + baseendpoint + '/images/' + id + '/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getImage = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/images/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getSingleImage = function (id,venue) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/images/' + id + '/venues/' + venue, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteImage = function (id, venueid) {
      var token = Auth.getAuthData().token;
      return $http.delete(baseurl + baseendpoint + '/images/' + id + '/venues/' + venueid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getImages = function () {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/images/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getImagesVenue = function (venueid) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/images/venues/' + venueid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Venues

    ApiBase.createVenue = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + '/admin-web/v1' + '/venues/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.createBulkVenue = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + '/admin-web/v1' + '/venues/bulk/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateVenue = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.put(adminbase + '/admin-web/v1' + '/venues/' + id + '/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getVenue = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint + '/venues/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteVenue = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(adminbase + '/admin-web/v1' + '/venues/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getVenues = function () {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint + '/venues/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    //events

    ApiBase.createEvent = function (data, id) {
      var token = Auth.getAuthData().token;
      return $http.post(baseurl + baseendpoint + '/events/venues/' + id, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateEvent = function (eventid, venueid, data) {
      var token = Auth.getAuthData().token;
      return $http.put(baseurl + baseendpoint + '/events/' + eventid + '/venues/' + venueid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getAllEvents = function () {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/events' + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getAllEventsByVenue = function (venueid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/events/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getEvent = function (eventid, venueid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/events/' + eventid + '/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteEvent = function (eventid, venueid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(baseurl + baseendpoint + '/events/' + eventid + '/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.checkSSIDName = function (startTime, endTime, data) {
      var token = Auth.getAuthData().token;
      return $http.post(baseurl + baseendpoint + '/events/checkssidname/' + startTime + '/' + endTime, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.checkEventCount = function (startTime, endTime, data) {
      var token = Auth.getAuthData().token;

      return $http.post(baseurl + baseendpoint + '/events/checkssidcount/' + startTime + '/' + endTime, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getAccessPoints = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint +'/apoints/venues/' + id + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getAccessPoint = function (venueid, apid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint + '/apoints/' + apid + '/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateAccesspoint = function (venueid, apid, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgid = orgid;
      return $http.put(adminbase + adminEndpoint + '/apoints/' + apid + '/venues/' + venueid + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.addAccessPoint = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgid = orgid;
      return $http.post(adminbase + adminEndpoint + '/apoints/venues/' + id + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiBase.bulkAccessPoint = function (id, providerid, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgid = orgid;
      return $http.post(adminbase + adminEndpoint + '/apoints/bulk/venues/' + id + '/provider/' + providerid + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteAccessPoint = function (apid, venueid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(adminbase + adminEndpoint + '/apoints/' + apid + '/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateApsZone = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + adminEndpoint + '/apoints/addtozone' + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.removeApsZone = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + adminEndpoint + '/apoints/removefromzone' + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getZones = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint + '/zones/venues/' + id + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiBase.getApByZone = function (venueId, zoneId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint  + '/apoints/venues/' + venueId +'/zones/' + zoneId+ '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.newZone = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + adminEndpoint  + '/zones/venues/' + id + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };
    ApiBase.bulkZone = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + adminEndpoint  + '/zones/bulk/venues/' + id + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getZone = function (zoneid, venueid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint  + '/zones/' + zoneid + '/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteZone = function (zoneid, venueid) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(adminbase + adminEndpoint  + '/zones/' + zoneid + '/venues/' + venueid + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateZone = function (zoneid, venueid, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.put(adminbase + adminEndpoint  + '/zones/' + zoneid + '/venues/' + venueid + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };


    ApiBase.getNetworkTypes = function (venueid) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint  + '/networktype/venues/' + venueid, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ApiBase.createNetworkType = function (venueid, data) {
      var token = Auth.getAuthData().token;
      return $http.post(adminbase + adminEndpoint  + '/networktype/venues/' + venueid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ApiBase.createBulkNetworkType = function (venueid, data) {
      var token = Auth.getAuthData().token;
      return $http.post(adminbase + adminEndpoint  + '/networktype/bulk/venues/' + venueid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ApiBase.getNetworkType = function (typeid, venueid) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint  + '/networktype/' + typeid + '/venues/' + venueid, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ApiBase.updateNetworkType = function (typeid, venueid, data) {
      var token = Auth.getAuthData().token;
      return $http.put(adminbase + adminEndpoint  +'/networktype/' + typeid + '/venues/' + venueid, data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    ApiBase.deleteNetworkType = function (typeid, venueid) {
      var token = Auth.getAuthData().token;
      return $http.delete(adminbase + adminEndpoint  + '/networktype/' + typeid + '/venues/' + venueid, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    // Orgoperators

    ApiBase.createOperator = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + adminEndpoint + '/operators/organisations/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.resendEmail = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgId = orgid;
      return $http.post(adminbase + adminEndpoint + '/operators/resend/' + data.id, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getOperators = function () {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint + '/operators/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getOperator = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + adminEndpoint + '/operators/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateOperator = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgId = orgid;
      return $http.put(adminbase + adminEndpoint + '/operators/' + id, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateOperatorPassword = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgId = orgid;
      return $http.put(adminbase + adminEndpoint + '/operators/' + id + '?password=true', data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteOperator = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(adminbase + adminEndpoint + '/operators/' + id + '/organisations/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Infrastructure
    ApiBase.getInfrastructures = function (id) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/infrastructure/venues/' + id, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.addInfrastructure = function (id, data) {
      var token = Auth.getAuthData().token;
      return $http.post(adminbase + adminEndpoint + '/infrastructure/venues/' + id, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getInfrastructure = function (infraid, venueid) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/infrastructure/' + infraid + '/venues/' + venueid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateInfrastructure = function (infraid, venueid, data) {
      var token = Auth.getAuthData().token;
      return $http.put(adminbase + adminEndpoint + '/infrastructure/' + infraid + '/venues/' + venueid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    //Survey
    ApiBase.createSurvey = function (data, id) {
      var token = Auth.getAuthData().token;
      return $http.post(baseurl + baseendpoint + '/survey/event/' + id, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getSurveyById = function (eventid, surveyid) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/survey/' + surveyid + '/event/' + eventid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateSurvey = function (eventid, surveyid, data) {
      var token = Auth.getAuthData().token;
      return $http.put(baseurl + baseendpoint + '/survey/' + surveyid + '/event/' + eventid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteSurvey = function (eventid, surveyid) {
      var token = Auth.getAuthData().token;
      return $http.delete(baseurl + baseendpoint + '/survey/' + surveyid + '/event/' + eventid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    //Mailchimp

    ApiBase.createList = function (data) {
      var token = Auth.getAuthData().token;
      return $http.post(baseurl + baseendpoint + '/mailchimp/lists/', data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getLists = function (apiKey) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/mailchimp/lists/' + apiKey, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getListById = function (listid, apiKey) {
      var token = Auth.getAuthData().token;
      return $http.get(baseurl + baseendpoint + '/mailchimp/lists/' + listid + '/' + apiKey, {
        headers: {
          'x-access-token': token
        }
      });
    };



    // Settings and Types
    ApiBase.getRoles = function () {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/roles', {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getCategories = function (provider) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/categories/provider/' + provider, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getSocialLogins = function (provider) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/sociallogintypes/provider/' + provider, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getSplashPageTypes = function (provider) {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/splashtypes/provider/' + provider , {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getProviders = function () {
      var token = Auth.getAuthData().token;
      return $http.get(adminbase + adminEndpoint + '/providers' , {
        headers: {
          'x-access-token': token
        }
      });
    };

    //Support, send a ticket

    ApiBase.createTicket = function (data) {
      var token = Auth.getAuthData().token;
      return $http.post(baseurl + baseendpoint + '/support/ticket/', data, {
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json'
        }
      });
    };

    // OrgInfrastructure

    ApiBase.createOrgInfrastructure = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.post(adminbase + '/admin-web/v1' + '/infrastructure/organisation/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.updateOrgInfrastructure = function (id, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.put(adminbase + '/admin-web/v1' + '/infrastructure/' + id + '/organisation/' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getOrgInfrastructure = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + '/admin-web/v1' + '/infrastructure/' + id + '/organisation/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getOrgInfrastructures = function () {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(adminbase + '/admin-web/v1' + '/infrastructure/organisation/' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.deleteOrgInfrastructure = function (id) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(adminbase + '/admin-web/v1' + '/infrastructure/'+id+'/organisation/'+orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getSites = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      if (unifitoken) {
        data.cookie = unifitoken;
      }
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/sites', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.createBulkVenues = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      data.cookie = unifitoken;
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/sites/create', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getUserGroups = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      data.cookie = unifitoken;
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/infra/usergroups', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getWlans = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      data.orgid = Auth.getAuthData().operator.organisation;
      data.cookie = unifitoken;
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/infra/wlangroups', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getInfraOrgs = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      data.cookie = unifitoken;
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/infra/orginisations', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.getApoints = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      data.cookie = unifitoken;
      data.orgid = Auth.getAuthData().operator.organisation;
      console.log(data);
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/infra/apoints', data,{
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiBase.setGuestSettings = function (id, data) {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      data.cookie = unifitoken;
      return $http.post(adminbase + '/admin-web/v1' + '/onboarding/provider/'+id+'/createinfra/guestsettings', data,{
        headers: {
          'x-access-token': token
        }
      });
    };
    return ApiBase;
  }
}());
