<h3>Layout Options</h3>
<div class="row">
  <div class="col-xs-9 col-sm-6 col-ms-6" data-drag="true" data-jqyoui-options="{revert: 'invalid'}" ng-model="eventcreation.rowTemplates[0]" jqyoui-draggable="{placeholder:'keep'}">
    <a href="#" class="thumbnail img-caption">
      <img src="http://placehold.it/250x150" alt="...">
    </a>
    <div class="caption">
      <p class="text-center">Single Column</p>
    </div>
  </div>
  <div class="col-xs-9 col-sm-6 col-ms-6" data-drag="true" data-jqyoui-options="{revert: 'invalid'}" ng-model="eventcreation.rowTemplates[1]" jqyoui-draggable="{placeholder:'keep'}">
    <a href="#" class="thumbnail img-caption">
      <img src="http://placehold.it/250x150" alt="...">
    </a>
    <div class="caption">
      <p class="text-center">Double Column</p>
    </div>
  </div>
</div>