(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editoperator.controller:EditoperatorCtrl
   *
   * @description
   *
   */
  angular
    .module('editoperator')
    .controller('EditoperatorCtrl', EditoperatorCtrl);

  function EditoperatorCtrl(Api, $log, $state, $modal, $stateParams) {
    var vm = this;
    vm.operator = {};
    vm.submitted = false;
    vm.venues = [];

    vm.updatePassword = function(id) {
      var modalInstance = $modal.open({
        templateUrl: 'editoperator/views/passwordmodal.tpl.html',
        controller: 'PasswordmodalCtrl as modal',
        resolve: {
          message: {
            message: 'Update Operator Password'
          }
        }
      });
      modalInstance.result.then(function (password) {
        var data = {
          password: password
        };
        Api.updateOperatorPassword(id, data).success(function (result) {
          $log.info(result);
        }).error(function (err) {
          $log.info(err);
        });
      }, function () {
        $log.info('canceled');
      });
    };
    Api.getRoles().success(function (data) {
      vm.roles = data.organisationRoles;
      $log.info(vm.roles);
    }).error(function (err) {
      $log.info(err);
    });

    Api.getOperator($stateParams.operatorid).success(function (data) {
      vm.operator = data.operator;
      vm.operator.roleId = data.operator.role;
      $log.info(vm.operator);
    }).error(function (err) {
      $log.info(err);
    });

    Api.getVenues().then(function(result){
      vm.venues = result.data.venues;
      console.log(vm.venues);
    }, function (err){
      console.log(err);
    });

    vm.setVenue = function(venue){
      for (var i = 0; i < vm.venues.length; i++){
        if(vm.venues[i].id === venue){
        vm.operator.details.venueName = vm.venues[i].venuetitle;
          break;
        }
      }

    };

    vm.updateOperator = function (valid) {
      vm.submitted = true;
      if (valid) {
        Api.updateOperator($stateParams.operatorid, vm.operator).success(function (data) {
          $log.info(data);
          $state.go('admin.operators');
        }).error(function (err) {
          $log.info(err);
        });
      }
    };
  }
}());
