(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name manageimages.controller:ManageimagesCtrl
   *
   * @description
   *
   */
  angular
    .module('manageimages')
    .controller('ManageimagesCtrl', ManageimagesCtrl);

  function ManageimagesCtrl($modal, Api, Auth) {
    var vm = this;

    vm.imageList = [];
    vm.limits = [1, 5, 10, 15, 20, 50, 'all'];
    vm.all = 0;
    vm.limit = 0;
    vm.venues = [];
    vm.venueId = 0;
    vm.role = 0;

    if (Auth.getAuthData()) {
      vm.profileInfo = Auth.getAuthData().operator;
      vm.role = vm.profileInfo.role;
      console.log(vm.profileInfo);
      if (vm.role === 2) {
        vm.venueId = vm.profileInfo.details.venue;
        Api.getImagesVenue(vm.venueId).then(function (res) {
          vm.imageList = res.data.images;
          vm.limit = vm.imageList.length;
          vm.all = vm.imageList.length;
        }, function (err) {
          console.log(err);
        });
      }
      else{
        Api.getVenues().success(function (data) {
          vm.venues = data.venues;
          if (vm.venues.length > 0) {
            vm.venueId = vm.venues[0].id;
            vm.getImagesByVenue(vm.venueId);
          }
        }).error(function () {
          console.log('cannot get venues');
        });
      }
    }

    vm.setLimit = function (limit) {
      console.log(limit);
      if (limit === 'all') {
        vm.limit = vm.all;
      }
    };

    // function getImages() {
    //   Api.getImages().success(function (data) {
    //     vm.imageList = data.images;
    //     vm.limit = vm.imageList.length;
    //     vm.all = vm.imageList.length;
    //     console.log(vm.imageList);
    //   });
    // }

    vm.getImagesByVenue = function (venueid) {
      Api.getImagesVenue(venueid).then(function (res) {
        vm.imageList = res.data.images;
        vm.limit = vm.imageList.length;
        vm.all = vm.imageList.length;
      }, function (err) {
        console.log(err);
      });
    };

    vm.deleteImage = function (image) {
      var modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Image, are you sure?',
        messageBody: 'Note! Images associated with a Venue/Event cannot be deleted.'
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });

      modalInstance.result.then(function () {
        Api.deleteImage(image.id, vm.venueId).success(function (resp) {
          console.log(resp);
          vm.getImagesByVenue(vm.venueId);
        }).error(function () {
          var modalMessage = {
            ok: 'Done',
            cancel: '',
            messageHeader: 'Cannot Delete Image',
            messageBody: ''
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return modalMessage;
              }
            }
          });

          modalInstance.result.then(function () {
          });
        });
      });
    };

    vm.uploadImage = function () {
      var modalInstance = $modal.open({
        templateUrl: 'manageimages/views/uploadmodal.tpl.html',
        controller: 'UploadmodalCtrl as modal',
        resolve: {
          'venue': vm.venueId
        }
      });

      modalInstance.result.then(function (image) {
        console.log('IMG', image);
        var venueid  = parseInt(image.venue);
        vm.venueId = venueid;
        vm.getImagesByVenue(vm.venueId);
      }, function () {
        console.log('canceled');
      });
    };
    //getImages();
  }
}());
