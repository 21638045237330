(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editsurvey.controller:EditsurveyCtrl
   *
   * @description
   *
   */
  angular
    .module('editsurvey')
    .controller('EditsurveyCtrl', EditsurveyCtrl);

  function EditsurveyCtrl($rootScope, $state, $stateParams, Api, $log, $modal) {
    var vm = this;
    vm.ctrlName = 'EditsurveyCtrl';
    vm.survey = [];
    vm.surveyObjects = [];
    vm.selectedSurvey = false;
    vm.numberOfQuestionsTotal = 4;
    vm.numberOfQuestions = 0;
    vm.answers = [];
    vm.surveyLoaded = false;
    vm.isActive = false;

    Api.getSurveyById($stateParams.eventid, $stateParams.surveyid).then(function (response) {
      vm.survey = response.data.survey;
      if (vm.survey !== null && vm.survey) {
        vm.surveyLoaded = true;
        setSurveyVariables();
      }
    }, function (err) {
      $log.info(err);
    });

    vm.addQuestion = function () {
      if(vm.numberOfQuestions < vm.numberOfQuestionsTotal) {
        vm.surveyObjects[vm.numberOfQuestions].show = true;
        vm.numberOfQuestions++;
      }
    };

    vm.removeQuestion = function () {
      if(vm.numberOfQuestions > 0) {
        vm.numberOfQuestions--;
        vm.surveyObjects[vm.numberOfQuestions].show = false;
      }
    };

    function setSurveyVariables() {
      vm.numberOfQuestions = vm.survey.details.survey.length;
      console.log(vm.survey);
      vm.isActive = vm.survey.isactive;
      console.log(vm.isActive);
      for (var i=0; i<vm.numberOfQuestions; i++) {
        var surveyObj = { "id": i, "show": true, survey: vm.survey.details.survey[i]};
        var answers = {answers: vm.survey.details.survey[i].answers};
        vm.answers.push(answers);
        vm.surveyObjects.push(surveyObj);
      }
      addEmptySurveyObjects();
    }

    function addEmptySurveyObjects() {
      for (var i=vm.numberOfQuestions; i<vm.numberOfQuestionsTotal; i++){
        var surveyObj = { "id": i, "show": false};
        var answers = [];
        for (var k=0; k<4; k++){
          var ansObj = {"answer": "", "count": 0};
          answers.push(ansObj);
        }
        var answersObj = {answers: answers};
        vm.answers.push(answersObj);
        vm.surveyObjects.push(surveyObj);
      }
    }

    vm.setSurveyObject = function () {
      var details = [];
      for (var i=0; i < vm.numberOfQuestions; i++) {
        if (vm.surveyObjects[i].survey !== null && vm.surveyObjects[i].survey && vm.surveyObjects[i].survey.question !== null && vm.surveyObjects[i].survey.question){
          var answers = [];
          for (var j = 0; j < vm.answers[i].answers.length; j++){
            answers.push({"answer": vm.answers[i].answers[j].answer, "count": vm.answers[i].answers[j].count});
          }
          var surveyObj = {
            "question": vm.surveyObjects[i].survey.question,
            "answers": answers
          };
          details.push(surveyObj);
        }
      }

      var surveyObj = {survey: details};
      var survey = {name: vm.survey.name, isActive: vm.isActive, details: surveyObj};
      return survey;
    };

    vm.updateSurvey = function () {
      var surveyObj = vm.setSurveyObject();
      Api.updateSurvey($stateParams.eventid, $stateParams.surveyid, surveyObj).success(function (data) {
        $log.info(data);
        $state.go('admin.events');
      }).error(function (err) {
        $log.info(err);
      });
    };

    vm.addSurvey = function () {
      $state.go('admin.addsurvey', {eventid: $stateParams.eventid});
    };

    vm.deleteSurvey = function (id) {
      var modalInstance = $modal.open({
        templateUrl: 'editsurvey/views/delsurveymodal.tpl.html',
        controller: 'DelsurveymodalCtrl as delsurveymodal'
      });

      modalInstance.result.then(function () {
        Api.deleteSurvey($stateParams.eventid, $stateParams.surveyid).success(function () {
          $state.go('admin.events');
        });
      }, function (err) {
        console.log(err);
      });
    };
  }
}());
