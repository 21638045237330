(function () {
  'use strict';
  /**
   * @ngdoc object
   * @name manageimages.controller:UploadmodalCtrl
   *
   * @description
   *
   */
  angular.module('manageimages').controller('UploadmodalCtrl', UploadmodalCtrl);

  function UploadmodalCtrl($modalInstance, Auth, baseurl, baseendpoint, Upload, $timeout, Api, venue) {
    var vm = this,
      token = '',
      venueid;

    vm.venues = [];
    vm.venueId = venue;

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
      venueid = Auth.getAuthData().operator.venueid;

    }

    Api.getVenues().success(function (data) {
      vm.venues = data.venues;
    }).error(function () {
      console.log("cannot get venues");
    });

    vm.uploadFiles = function (file) {
      vm.f = file;
      if (file) {
        file.upload = Upload.upload({
          url: baseurl + baseendpoint + '/images/venues/' + vm.venueId,
          data: {
            image: file,
            details: {
              description: vm.description
            }
          },
          headers: {
            'x-access-token': token
          }
        });
        file.upload.then(function (response) {
          $timeout(function () {
            file.result = response.data;
            vm.imageAdded = true;
            vm.image = response.data.imageURI;
            vm.imageID = response.data.id;
            response.data.venue = vm.venueId;
            $modalInstance.close(response.data);
          });
        }, function (response) {
          if (response.status > 0) {
            vm.errorMsg = response.status + ': ' + response.data;
            console.log(String(vm.errorMsg));
          }
        }, function (evt) {
          file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });
      }
    };
    vm.exit = function () {
      $modalInstance.dismiss();
    };
    vm.dismiss = function () {
      vm.message = {};
      console.log('was called');
    };
  }
}());
