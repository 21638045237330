(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:ApiSettingsFactory
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .factory('ApiSettingsFactory', ApiSettingsFactory);

  function ApiSettingsFactory(baseurl, baseendpoint, $http, $base64, Auth) {
    var ApiSettingsFactoryBase = {};

    ApiSettingsFactoryBase.factoryName = 'Api Settings';

    ApiSettingsFactoryBase.someValue = 'ApiSettingsFactory';
    ApiSettingsFactoryBase.someMethod = function () {
      return 'ApiSettingsFactory';
    };

    // AccessPoints

    ApiSettingsFactoryBase.createAp = function (venueId, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgid = orgid;
      return $http.post(baseurl + baseendpoint + '/apoints/venues/' + venueId+ '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.updateAp = function (id, venueId, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgid = orgid;
      return $http.put(baseurl + baseendpoint + '/apoints/' + id + '/venues/' + venueId+ '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.getAp = function (id, venueId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/apoints/' + id + '/venues/' + venueId+ '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.deleteAp = function (id, venueId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(baseurl + baseendpoint + '/apoints/' + id + '/venues/' + venueId+ '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.getAps = function (venueId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/apoints/venues/' + venueId+ '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };
    
    ApiSettingsFactoryBase.getApByZone = function (venueId, zoneId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/apoints/venues/' + venueId +'/zones/' + zoneId+ '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.updateApsZone = function (data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgId = orgid;
      return $http.post(baseurl + baseendpoint + '/apoints/updateapzone', data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    // Zones

    ApiSettingsFactoryBase.createZone = function (venueId, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      data.orgid = orgid;
      return $http.post(baseurl + baseendpoint + '/zones/venues/' + venueId + '?org=' + orgid, data , {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.updateZone = function (id, venueId, data) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;

      data.orgid = orgid;

      return $http.put(baseurl + baseendpoint + '/zones/' + id + '/venues/' + venueId + '?org=' + orgid, data, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.getZone = function (id, venueId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(baseurl + baseendpoint + '/zones/' + id + '/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.deleteZone = function (id, venueId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.delete(baseurl + baseendpoint + '/zones/' + id + '/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };

    ApiSettingsFactoryBase.getZones = function (venueId) {
      var token = Auth.getAuthData().token;
      var orgid = Auth.getAuthData().operator.organisation;
      //console.log(token);
      return $http.get(baseurl + baseendpoint + '/zones/venues/' + venueId + '?org=' + orgid, {
        headers: {
          'x-access-token': token
        }
      });
    };
    return ApiSettingsFactoryBase;
  }
}());
