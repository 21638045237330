(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editsurvey.controller:DelsurveymodalCtrl
   *
   * @description
   *
   */
  angular
    .module('editsurvey')
    .controller('DelsurveymodalCtrl', DelsurveymodalCtrl);

  function DelsurveymodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'DelsurveymodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
