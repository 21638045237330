(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editvenuemap.controller:EditvenuemapCtrl
   *
   * @description
   *
   */
  angular
    .module('editvenuemap')
    .controller('EditvenuemapCtrl', EditvenuemapCtrl);

  function EditvenuemapCtrl(baseurl, $stateParams, $window, Api, ApiAnalytic, $state, $scope) {
    var vm = this;
    vm.ctrlName = 'EditvenuemapCtrl';

    vm.venueid = $stateParams.venueid;
    var orgid = $stateParams.orgid;
    vm.mapId = $stateParams.mapId;
    vm.mapName = '';

    // Data Sets
    vm.accesspoints = [];
    vm.onmapaps = [];
    vm.accesspointData = [];

    // Page Objects
    var backDrop = document.getElementById('backDrop');
    var pointMap = document.getElementById('pointMap');
    var heatMapContainer = document.getElementById('heatMapContainer');

    /* Mouse Control */
    vm.dragging = false;
    vm.canvasApDrag = {};
    pointMap.addEventListener('mousedown', function(event) {
      if(vm.accesspointData.length !== 0) {
        for(var i = 0; i < vm.accesspointData.length; i++){
          // Do drag
          var a = event.offsetX - vm.accesspointData[i].x;
          var b = event.offsetY - vm.accesspointData[i].y;
          var dist = Math.sqrt( a*a + b*b );
          if (Math.abs(dist) < 10) {
            vm.canvasApDrag = vm.accesspointData[i];
            vm.accesspointData.splice(i,1);
            vm.dragging = true;
          }
          // Do clear
          if (!vm.dragging) {
            var aX = event.offsetX - (vm.accesspointData[i].x+(vm.mapWidth/32)*.6);
            var bX = event.offsetY - (vm.accesspointData[i].y-(vm.mapWidth/32)*.6);
            var distX = Math.sqrt( aX*aX + bX*bX );
            if (Math.abs(distX) < 6) {
              var ap = vm.accesspointData[i];
              vm.accesspointData.splice(i,1);
              for(var j = 0; j < vm.onmapaps.length; j++){
                if (ap.hwaddr === vm.onmapaps[j].hwaddr) {
                  vm.accesspoints.push(vm.onmapaps[j]);
                  vm.onmapaps.splice(j,1);
                  vm.draw(vm.accesspointData);
                  $scope.$apply();
                  j = vm.onmapaps.length;
                }
              }
            }
          }

        }
      }
    }, false);
    pointMap.addEventListener('mousemove', function(e) {
      if (vm.dragging){
        var x =  event.offsetX;
        var y = event.offsetY;
        if (vm.canvasApDrag !== {}) {
          vm.canvasApDrag.x = x;
          vm.canvasApDrag.y = y;
        }
        vm.draw(vm.accesspointData);
      }
    }, true);
    pointMap.addEventListener('mouseup', function(event) {
      if (vm.dragging) {
        vm.accesspointData.push(vm.canvasApDrag);
        vm.canvasApDrag = {};
        vm.dragging = false;
        vm.draw(vm.accesspointData);
      }
    }, false);

    // Context
    var context = pointMap.getContext('2d');
    var backCtx = backDrop.getContext('2d');
    backCtx.globalAlpha = 1.0;
    context.globalAlpha = 1.0;

    // Setup Data
    vm.mapHeight = 480;
    vm.mapWidth = 854;
    vm.dragingAp = {};

    // image Data
    vm.backdrop = '';
    vm.backgroundSelected = false;
    vm.backdropId = 0;
    vm.backdropUri = '';
    vm.apicon = './images/apicon.png';
    vm.imageloaded = false;
    vm.percent = 0;
    vm.originalHeight = 0;
    vm.originalWidth = 0;
    var imageObj = new Image();


    vm.getMap = function(mapId, venueid) {
      ApiAnalytic.getMap(venueid, mapId).then(function (res) {
        vm.backdrop = baseurl + '/' + res.data.map.imageUri;
        vm.backdropUri = res.data.map.imageUri;
        vm.backdropId = res.data.map.imageId;
        vm.accesspointData = res.data.map.points;
        vm.mapName = res.data.map.name;
        vm.mapId = res.data.map._id;

        vm.originalHeight = res.data.map.originalHeight;
        vm.originalWidth = res.data.map.originalWidth;

        vm.mapLoad = true;
        vm.startMap();
      }, function (err) {
        console.log(err);
      });
    };
    vm.submit = function() {
      var map = {
        'name': vm.mapName,
        'venue': vm.venueid,
        'originalHeight': vm.mapHeight,
        'originalWidth': vm.mapWidth,
        'imageId': vm.backdropId,
        'imageUri': vm.backdropUri,
        'points': vm.accesspointData
      };
      ApiAnalytic.editMap(map, vm.venueid, vm.mapId).then(function (res) {
        $state.go('admin.managenetwork', {
          'venueid': $stateParams.venueid
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.startMap = function(){
      // fetch Aps
      Api.getAccessPoints(vm.venueid).then(function (res) {
        vm.accesspoints = res.data.apoints;
        for(var i = 0;i<vm.accesspoints.length;i++){
          vm.accesspointData.forEach(function(point){
            if (point.hwaddr === vm.accesspoints[i].hwaddr) {
              vm.onmapaps.push(vm.accesspoints[i]);
              vm.accesspoints.splice(i,1);
            }
          });
        };
        vm.drawBackground();
      }, function (err) {
        console.log(err);
      });
    };

    // Drawing
    vm.draw = function(data) {
      context.clearRect(0, 0, pointMap.width, pointMap.height);
      for(var i=0; i<data.length; i++) {
        vm.drawAp(data[i]);
      }
      if(vm.dragging) {
        vm.drawAp(vm.canvasApDrag);
      }
    };
    vm.drawX = function(x, y) {
      context.beginPath();
      var size = 5;

      context.strokeStyle = '#ff0000';
      context.lineWidth = 2;

      context.moveTo(x - size, y - size);
      context.lineTo(x + size, y + size);

      context.moveTo(x + size, y - size);
      context.lineTo(x - size, y + size);

      context.stroke();
    }
    vm.drawBackground = function () {
      var background = new Image();

      background.onload = function() {
        background.height = vm.originalHeight;
        background.width = vm.originalWidth;
        if (heatMapContainer.offsetWidth < background.width) {
          var max = background.width;
          var reduced = heatMapContainer.offsetWidth;
          var diff = max - reduced;
          vm.percent = parseInt((diff/max)*100);
          background.width = reduced;
          background.height = parseInt((background.height/100)*(100 - vm.percent));
          //background.height = parseInt((background.height/100)*percent);
        } else {
          var min = background.width;
          var scaled = heatMapContainer.offsetWidth;
          var diff = scaled - min;
          vm.percent = parseInt((diff/min)*100);
          background.width = scaled;
          background.height = background.height + parseInt((background.height/100)*vm.percent);
        }

        vm.accesspointData.forEach(function(ap) {
          ap.x = ap.x*(background.width/vm.originalWidth);
          ap.y = ap.y*(background.height/vm.originalHeight);
        });


        vm.mapHeight = background.height;
        vm.mapWidth = background.width;

        pointMap.width = vm.mapWidth;
        pointMap.height = vm.mapHeight;
        backDrop.width = vm.mapWidth;
        backDrop.height = vm.mapHeight;

        $scope.$apply();

        backCtx.drawImage(background, 0,0, vm.mapWidth, vm.mapHeight);
        vm.loadIcon();
        vm.draw(vm.accesspointData);
      };
      background.src = vm.backdrop;
    };
    vm.drawAp = function(data) {
      if (vm.imageloaded) {
        context.font = (vm.mapWidth/32)/3+"px Verdana";
        context.fillStyle = 'white';
        context.strokeStyle = 'black';
        context.lineWidth = .5;
        context.drawImage(imageObj, data.x - (vm.mapWidth/32)/2, data.y - (vm.mapWidth/32)/2, vm.mapWidth/32, vm.mapWidth/32);
        context.fillText(data.name, data.x- (vm.mapWidth/32)/2, data.y+(vm.mapWidth/32)*.8);
        context.strokeText(data.name,data.x- (vm.mapWidth/32)/2,data.y+(vm.mapWidth/32)*.8);

        vm.drawX(data.x + (vm.mapWidth/32)*.6, data.y - (vm.mapWidth/32)*.6)
      }
    };

    // Image Functions
    vm.loadIcon= function () {
      imageObj.onload = function() {
        vm.imageloaded = true;
        vm.draw(vm.accesspointData);
      };
      imageObj.src = vm.apicon;
    }

    // Drag N Drop Onto Canvas
    vm.handleApDrop = function(element) {
      var ap = {
        x: element.x,
        y: element.y,
        name: vm.dragingAp.name,
        hwaddr: vm.dragingAp.hwaddr
      }
      vm.dragingAp = {};

      for(var i = 0; i < vm.accesspoints.length; i++) {
        if (ap.hwaddr === vm.accesspoints[i].hwaddr) {
          vm.accesspointData.push({
            name: ap.name,
            hwaddr: ap.hwaddr,
            x: ap.x,
            y: ap.y
          });
          vm.onmapaps.push(vm.accesspoints[i]);
          vm.accesspoints.splice(i, 1);
        }
      }
      vm.draw(vm.accesspointData);
    }


    vm.getMap(vm.mapId, vm.venueid);
  }
  angular.module('leoperations').directive('dragEditAps', function() {
    return function(scope, element) {
      var el = element[0];

      el.draggable = true;

      el.addEventListener('dragstart',function(e) {
        e.dataTransfer.effectAllowed = 'move';
        //e.dataTransfer.setData('Text', this.id);
        var ap = e.target.id.split("|");
        scope.$parent.editvenuemap.dragingAp = {
          hwaddr: ap[1],
          name: ap[0]
        };
        this.classList.add('drag');
        return false;
      },false);

      el.addEventListener('dragend', function(e) {
        this.classList.remove('drag');
        return false;
      },false);
    }
  });
  angular.module('leoperations').directive('dropEditAps', function() {
    return {
      scope: {},
      link: function(scope, element) {
        var el = element[0];

        el.addEventListener('dragover',function(e) {
          e.dataTransfer.dropEffect = 'move';
          // allows us to drop
          if (e.preventDefault) e.preventDefault();
          this.classList.add('over');
          return false;
        },false);
        el.addEventListener('dragenter',function(e) {
          this.classList.add('over');
          return false;
        },false);
        el.addEventListener('dragleave',function(e) {
          this.classList.remove('over');
          return false;
        },false);

        el.addEventListener('drop',function(e) {
          if (e.stopPropagation) e.stopPropagation();
          this.classList.remove('over');

          scope.$apply(function() {
            scope.$parent.editvenuemap.handleApDrop({
              x: e.layerX,
              y: e.layerY
            });
          });

          return false;
        },false);
      }
    }
  });
}());
