<div class="eventspage">
  <div class="hidden-sm hidden-md hidden-lg">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row row-align">
          <h5>Events</h5>
        </div>
      </div>
      <div class="panel-body">
        <ul class="nav nav-tabs tabs-xs" role="tablist">
          <li role="presentation" ng-class="{'active': (events.showCurrent)}"><a href="" ng-click="events.showArchieved && events.setEventView()" aria-controls="home" role="tab">Current Events</a></li>
          <li role="presentation" ng-class="{'active': (events.showArchieved)}"><a href="" ng-click="events.showCurrent && events.setEventView()" aria-controls="profile" role="tab" data-toggle="tab">Archived Events</a></li>
        </ul>
        <ul class="list-unstyled list-margin event-list-xs">
          <li ng-repeat="event in events.events" ng-show="event.eventstatus != 2 && event.eventstatus != 5 && events.showCurrent">

            <h5 class="mg-bt-10">{{event.venueName}}</h5>

            <div class="row">
              <div class="col-xs-4">
                <img ng-src="{{staticUrl}}{{event.eventimageuri}}" class="img-responsive" />
              </div>
              <div class="col-xs-6">
                <h5>{{event.name}}</h5>
                <p class="label label-warning" ng-show="event.eventstatus === 0">Pending</p>
                <p class="label label-success" ng-show="event.eventstatus === 1">Active</p>
                <p class="label label-danger" ng-show="event.eventstatus === 2">Ended</p>
                <button class="btn btn-danger btn-xs" ng-show="event.eventstatus === 4" ng-click="events.showErrors(event.details)"><i class="fa fa-exclamation-circle fa-2x" aria-hidden="true"></i></button>
                <button class="btn btn-warning btn-xs" ng-show="event.eventstatus === 5" ng-click="events.showErrors(event.details)">Ended with Errors</button>
              </div>
            </div>
            <div class="row">
              <div class="mgn-top-20">
                <div class="col-xs-2 no-padding pull-right">
                  <a class="btn btn-md btn-danger btn-md" ui-sref="admin.eventanalytics({venueid: event.venue, eventid: event.id})" tooltip-placement="left" tooltip="Analytics">
                    <i class="fa fa-line-chart" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!-- Set for archived events -->
        <ul class="list-unstyled list-margin event-list-xs">
          <li ng-repeat="event in events.events" ng-show="(event.eventstatus === 2 || event.eventstatus === 5) && events.showArchieved">

            <h5 class="mg-bt-10">{{event.venueName}}</h5>

            <div class="row">
              <div class="col-xs-4">
                <img ng-src="{{staticUrl}}{{event.eventimageuri}}" class="img-responsive" />
              </div>
              <div class="col-xs-6">
                <h5>{{event.name}}</h5>
                <p class="label label-warning" ng-show="event.eventstatus === 0">Pending</p>
                <p class="label label-success" ng-show="event.eventstatus === 1">Active</p>
                <p class="label label-danger" ng-show="event.eventstatus === 2">Ended</p>
                <button class="btn btn-danger btn-xs" ng-show="event.eventstatus === 4" ng-click="events.showErrors(event.details)"><i class="fa fa-exclamation-circle fa-2x" aria-hidden="true"></i></button>
                <button class="btn btn-warning btn-xs" ng-show="event.eventstatus === 5" ng-click="events.showErrors(event.details)">Ended with Errors</button>
              </div>
            </div>
            <div class="row">
              <div class="mgn-top-20">
                <div class="col-xs-2 no-padding pull-right">
                  <a class="btn btn-md btn-danger btn-md" ui-sref="admin.eventanalytics({venueid: event.venue, eventid: event.id})" tooltip-placement="left" tooltip="Analytics">
                    <i class="fa fa-line-chart" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="hidden-xs">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="row row-align">
          <h3>Events
            <button class="btn btn-md btn-danger pull-right" data-ui-sref="admin.eventcreation.evcsetup" tooltip-placement="left" tooltip="Add Event"><i class="fa fa-plus" aria-hidden="true"></i></button>
          </h3>
        </div>
      </div>
      <div class="panel-body">
        <div class="row tabrow">
          <div class="col-md-9 col-md-offset-3">
            <div class="col-md-6">
              <div class="input-group">
                <span class="input-group-addon">
                  <i class="fa fa-search"></i>
                </span>
                <input type="text" class="form-control" placeholder="Search Events" ng-model="eventSearch">
              </div>
            </div>
            <div class="col-md-6">
              <select class="form-control" ng-model="events.limit" ng-options="limit for limit in events.limits" ng-change="events.setLimit(events.limit)">
                <option value="">Limit Results</option>
              </select>
            </div>
          </div>
        </div>
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" ng-class="{'active': (events.showCurrent)}"><a href="" ng-click="events.showArchieved && events.setEventView()" aria-controls="home" role="tab">Current Events</a></li>
          <li role="presentation" ng-class="{'active': (events.showArchieved)}"><a href="" ng-click="events.showCurrent && events.setEventView()" aria-controls="profile" role="tab" data-toggle="tab">Archived Events</a></li>
        </ul>
        <table class="table table-responsive eventsTable">
          <thead>
            <tr>
              <th>Image</th>
              <th>Event</th>
              <th>Venue</th>
              <th>Duration</th>
              <th>Description</th>
            </tr>
          </thead>

          <tbody ng-repeat="event in events.events | orderBy:'-startDate' | filter:eventSearch | limitTo:events.limit" ng-show="event.eventstatus != 2 && event.eventstatus != 5 && events.showCurrent">
            <tr>
              <td class="evntIMG">
                <div>
                  <img ng-src="{{staticUrl}}{{event.eventimageuri}}" class="img-responsive" />
                </div>
              </td>
              <td>
                <h4>{{event.name}}
                  <p class="label label-warning" ng-show="event.eventstatus === 0">Pending</p>
                  <p class="label label-success" ng-show="event.eventstatus === 1">Active</p>
                  <p class="label label-danger" ng-show="event.eventstatus === 2">Ended</p>
                  <button class="btn btn-danger btn-xs" ng-show="event.eventstatus === 4" ng-click="events.showErrors(event.details)"><i class="fa fa-exclamation-circle fa-2x" aria-hidden="true"></i></button>
                  <button class="btn btn-warning btn-xs" ng-show="event.eventstatus === 5" ng-click="events.showErrors(event.details)">Ended with Errors</button>
                </h4>
              </td>
              <td>
                <p>{{event.venueName}}</p>
              </td>
              <td>
                <p>Starts: {{event.starttime | date:'dd/MM/yyyy @ h:mma'}}</p>

                <p ng-hide="event.endtime === events.maxDate">Ends: {{event.endtime | date:'dd/MM/yyyy @ h:mma'}}</p>
              </td>
              <td>
                <p>{{event.description}}</p>
              </td>
            </tr>
            <tr>
              <td colspan=5>
                <div class="pull-right">
                  <a class="btn btn-danger btn-md" ui-sref="admin.editsurvey({eventid: event.id, surveyid: event.survey})" tooltip-placement="left" tooltip="Edit Survey" ng-hide="true"><i class="fa fa-check-square-o"></i></a>
                  <!-- <a class="btn btn-md btn-danger btn-md" ui-sref="admin.analytics({venueid: event.venue, eventid: event.id})" tooltip-placement="left" tooltip="Analytics">
                  <i class="fa fa-bar-chart"></i>
                </a> -->
                  <a class="btn btn-md btn-danger btn-md" ui-sref="admin.eventanalytics({venueid: event.venue, eventid: event.id})" tooltip-placement="left" tooltip="Analytics">
                    <i class="fa fa-line-chart" aria-hidden="true"></i>
                  </a>
                  <button class="btn btn-danger btn-md" ng-click="events.deleteEvent(event.id, event.venue)" ng-hide="event.eventstatus === 1" tooltip-placement="left" tooltip="Delete Event">
                    <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                  </button>
                  <!-- <a class="btn btn-danger btn-md" ui-sref="admin.event({eventid: event.id, venueid: event.venue})" tooltip-placement="left" tooltip="View Event"><i class="fa fa-eye" aria-hidden="true"></i></a> -->
                  <a class="btn btn-danger btn-md" ui-sref="admin.editeventnew({eventid: event.id, venueid: event.venue})" tooltip-placement="left" tooltip="Edit Event"><i class="fa fa-pencil-square-o"></i></a>
                  <button class="btn btn-danger btn-md" ng-click="events.sendMessage(event.venue, event.id)" tooltip-placement="left" tooltip="Send Message" ng-show="event.twilio">
                    <i class="fa fa-mobile fa-lg"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody ng-repeat="event in events.events | orderBy:'-startDate' | filter:eventSearch | limitTo:events.limit" ng-show="(event.eventstatus === 2 || event.eventstatus === 5) && events.showArchieved">
            <tr>
              <td class="evntIMG">
                <div>
                  <img ng-src="{{staticUrl}}{{event.eventimageuri}}" class="img-responsive" />
                </div>
              </td>
              <td>
                <h4>{{event.name}}</h4>
                <p class="label label-warning" ng-show="event.eventstatus === 0">Pending</p>
                <p class="label label-success" ng-show="event.eventstatus === 1">Active</p>
                <p class="label label-danger" ng-show="event.eventstatus === 2">Ended</p>
                <button class="btn btn-warning" ng-show="event.eventstatus === 4" ng-click="events.showErrors(event.details)">Active with Errors</button>
                <button class="btn btn-warning" ng-show="event.eventstatus === 5" ng-click="events.showErrors(event.details)">Ended with Errors</button>
              </td>
              <td>
                <p>{{event.venueName}}</h4>
              </td>
              <td>
                <p>Starts: {{event.starttime | date:'dd/MM/yyyy @ h:mma'}}</p>

                <p>Ends: {{event.endtime | date:'dd/MM/yyyy @ h:mma'}}</p>
              </td>
              <td>
                <p>{{event.description}}</p>
              </td>
            </tr>
            <tr>
              <td colspan=5>
                <div class="pull-right">
                  <a class="btn btn-md btn-danger btn-md" ui-sref="admin.eventanalytics({venueid: event.venue, eventid: event.id})" tooltip-placement="left" tooltip="Analytics">
                    <i class="fa fa-line-chart" aria-hidden="true"></i>
                  </a>
                  <a class="btn btn-danger btn-md" ui-sref="admin.duplicateevent({eventid: event.id, venueid: event.venue})" tooltip-placement="left" tooltip="Duplicate Event"><i class="fa fa-files-o" aria-hidden="true"></i></a>
                  <a class="btn btn-danger btn-md" ui-sref="admin.event({eventid: event.id, venueid: event.venue})" tooltip-placement="left" tooltip="View Event"><i class="fa fa-eye" aria-hidden="true"></i></a>
                  <button class="btn btn-danger btn-md" ng-click="events.deleteEvent(event.id, event.venue)" ng-hide="event.eventstatus === 1" tooltip-placement="left" tooltip="Delete Event">
                    <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</div>
