(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editeventnew.controller:EditeventsummaryCtrl
   *
   * @description
   *
   */
  angular
    .module('editeventnew')
    .controller('EditeventsummaryCtrl', EditeventsummaryCtrl);

  function EditeventsummaryCtrl(params, $modalInstance) {
    var vm = this;
    vm.ctrlName = 'EditeventsummaryCtrl';
    vm.minDate = new Date();
    vm.params = params;

    vm.noend = (new Date(vm.params.endtime).getTime() === 253402300740905) ? true: false;

    if (vm.noend) {
      vm.params.endtime = new Date();
    }

    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    vm.dateFormat = 'dd-MMMM-yyyy';
    vm.hstep = 1;
    vm.mstep = 1;

    vm.status = {
      openedSt: false,
      openedEnd: false
    };

    vm.openCalSt = function () {
      vm.status.openedSt = true;
    };

    vm.openCalEnd = function () {
      vm.status.openedEnd = true;
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

    vm.ok = function () {
      if (vm.noend) {
        vm.params.endtime = new Date(253402300740905);
      }
      $modalInstance.close(vm.params);
    };
  }
}());
