<div class="modal-header">
  <img class="modal-img" src="./images/login/modal_image.png">
</div>
<div class="modal-body">
  <div ng-hide="venueCreateModal.error.length > 1">
    <h4>Would you like to create from controller?</h4>

    <select class="form-control" name="selected" ng-model="venueCreateModal.selected" ng-options="option.id as option.controlleraddress for option in venueCreateModal.infrastructures" ng-change="venueCreateModal.selectedInfrastructure(venueCreateModal.selected)">
      <option value="" disabled selected>- Please Select An Infrastructure -</option>
    </select>
  </div>
  <h4>{{venueCreateModal.error}}</h4>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button" ng-click="venueCreateModal.ok()" ng-disabled="venueCreateModal.disabled">Yes</button>
  <button class="btn btn-info" type="button" ng-click="venueCreateModal.no()" ng-hide="venueCreateModal.error.length > 1">No</button>
  <button class="btn btn-warning" type="button" ng-click="venueCreateModal.cancel()">Cancel</button>
</div>
