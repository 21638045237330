(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editinfrastructure.controller:EditinfrastructureCtrl
   *
   * @description
   *
   */
  angular
    .module('editinfrastructure')
    .controller('EditinfrastructureCtrl', EditinfrastructureCtrl);

  function EditinfrastructureCtrl(Api, $stateParams, $state, $log) {
    var vm = this;

    vm.infrastructure = {};
    vm.submitted = false;

    vm.providerTypes = [];
    vm.selectedProvider = '';

    vm.setProvider = function (provider) {
      console.log(provider);
      vm.providerSelected = true;
      vm.selectedProvider = provider;
      if(provider === 1){
        vm.infrastructure.controlleraddress = 'Xirrus';
        console.log(vm.infrastructure.controlleraddress);
      } else {
        console.log(vm.infrastructure.controlleraddress);
      }
    };

    Api.getProviders().then(function(res){
      console.log(res);
      vm.providerTypes = res.data.providers;
    }, function(err){
      console.log(err);
    });

    Api.getInfrastructure($stateParams.infraid, $stateParams.venueid).then(function (res) {
      $log.info(res);
      vm.infrastructure = res.data.infrastructure;
      vm.setProvider(vm.infrastructure.provider);
    }, function (err) {
      $log.error(err);
    });

    vm.edit = function (isValid) {
      var thisInfrastructure = vm.infrastructure;
      vm.submitted = true;
      if (isValid) {
        Api.updateInfrastructure($stateParams.infraid, $stateParams.venueid, thisInfrastructure).then(function (res) {
          $log.info(res);
          $state.go('admin.managenetwork', {
            'venueid': $stateParams.venueid
          });
        }, function (err) {
          $log.error(err);
        });
      }
    };

    vm.cancel = function(){
      $state.go('admin.managenetwork', {
        'venueid': $stateParams.venueid
      });
    };

  }
}());
