<div class="modal-header">
  <img class="modal-img" src="./images/login/modal_image.png">
</div>
<div class="modal-body">
  <div ng-hide="modal.image" class="image-box text-center" ngf-select ngf-drop accept="image/*" ngf-pattern="'image/*'" ngf-drag-over-class="dragover" ng-model="modal.image">
    <span class="glyphicon glyphicon-camera"></span>
    <h4>
      <strong>ADD AN IMAGE</strong>
    </h4>
    <!-- <p>Choose an image that captures your event</p> -->
  </div>
  <div class="row modalThumb">
    <img ngf-src="modal.image">
  </div>
  <div class="row">
    <div class="col-md-2">
      <button class="btn btn-file btn-danger" type="file" ngf-select accept="image/*" ngf-max-height="1000" ngf-max-size="1MB" ng-model="modal.image">
        Select File</button>
    </div>
  </div>
  <div class="form-group">
    <label><h4>Keywords</h4></label>
    <textarea class="form-control" rows="3" ng-model="modal.description" placeholder="comma, seperated, values"></textarea>
  </div>
  <button ng-click="modal.uploadFiles(modal.image)" class="btn btn-sm btn-danger">Submit</button>
  <button class="btn btn-warning" type="button" ng-click="modal.exit()">Cancel</button>
</div>
