(function () {
  'use strict';

  angular
    .module('ppolicy')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('main.privacypolicy', {
        url: 'privacypolicy',
        templateUrl: 'ppolicy/views/ppolicy.tpl.html',
        controller: 'PpolicyCtrl',
        controllerAs: 'ppolicy'
      });
  }
}());
