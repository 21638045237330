<form name="createEvent" novalidate>
  <h2 class="text-center">duplicate Event - {{duplicateevent.event.name}}</h2>
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <ul class="breadcrumb">
        <li ui-sref-active="active">
          <a ui-sref="admin.duplicateevent.duplicatesetup" ng-show="duplicateevent.setupPassed"><i class="fa fa-2x fa-sliders"></i></br>Setup</a>
          <a href="" ng-show="!duplicateevent.setupPassed" ng-click="duplicateevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, 'admin.duplicateevent.duplicatesetup')"><i class="fa fa-2x fa-sliders"></i></br>Setup</a>
        </li>
        <li ui-sref-active="active">
          <a ui-sref="admin.duplicateevent.duplicatenetwork" ng-show="duplicateevent.netPassed"><i class="fa fa-2x fa-cogs"></i></br>Network</a>
          <a ng-show="!duplicateevent.netPassed" ng-click="duplicateevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, 'admin.duplicateevent.duplicatenetwork')" href=""><i class="fa fa-2x fa-cogs"></i></br>Network</a>
        </li>
        <li ui-sref-active="active">
          <a ui-sref="admin.duplicateevent.duplicatesplash" ng-show="duplicateevent.splashPassed"><i class="fa fa-2x fa-mobile"></i></br>Splash Page</a>
          <a ng-show="!duplicateevent.splashPassed" ng-click="duplicateevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, 'admin.duplicateevent.duplicatesplash')" href=""><i class="fa fa-2x fa-mobile"></i></br>Splash Page</a>
        </li>
        <li ui-sref-active="active" role="presentation">
          <a ui-sref="admin.duplicateevent.confirm" ng-hide="true"><i class="fa fa-2x fa-check-circle-o"></i></br>Confirm</a>
          <a href="" ng-click="duplicateevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, 'admin.duplicateevent.confirm')"><i class="fa fa-2x fa-check-circle-o"></i></br>Confirm</a>
        </li>
      </ul>
    </div>
    <div class="panel-body">
    <div data-ui-view></div>
    </div>
</form>
