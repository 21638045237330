 (function () {
   'use strict';

   /* @ngdoc object
    * @name leoperations
    * @description
    *
    */
   angular
     .module('leoperations', [
       'ngAria',
       'ui.router',
       'ui.bootstrap',
       'ngDragDrop',
       'home',
       'admin',
       'main',
       'eventcreation',
       'base64',
       'checklist-model',
       'rzModule',
       'ngCookies',
       'events',
       'ngMessages',
       'chart.js',
       'ngFileUpload',
       'ngStorage',
       'event',
       'editevent',
       'manageimages',
       'editinfrastructure',
       'venues',
       'newvenue',
       'operators',
       'editoperator',
       'newoperator',
       'modalservice',
       'selectimage',
       'duplicateevent',
       'angularUtils.directives.dirPagination',
       'managenetwork',
       'editzone',
       'addap',
       'addzone',
       'networktype',
       'editnetworktype',
       'editap',
       'addInfrastructure',
       'editvenue',
       'editsurvey',
       'editeventnew',
       'addsurvey',
       'mailchimp',
       'newlist',
       'uploadorgimg',
       'userprofile',
       'editclservice',
       'addclservice',
       'cloudservices',
       'support',
       'ppolicy',
       'orgInfrastructures',
       'tags',
       'dndLists',
       'mailListModal',
       'twilioModal',
       'newvenueanalytics',
       'eventanalytics',
       'ngAnimate',
       'addvenuemap',
       'editvenuemap',
       'textAngular',
       'tandceditor',
       'ngSanitize',
       'ngCsv'
     ]);
 }());
