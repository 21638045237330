(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editeventnew.controller:EndeventmodalCtrl
   *
   * @description
   *
   */
  angular
    .module('editeventnew')
    .controller('EndeventmodalCtrl', EndeventmodalCtrl);

  function EndeventmodalCtrl(params, $modalInstance) {
    var vm = this;
    vm.ctrlName = 'EndeventmodalCtrl';
    vm.params = params;

    vm.ok = function () {
      var MS_PER_MINUTE = 60000;
      var minDate = new Date();
      vm.params.endtime = new Date(minDate - 30 * MS_PER_MINUTE);
      $modalInstance.close(vm.params);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
