(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name duplicateevent.controller:DuplicatessidchecksCtrl
   *
   * @description
   *
   */
  angular
    .module('duplicateevent')
    .controller('DuplicatessidchecksCtrl', DuplicatessidchecksCtrl);

  function DuplicatessidchecksCtrl($modalInstance, message) {
    var vm = this;
    vm.ctrlName = 'DuplicatessidchecksCtrl';

    vm.message = message;

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
