(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name addzone.controller:AddzoneCtrl
   *
   * @description
   *
   */
  angular
    .module('addzone')
    .controller('AddzoneCtrl', AddzoneCtrl);

  function AddzoneCtrl(Api, $stateParams, $log, $state, Auth) {
    var vm = this;
    vm.status = {
      isopen: false
    };
    vm.zone = {};
    vm.accesspoints = [];
    vm.selectedAccesspoints = [];
    vm.provider = $stateParams.provider;
    var orgid = Auth.getAuthData().operator.organisation;
    var zoneId = 0;

    Api.getAccessPoints($stateParams.venueid).success(function(data) {
      $log.info(data);
      vm.accesspoints = data.apoints;
      $log.info(vm.accesspoints);
    }).error(function() {
      vm.accesspoints = [];
      $log.info('get Zones error');
    });

    vm.createZone = function() {
      //console.log(vm.selectedAccesspoints);
      vm.zone.orgid = $stateParams.orgid;
      Api.newZone($stateParams.venueid, vm.zone).success(function(data) {
        //console.log(data);
        zoneId = data.id;
        console.log(vm.selectedAccesspoints);
        if (vm.selectedAccesspoints.length > 0) {
          var strSelectedAccesspoints = vm.selectedAccesspoints;
          var apZoneUpateObj = {
            venueId: $stateParams.venueid,
            zoneId: zoneId,
            orgId: orgid,
            accessPoints: strSelectedAccesspoints
          };
          console.log(apZoneUpateObj);
          Api.updateApsZone(apZoneUpateObj).success(function(data) {
            console.log(data);
            $state.go('admin.managenetwork', {
              'venueid': $stateParams.venueid
            });
          }).error(function(err) {
            console.log(err);
          });
        } else {
          $state.go('admin.managenetwork', {
            'venueid': $stateParams.venueid
          });
        }
      }, function(err) {
        console.log(err);
      });
    };

  }
}());
