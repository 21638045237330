<h2>Manage {{managenetwork.venue.venuetitle}} Network
</h2>
<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row row-align">
      <h3 ng-show="managenetwork.showZones">Zones
        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createZone()" tooltip-placement="left" tooltip="New Zone">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
      <h3 ng-show="managenetwork.showAPoints">Access Points
        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createAccesspoint()" tooltip-placement="left" tooltip="New Accesspoint">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
      <h3 ng-show="managenetwork.showTypes">Network Types
        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createNetworkType()" tooltip-placement="left" tooltip="New Network Type">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
      <h3 ng-show="managenetwork.showInfra">Infrastructure
        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createInfrastructure()" tooltip-placement="left" tooltip="New Infrastructure" ng-hide="managenetwork.infrastructures.length > 0">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
      <h3 ng-show="managenetwork.showVenueMap">Venue Maps
        <button class="btn btn-sm btn-danger pull-right" ng-click="managenetwork.createVenueMap()" tooltip-placement="left" tooltip="New Venue Map" >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </h3>
    </div>
  </div>
  <div class="panel-body">
    <div class="row tabrow">
      <div class="col-md-9">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" ng-class="{'active': (managenetwork.showInfra)}">
            <a href="" ng-click="managenetwork.setNetworkView('infra')" aria-controls="network types" role="tab" data-toggle="tab">Infrastructure</a>
          </li>
          <li role="presentation" ng-class="{'active': (managenetwork.showZones)}" ng-show="managenetwork.infrastructures.length > 0">
            <a href="" ng-click="managenetwork.setNetworkView('zone')" aria-controls="zones" role="tab">Zones</a>
          </li>
          <li role="presentation" ng-class="{'active': (managenetwork.showAPoints)}" ng-show="managenetwork.infrastructures.length > 0">
            <a href="" ng-click="managenetwork.setNetworkView('apoints')" aria-controls="accesspoints" role="tab" data-toggle="tab">Access Points</a>
          </li>
          <li role="presentation" ng-class="{'active': (managenetwork.showTypes)}" ng-show="managenetwork.infrastructures.length > 0">
            <a href="" ng-click="managenetwork.setNetworkView('types')" aria-controls="network types" role="tab" data-toggle="tab">Network Types</a>
          </li>
          <li role="presentation" ng-class="{'active': (managenetwork.showVenueMap)}" ng-show="managenetwork.infrastructures.length > 0">
            <a href="" ng-click="managenetwork.setNetworkView('venuemap')" aria-controls="network types" role="tab" data-toggle="tab">Venue Map</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3">
        <div class="input-group">
          <div class="input-group-addon">
            <i class="fa fa-search"></i>
          </div>
          <input type="text" class="form-control" placeholder="Search Zones" ng-model="zoneSearch" ng-show="managenetwork.showZones">
          <input type="text" class="form-control" placeholder="Search Accesspoints" ng-model="apSearch" ng-show="managenetwork.showAPoints">
          <input type="text" class="form-control" placeholder="Search Network Types" ng-model="ntSearch" ng-show="managenetwork.showTypes">
          <input type="text" class="form-control" placeholder="Search Infrastructure" ng-model="inSearch" ng-show="managenetwork.showInfra">
          <input type="text" class="form-control" placeholder="Search Venue Maps" ng-model="inSearch" ng-show="managenetwork.showVenueMap">
        </div>
      </div>
    </div>
    <div ng-show="managenetwork.showZones">
      <table class="table table-responsive">
        <tbody>
          <tr dir-paginate="zone in managenetwork.zones | orderBy:'id' | filter:zoneSearch | itemsPerPage: 10" pagination-id="zones">
            <td>
              <h4>{{zone.name}}</h4>
            </td>
            <td>
              <h4>{{zone.details.description}}</h4>
            </td>
            <td>
              <div class="pull-right">
                <button class="btn btn-danger btn-sm" ng-click="managenetwork.deleteZone(zone.id)" tooltip-placement="left" tooltip="Delete Zone">
                  <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                </button>
                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editZone(zone.id)" tooltip-placement="left" tooltip="Edit zone">
                  <i class="fa fa-pencil-square-o"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true" pagination-id="zones"></dir-pagination-controls>
    </div>
    <div ng-show="managenetwork.showAPoints">
      <table class="table table-responsive">
        <tbody>
          <tr dir-paginate="accesspoint in managenetwork.accesspoints | orderBy:'ipAddress' | filter:apSearch | itemsPerPage: 10" pagination-id="aps">
            <td>
              <h4>Name : {{accesspoint.name}}</h4>
            </td>
            <td>
              <h4>Radio MAC: {{accesspoint.hwaddr}}</h4>
            </td>
            <td>
              <div class="pull-right">
                <button class="btn btn-danger btn-sm" ng-click="managenetwork.deleteAccesspoint(accesspoint.id)" tooltip-placement="left" tooltip="Delete Accesspoint">
                  <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                </button>
                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editAccesspoint(accesspoint.id)" tooltip-placement="left" tooltip="Edit Accesspoint">
                  <i class="fa fa-pencil-square-o"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true" pagination-id="aps"></dir-pagination-controls>
    </div>
    <div ng-show="managenetwork.showTypes">
      <table class="table table-responsive">
        <tbody>
          <tr ng-repeat="type in managenetwork.networkTypes | filter:ntSearch">
            <td>
              <h4>Name: {{type.name}}
              </h4>
            </td>
            <td>
              <div class="pull-right">
                <button class="btn btn-danger btn-sm" ng-click="managenetwork.deleteNetworkType(type.id)" tooltip-placement="left" tooltip="Remove Network Type">
                  <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                </button>
                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editNetworkType(type.id)" tooltip-placement="left" tooltip="Edit Network Type">
                  <i class="fa fa-pencil-square-o"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div ng-show="managenetwork.showVenueMap">
      <table class="table table-responsive">
        <tbody>
          <tr dir-paginate="map in managenetwork.maps | orderBy:'id' | filter:mapSearch | itemsPerPage: 10" pagination-id="maps">
            <td class="evntIMG">
              <div>
                <img ng-src="{{adminStaticUrl}}{{map.imageUri}}" onerror="this.src='./images/le-sq.png'" class="img-responsive" />
              </div>
            </td>
            <td>
              <h4>{{map.name}}</h4>
            </td>
            <td>
              <div class="pull-right">
                <button class="btn btn-danger btn-sm" ng-click="managenetwork.deleteMap(map._id)" tooltip-placement="left" tooltip="Delete Map">
                  <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                </button>
                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editMap(map._id)" tooltip-placement="left" tooltip="Edit Map">
                  <i class="fa fa-pencil-square-o"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true" pagination-id="maps"></dir-pagination-controls>
    </div>
    <div ng-show="managenetwork.showInfra">
      <table class="table table-responsive">
        <tbody>
          <tr ng-repeat="type in managenetwork.infrastructures | filter:inSearch">
            <td>
              <h4>Provider: {{type.name}}
              </h4>
            </td>
            <td>
              <h4>Controller Address: {{type.controlleraddress}}
              </h4>
            </td>
            <td>
              <div class="pull-right">
                <button class="btn btn-danger btn-sm" ng-click="managenetwork.editInfrastructure(type.id)" tooltip-placement="left" tooltip="Edit Infrastructure">
                  <i class="fa fa-pencil-square-o"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
