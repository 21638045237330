<div class="modal-header">
  <img class="modal-img" src="./images/login/modal_image.png">
</div>
<div class="modal-body">
  <h4>Cancel editing this Event?</h4>
  <p>All changes will be lost</p>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button" ng-click="cancelEditModal.ok()">Confirm</button>
  <button class="btn btn-warning" type="button" ng-click="cancelEditModal.cancel()">Don't Cancel</button>
</div>
