(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:GoogleAnalyticsApi
   *
   * @description
   *
   */

  angular
    .module('leoperations')
    .factory('GoogleAnalyticsApi', GoogleAnalyticsApi);

  function GoogleAnalyticsApi(baseurl, baseendpoint, $http) {
    var GoogleAnalyticsApiBase = {};
    GoogleAnalyticsApiBase.factoryName = 'GoogleAnalyticsApi';

    GoogleAnalyticsApiBase.getToken = function () {
      return $http.get(baseurl + baseendpoint + '/analytics/googletoken', {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    };

    GoogleAnalyticsApiBase.getPageViewsData = function (token) {
      return $http.get('https://www.googleapis.com/analytics/v3/data/ga?ids=ga%3A110558735&start-date=30daysAgo&end-date=yesterday&metrics=ga%3Apageviews&dimensions=ga%3ApagePath&access_token=' + token, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      });
    };

    GoogleAnalyticsApiBase.getOsData = function (token) {
      return $http.get('https://www.googleapis.com/analytics/v3/data/ga?ids=ga%3A110558735&start-date=30daysAgo&end-date=yesterday&metrics=ga%3Apageviews&dimensions=ga%3AoperatingSystem&access_token=' + token, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      });
    };

    GoogleAnalyticsApiBase.getBrowserData = function (token) {
      return $http.get('https://www.googleapis.com/analytics/v3/data/ga?ids=ga%3A110558735&start-date=30daysAgo&end-date=yesterday&metrics=ga%3Apageviews&dimensions=ga%3Abrowser&access_token=' + token, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      });
    };

    GoogleAnalyticsApiBase.getClickEvents = function (token) {
      return $http.get('https://www.googleapis.com/analytics/v3/data/ga?ids=ga%3A110558735&start-date=30daysAgo&end-date=yesterday&metrics=ga%3AtotalEvents&dimensions=ga%3AeventCategory%2Cga%3AeventAction%2Cga%3AeventLabel&access_token=' + token, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      });
    };

    return GoogleAnalyticsApiBase;
  }
}());
