<div class="modal-header">
  <img class="modal-img" src="./images/login/modal_image.png">
</div>
<div class="modal-body">
  <div style="text-align: center;">
    <p>Warning this will reset the password of the operator and re-issue login credentials to <strong>{{emailModal.user.login}}<strong></p>
    <p>Please type the operators email to confirm: </p>
    <input class="form-control" type="text" name="email" value="" ng-model="emailModal.user.compare">

    <strong style="color: red;">{{emailModal.error}}</strong>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button" ng-click="emailModal.cancel()">Cancel</button>
  <button class="btn btn-primary" type="button" ng-click="emailModal.ok()">OK</button>
</div>
