<div class="tandceditor">
  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="row row-align">
        <h3>Terms &amp; Conditions</h3>
      </div>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-md-10 col-md-offset-1">
          <text-angular ng-model="tandceditor.htmlVariable"></text-angular>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10 col-md-offset-1">
          <button class="btn btn-large btn-danger pull-right" ng-click="tandceditor.createTandC()">Submit</button>
          <button class="btn btn-large btn-danger pull-right" ng-click="tandceditor.deleteTandC()">
            <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>