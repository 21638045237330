<div class="addvenuemap">
  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="row row-align">
        <h3>Add Venue Map</h3>
      </div>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-6">
            <h4>Map Name</h4>
            <input type="text" class="form-control" ng-model="addvenuemap.mapName" placeholder="eg. Floor One, Two, Three, Etc ..." required>
          </div>
          <div class="col-md-6">
            <h4>Add Map Image  </h4>
            <button class="btn btn-danger" ng-click="addvenuemap.uploadImage()" tooltip-placement="left" tooltip="Upload Image">
              <i class="fa fa-cloud-upload" aria-hidden="true"></i>
            </button>
            <br/>
            <br/>
          </div>
        </div>
      </div>
      <div class="panel panel-default" ng-show="addvenuemap.backgroundSelected">
        <div class="panel-heading">
          <div class="row row-align">
            <h3>Floor Map</h3>
          </div>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="col-sm-2">
                <h3>Accesspoints</h3>
                <ul style="list-style-type: none; list-style-position:inside; margin:0; padding:0;" ng-repeat="ap in addvenuemap.accesspoints">
                  <li>
                    <div id="{{ap.name}}|{{ap.hwaddr}}" class="apItem" style="border: 1px solid #000;padding: 5px; margin: 5px;" drag-canvas-aps>
                      <img  ng-hide="ap.ongraph" id="dragicon" class="pull-right" src="./images/apicon.png" alt="Smiley face" height="16" width="16">
                      <div>{{ap.name}}</div>
                      <div>{{ap.hwaddr}}</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-sm-10">
                <div id="heatMapContainer" style="position: relative; height: {{ addvenuemap.mapHeight }}px;">
                  <canvas id="backDrop" style="position: absolute; left: 0; top: 0; z-index: 0;"></canvas>
                  <!-- <canvas id="heatMap" style="position: absolute; left: 0; top: 0; z-index: 1;"></canvas> -->
                  <canvas id="pointMap" style="position: absolute; left: 0; top: 0; z-index: 1;" drop-canvas-aps></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-11">
          <button  ng-show="addvenuemap.backgroundSelected" type="submit" class="btn btn-danger pull-right" ng-click="addvenuemap.submit()">Submit</button>
        </div>
        <div class="col-sm-1">
          <button type="submit" class="btn btn-info pull-right" onclick="history.back()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
