(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('venues')
    .controller('VenuecreatemodalCtrl', VenuecreatemodalCtrl);

  function VenuecreatemodalCtrl($modalInstance, $log, Api) {
    var vm = this;
    vm.ctrlName = 'VenuecreatemodalCtrl';
    vm.infrastructures = [];
    vm.selected = '';
    vm.infrastructure = {};
    vm.disabled = true;
    vm.error = '';

    vm.getInfrastructures = function(){
      Api.getOrgInfrastructures().success(function(data) {
        vm.infrastructures = data.infrastructures;
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };

    vm.selectedInfrastructure = function(option){
      var infraIndex = 0;
      for(infraIndex = 0; infraIndex < vm.infrastructures.length; infraIndex++) {
        if (vm.infrastructures[infraIndex].id === option) {
          vm.infrastructure = vm.infrastructures[infraIndex];
        }
      }
      vm.disabled = false;
    };


    Api.getProviders().then(function(res){
      vm.providers = res.data.providers;
      console.log(vm.providers);
    }, function(err){
      console.log(err);
    });
    vm.matchProvider = function(input, id){
      var returning = false;
      vm.providers.forEach(function(item){
        if(item.id === id){
          if (item.name.toLowerCase() === input){
            returning = true;
          }
        }
      });
      return returning;
    };

    vm.getInfrastructures();

    vm.ok = function () {
      var data = {};
      if (vm.error.length > 1) {
        data = {
          choice: false
        };
        $modalInstance.close(data);
      } else {
        data = {
          choice: true,
          infrastructure: vm.infrastructure
        };
        if (vm.matchProvider('unifi', data.infrastructure.provider) || vm.matchProvider('ruckus', data.infrastructure.provider) || vm.matchProvider('xirrus', data.infrastructure.provider) || vm.matchProvider('meraki', data.infrastructure.provider)) {
          $modalInstance.close(data);
        } else {
          vm.error = 'Selected Infrastructure does not support bulk create would you like to continue with creating a venue ?';
        }
      }
    };
    vm.cancel = function () {
      $modalInstance.dismiss();
    };
    vm.no = function () {
      var data = {
        choice: false
      };
      $modalInstance.close(data);
    };
  }
}());
