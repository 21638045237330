(function () {
  'use strict';

  angular
    .module('addzone')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addzone', {
        url: '/addzone/:venueid/:provider',
        templateUrl: 'addzone/views/addzone.tpl.html',
        controller: 'AddzoneCtrl',
        controllerAs: 'addzone'
      });
  }
}());
