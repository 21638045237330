(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name userprofile.controller:UserprofileCtrl
   *
   * @description
   *
   */
  angular
    .module('userprofile')
    .controller('UserprofileCtrl', UserprofileCtrl);

  function UserprofileCtrl(ApiAnalytic, AnalyticsApi, $stateParams) {
    var vm = this;
    vm.venueId = parseInt($stateParams.venueid);
    vm.userId = parseInt($stateParams.userid);
    vm.providerId = parseInt($stateParams.providerid);
    vm.firstSeen = '';
    vm.lastSeen = '';
    vm.user = {};
    vm.userJourney = [];
    vm.isLive = false;

    var checkUserOnline = function () {
      ApiAnalytic.checkUserOnline(vm.venueId, vm.providerId, {'macs':vm.user.usermac}).then(function (response) {
        vm.isLive = response.data.online;
        console.log(response.data.online);
      }, function (err) {
        console.log(err);
      });
    };

    vm.getUserProfile = function () {
      ApiAnalytic.getUserProfile(vm.venueId, vm.userId).then(function (response) {
        vm.user = response.data;
        vm.firstSeen = vm.user.logins[0].timestamp;
        vm.lastSeen = vm.user.logins[vm.user.logins.length - 1].timestamp;
        checkUserOnline();
        // console.log(response);
        console.log(vm.user);
      }, function (err) {
        console.log(err);
      });
    };

    vm.getUserProfile();

    vm.dateOf = function (utcDateStr) {
      return new Date(utcDateStr);
    };

    /* Access Points */

    vm.submit = function (macAddress, date) {
      // console.log('called Journey');
      AnalyticsApi.getUserJourney(vm.venueId, macAddress, (new Date(date)).getTime()).then(function (response) {
        // console.log(response);
        vm.userJourney = response.data.aps;
      }, function (err) {
        console.log(JSON.stringify(err));
      });
    };
  }
}());
