(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name uploadorgimg.controller:UploadorgimgCtrl
   *
   * @description
   *
   */
  angular
    .module('uploadorgimg')
    .controller('UploadorgimgCtrl', UploadorgimgCtrl);

  function UploadorgimgCtrl($modalInstance, adminbase, Auth, Upload, $timeout) {

    var vm = this,
      token = '';

    if (Auth.getAuthData()) {
      token = Auth.getAuthData().token;
    }

    var imgurl = adminbase + '/admin-web/v1' + '/images';

    vm.uploadFiles = function (file) {
      vm.f = file;
      if (file) {
        file.upload = Upload.upload({
          url: imgurl,
          data: {
            image: file,
            details: {
              description: vm.description
            }
          },
          headers: {
            'x-access-token': token
          }
        });
        file.upload.then(function (response) {
          $timeout(function () {
            file.result = response.data;
            vm.imageAdded = true;
            vm.image = response.data.imageURI;
            vm.imageID = response.data.id;
            $modalInstance.close(response.data);
          });
        }, function (response) {
          if (response.status > 0) {
            vm.errorMsg = response.status + ': ' + response.data;
            console.log(String(vm.errorMsg));
          }
        }, function (evt) {
          file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });
      }
    };

    vm.exit = function () {
      $modalInstance.dismiss();
    };
    
    vm.dismiss = function () {
      vm.message = {};
      console.log('was called');
    };



  }
}());
