<div class="well well-small">
  <div class="row">
    <div class="col-sm-2">
      <div ng-show="userprofile.user.socialdata.provider == 'google'">
        <img ng-src="{{userprofile.user.socialdata.photos[0].value}}" class="img-responsive" />
      </div>
      <div ng-show="userprofile.user.socialdata.provider == 'facebook'">
        <img ng-src="https://graph.facebook.com/{{userprofile.user.socialdata.id}}/picture?type=large" class="img-responsive" />
      </div>
      <div ng-show="userprofile.user.socialdata.provider == 'twitter'">
        <img ng-src="https://twitter.com/{{userprofile.user.socialdata.username}}/profile_image?size=original" class="img-responsive" />
      </div>
    </div>
    <div class="col-sm-4 wrap-text">
      <h5>{{userprofile.user.name}}</h5>
      <ul class="list-unstyled gender-list">
        <li>
          <strong>Email: </strong>{{userprofile.user.login}}</li>
        <li>
          <strong>Gender: </strong>{{userprofile.user.socialdata.gender}}</li>
        <li>
          <strong>Social Media: </strong>
          <a ng-show="userprofile.user.logintype == 'facebook'" ng-href="{{userprofile.user.socialdata.profileUrl}}" target="-_blank">{{userprofile.user.logintype}}</a>
          <a ng-show="userprofile.user.logintype == 'google'" ng-href="https://plus.google.com/u/0/{{userprofile.user.socialdata.id}}" target="-_blank">{{userprofile.user.logintype}}</a>
          <a ng-show="userprofile.user.logintype == 'linkedin'" ng-href="https://www.linkedin.com/in/{{userprofile.user.socialdata.id}}" target="-_blank">{{userprofile.user.logintype}}</a>
          <a ng-show="userprofile.user.logintype == 'twitter'" ng-href="https://twitter.com/{{userprofile.user.socialdata.username}}" target="-_blank">{{userprofile.user.logintype}}</a>
        </li>
        <li>
          <ul class="list-group tagList">
            <li class="list-group-item list-group-item-success" ng-if="userprofile.isLive">Online</li>
            <li class="list-group-item list-group-item-danger" ng-if="!userprofile.isLive">Offline</li>
          </ul>
        </li>
      </ul>
      <br>
      <br>
      <br>
      <p><strong>Tags:</strong></p>
      <ul class="list-group tagList">
        <li ng-repeat="tag in userprofile.user.tags" class="list-group-item list-group-item-success">{{tag}}</li>
      </ul>
    </div>
    <div>
      <div class="col-sm-3 col-sm-offset-3">
        <ul class="list-unstyled">
          <li>
            <h5> First Visit: </h5>
            <span>{{userprofile.dateOf(userprofile.firstSeen)| date: 'yyyy-MM-dd HH:mm'}}</span>
          </li>
          <li>
            <h5> Most Recent Visit: </h5>
            <span>{{userprofile.dateOf(userprofile.lastSeen)| date: 'yyyy-MM-dd HH:mm'}}</span>
          </li>
          <li>
            <h5> Visits: </h5>
            <span>{{userprofile.user.loginCount}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="hidden-sm hidden-md hidden-lg">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3>Visits</h3>
    </div>
    <div class="panel-body">
      <ul class="list-unstyled list-margin event-list-xs">
        <li ng-repeat="login in userprofile.user.logins">
          <div class="row">
            <div class="col-xs-6">
              <p>
                <strong>Date: </strong>
              </p>
              <p>{{userprofile.dateOf(login.timestamp)| date: 'yyyy-MM-dd HH:mm'}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6">
              <p>
                <strong>AP: </strong>
              </p>
              <p ng-if="!i.apname.length">{{login.apmac}}</p>
              <p ng-if="i.apname.length > 0">{{login.apname}}</p>
            </div>
            <div class="col-xs-6">
              <p>
                <strong>SSID: </strong>
              </p>
              <p>{{login.ssid}}</p>
            </div>
            <div class="col-xs-6" ng-hide="true">
              <button class="btn btn-danger btn-md" ng-click="userprofile.submit()" tooltip-placement="left" tooltip="User Journey">
                <span class="glyphicon" aria-hidden="true">Details</span>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

</div>

<div class="panel panel-default hidden-xs">
  <div class="panel-heading">
    <h3>Visits</h3>
  </div>
  <div class="panel-body">
    <table class="table table-responsive table-striped table-bordered">
      <thead>
        <tr>
          <th>Date</th>
          <th>AP Name</th>
          <th>SSID</th>
        </tr>
      </thead>
      <tbody>
        <tr ng-repeat="i in userprofile.user.logins">
          <td> {{userprofile.dateOf(i.timestamp)| date: 'yyyy-MM-dd HH:mm'}}</td>
          <td ng-if="!i.apname.length">{{i.apmac}}</td>
          <td ng-if="i.apname.length > 0">{{i.apname}}</td>
          <td>{{i.ssid}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
