(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:DeletevenuemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('orgInfrastructures')
    .controller('OrgInfraeditmodalCtrl', OrgInfraeditmodalCtrl);

  function OrgInfraeditmodalCtrl($modalInstance, Api, $log, infrastructureId) {
    var vm = this;
    vm.ctrlName = 'NewInfrastructuremodalCtrl';

    vm.control = {
      provider: false,
      submitted: false
    };

    vm.infrastructureId = infrastructureId;
    vm.selectedProvider = 0;
    vm.providers = [];

    vm.orgs =[];

    vm.infrastructure = {
      provider: 0,
      controlleraddress: '',
      authentication: '',
      password: '',
      username: '',
      ssidlimit: 0
    };

    vm.matchProvider = function(input, id){
      var returning = false;
      vm.providers.forEach(function(item){
        if(item.id === id){
          if (item.name.toLowerCase() === input){
            returning = true;
          }
        }
      });
      return returning;
    };

    vm.getOrgForInfrastructure = function(){
      Api.getInfraOrgs(5, vm.infrastructure).then(function(res){
        vm.orgs = res.data.organisations.map(function(org){
          return {
            id: '' + org.id,
            name: org.name
          }
        });
        var optionSelect = vm.infrastructure.username;
        vm.infrastructure.username = optionSelect;
      }, function(err){
        console.log(err);
      });
    }

    vm.setProvider = function (provider) {
      vm.control.provider = true;
      vm.selectedProvider = provider;
      if(vm.matchProvider('xirrus', provider)){
        vm.infrastructure.controlleraddress = 'api.cloud.xirrus.com';
      }  else if (vm.matchProvider('meraki', provider)){
        vm.infrastructure.controlleraddress = 'api.meraki.com';
      }else {
        vm.infrastructure.controlleraddress = '';
      }
    };


    Api.getProviders().then(function(res){
      res.data.providers.forEach(function(item){
        if (item.name.toLowerCase() === 'unifi' || item.name.toLowerCase() === 'meraki' || item.name.toLowerCase() === 'ruckus' || item.name.toLowerCase() === 'xirrus') {
          vm.providers.push(item);
        }
      });
    }, function(err){
      console.log(err);
    });
    vm.editInfrastructure = function() {
      Api.updateOrgInfrastructure(vm.infrastructureId, vm.infrastructure).success(function(data) {
        $modalInstance.close();
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };

    vm.getInfrastructure = function() {
      Api.getOrgInfrastructure(vm.infrastructureId).success(function(data) {
        vm.selectedProvider = parseInt(data.infrastructure.provider);
        vm.control.provider = true;
        vm.infrastructure = data.infrastructure;
        vm.getOrgForInfrastructure();
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };

    vm.getInfrastructure();
    vm.ok = function () {
      vm.control.submitted = true;
      vm.editInfrastructure();
      //$modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
