(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name selectimage.controller:SelectimageCtrl
   *
   * @description
   *
   */
  angular
    .module('selectimage')
    .controller('SelectimageCtrl', SelectimageCtrl);

  function SelectimageCtrl($modalInstance, Api, venue) {
    var vm = this;


      Api.getImagesVenue(venue).success(function (data) {
        vm.imageList = data.images;
        // console.log(data.images);
      });
    

    vm.selectedImage = {};

    vm.ok = function () {
      $modalInstance.close(vm.selectedImage);
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

  }
}());
