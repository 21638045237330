(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mailListModal.controller:MailListModalCtrl
   *
   * @description
   *
   */
  angular
    .module('mailListModal')
    .controller('MailListModalCtrl', MailListModalCtrl);

  function MailListModalCtrl(mailList, $modalInstance) {
    var vm = this;
    vm.ctrlName = 'MailListModalCtrl';
    vm.mailList = mailList.data;
    vm.include = mailList.include;
    vm.chimpList = [{'id': 1, 'name':'Guests that used Gym'}, {'id': 2, 'name':'Gym Users'}, {'id': 3, 'name':'Guests No Gym'}];

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
