(function () {
  'use strict';

  angular
    .module('editap')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editap', {
        url: '/editap/:apid/:venueid',
        templateUrl: 'editap/views/editap.tpl.html',
        controller: 'EditapCtrl',
        controllerAs: 'editap'
      });
  }
}());
