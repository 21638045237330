<div class="row">
  <div class="col-xs-11 col-sm-7 col-ms-7">
    <div class="panel panel-default panel-form" ng-hide="activeUser.role === 2">
      <div class="panel-heading clearfix">
        <h4>Venue</h4>
      </div>
      <div class="panel-body">
        <select class="form-control" ng-model="eventcreation.newEvent.venueId" ng-options="venue.id as venue.venuetitle for venue in eventcreation.venues" ng-change="eventcreation.getZones(eventcreation.newEvent.venueId)">
        </select>
      </div>
    </div>
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Name &amp; Description</h4>
      </div>
      <div class="panel-body">
        <div class="form-group" ng-class="{ 'has-error' : createEvent.eventname.$error && eventcreation.stepOne}">
          <label for="eventname" class="control-label pull-left">EVENT NAME</label>
          <input type="text" class="form-control" id="eventname" name="eventname" ng-model="eventcreation.newEvent.name" placeholder="eg. Big Match Day" required>
        </div>
        <div class="form-group">
          <label for="eventdescription" class="control-label pull-left">DESCRIPTION</label>
          <textarea rows="5" class="form-control" id="eventdescription" name="eventdescription" ng-model="eventcreation.newEvent.description" placeholder="brief description of this event"></textarea>
        </div>
        <div ng-messages="createEvent.eventname.$error" ng-show="createEvent.eventname.$error && eventcreation.stepOne">
          <p class="text-danger" ng-message="required">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> EVENT NAME is required</strong>
          </p>
        </div>
        <div ng-show="eventcreation.thesamename && eventcreation.stepOne">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Name is not unique </strong>
            <button class="btn btn-default btn-primary pull-right" ng-click="eventcreation.nameCheckResult()">
              <i class="fa fa-question-circle-o"></i>
              More Info</button>
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Duration</h4>
      </div>
      <div class="panel-body">
        <div class="form-row">
          <div class="form-group text-box long cell cell-pad">
            <label for="startdate" class="control-label pull-left">START DATE</label>
            <input type="text" class="form-control" min-date="eventcreation.minDate" name="startDate" datepicker-popup="{{eventcreation.dateFormat}}" ng-model="eventcreation.dtStart" is-open="eventcreation.status.openedSt" datepicker-options="eventcreation.dateOptions" ng-required="true" close-text="Close" />
          </div>
          <div class="short cell">
            <button type="button" class="btn btn-date" ng-click="eventcreation.openCalSt()">
              <span class="glyphicon glyphicon-calendar"></span>
            </button>
          </div>
          <fieldset ng-disabled="eventcreation.noend">
            <div class="form-group text-box long cell cell-pad">
              <label for="enddate" class="control-label pull-left">END DATE</label>
              <input type="text" class="form-control" min-date="eventcreation.minDate" datepicker-popup="{{eventcreation.dateFormat}}" ng-model="eventcreation.dtEnd" is-open="eventcreation.status.openedEnd" datepicker-options="eventcreation.dateOptions" ng-required="true" close-text="Close" />
            </div>
          </fieldset>
          <div class="short cell">
            <button type="button" class="btn btn-date" ng-click="eventcreation.openCalEnd()" ng-disabled="eventcreation.noend">
              <span class="glyphicon glyphicon-calendar"></span>
            </button>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group text-box long cell cell-pad">
            <label for="starttime" class="control-label pull-left timelable">START TIME</label>
            <timepicker ng-model="eventcreation.startTime" hour-step="eventcreation.hstep" minute-step="eventcreation.mstep" show-meridian="ismeridian" show-spinners="true"></timepicker>
          </div>
          <div class="short cell">

          </div>
          <fieldset ng-disabled="eventcreation.noend">
            <div class="form-group text-box long cell cell-pad">
              <label for="endtime" class="control-label pull-left timelable">END TIME</label>
              <timepicker ng-model="eventcreation.endTime" hour-step="eventcreation.hstep" minute-step="eventcreation.mstep" show-meridian="ismeridian" show-spinners="true"></timepicker>
            </div>
          </fieldset>
          <div class="short cell" style="vertical-align: top; padding-top: 5px">
            <p><strong>No End Date</strong></p>
            <label class="noend">
              <input type="checkbox" ng-model="eventcreation.noend">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <div>
          <p class="text-danger" ng-show="eventcreation.dateError">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>Start Date/End Date Are Required Fields</strong>
          </p>
          <p class="text-danger" ng-show="eventcreation.stTimeInPast">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>Start Date/Time cannot be in the past</strong>
          </p>
          <p class="text-danger" ng-show="eventcreation.endTimeInPast">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>End Date/Time cannot be in the past</strong>
          </p>
          <p class="text-danger" ng-show="eventcreation.endBeforeStart">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong>End Date/Time cannot be prior to Start Date/Time</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>EVENT IMAGE</h4>
      </div>
      <div class="panel-body">
        <div class="form-group">
          <div class="row">
            <div ng-show="eventcreation.eventImageAdded" class="col-md-4 col-md-offset-4">
              <img ng-src="{{staticUrl}}{{eventcreation.eventImage}}" class="img-responsive" ng-if="eventcreation.eventImage"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-danger" ng-click="eventcreation.selectEventImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
              <button class="btn btn-danger" ng-click="eventcreation.uploadEventImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
        <div ng-show="!eventcreation.eventImageAdded && eventcreation.stepOne">
          <p class="text-danger">
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <strong> Event Image is required</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-8 col-sm-5 col-ms-5">
    <h3>Make your WiFi work for you!</h3>
    <p>Set up fast, secure WiFi the way you want it. Limit access time, allow or deny access to any site you want and set user bandwidth to prevent users hogging the network</p>
    <!-- <a href="#">Need Help?</a> -->
  </div>
</div>
</div>
<div class="panel-footer">
  <div class="pull-right">
    <button class="btn btn-default" ng-click="eventcreation.cancelCreate()">Cancel</button>
    <button class="btn btn-default btn-danger" ng-click="eventcreation.nextStep({setUp : createEvent.eventname.$error}, 'admin.eventcreation.evcnetwork')">Next Step</button>
  </div>
  <div class="clearfix"></div>
</div>
