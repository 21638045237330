<div id="support">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center">Submit A Ticket</h3>
    </div>
    <div class="panel-body">
      <div ng-show="support.ErrorMessage">
        <div class="alert alert-danger" role="alert">
          <button ng-click="support.dismiss()" type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
          <span class="sr-only">Error:</span>
          {{support.Message}}
        </div>
      </div>
      <form name="form" ng-submit=" support.createTicket(form.$valid)" role="form" class="col-md-8 col-md-offset-2" novalidate>
        <div ng-hide="activeUser.role === 2">
          <div class="panel panel-default panel-form">
            <div class="panel-heading clearfix">
              <h4>Venue</h4>
            </div>
            <div class="panel-body">
              <div class="form-group">
                <select name="venue" id="venue" ng-model="support.venue" class="form-control" required>
                  <option ng-repeat="venue in support.venues" value="{{venue.venuetitle}}">{{venue.venuetitle}}</option>
                </select>
                </br>
                <span style="color:red" ng-show="form.venue.$invalid && support.submitted">
                  Venue is required!
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Email</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="email" class="form-control" id="email" name="email" placeholder="eg.: example@gmail.com" ng-model="support.email" required/>
              </br>
              <span style="color:red" ng-show="form.email.$invalid && support.submitted">
                Email is required!
              </span>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Name</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="name" name="name" placeholder="eg.: Name" ng-model="support.name" required/>
              </br>
              <span style="color:red" ng-show="form.name.$invalid && support.submitted">
                Name is required!
              </span>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Subject</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <select name="subject" id="subject" ng-model="support.subject" class="form-control" required>
                <option ng-repeat="subject in support.subjects" value="{{subject}}">{{subject}}</option>
              </select>
              </br>
              <span style="color:red" ng-show="form.subject.$invalid && support.submitted">
                Subject is required!
              </span>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Query</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <textarea rows="5" cols="50" class="form-control" id="query" name="query" placeholder="" ng-model="support.query" required>
              </textarea>
              </br>
              <span style="color:red" ng-show="form.query.$invalid && support.submitted">
                Please, specify your query!
              </span>
            </div>
          </div>
        </div>
        <div class="pull-right">
          <button type="submit" class="btn btn-danger">Send</button>
          <button type="submit" class="btn btn-info " onclick="history.back()">Cancel</button>
        </div>
    </div>
    <div ng-show="support.EmailConfirmMessage">
      <div class="alert alert-success" role="alert" style="width: 40%;">
        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        <span class="sr-only">Alert:</span>
        {{support.EmailMessage}}
      </div>
    </div>
    <div ng-show="support.EmailErrorMessage">
      <div class="alert alert-danger" role="alert" style="width: 40%;">
        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        <span class="sr-only">Alert:</span>
        {{support.EmailMessage}}
      </div>
    </div>
    </form>
  </div>
</div>
</div>
