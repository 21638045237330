(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisations.controller:OrgdeletemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('modalservice')
    .controller('BulkZoneCreateModalCtrl', BulkZoneCreateModalCtrl);

  function BulkZoneCreateModalCtrl($modalInstance, Api, infrastructure, $cookies) {
    var vm = this;
    vm.ctrlName = 'BulkZoneCreateModalCtrl';
    vm.controller = infrastructure;
    vm.wlans = [];
    vm.zones = [];
    vm.selected = [];
    vm.error = '';
    vm.selectAllButton = 'Select All';

    vm.getZones= function () {
      Api.getZones(vm.controller.venue).success(function(data){
        data.zones.forEach(function(item){
          if(item.details.wlangroup){
            vm.zones.push(item);
          } else if (item.details.siteid){
            item.details.wlangroup = item.details.siteid;
            vm.zones.push(item);
          }
        });
      }).error(function(error){
        vm.error = 'There was a problem Creating your Zones. Please check you controller.';
      });
    };

    vm.getWlans = function () {
      Api.getWlans(vm.controller.provider, vm.controller).success(function(data){
        var cookie = $cookies.get('unifitoken');
        if (cookie === undefined) {
          var expireDate =  new Date((new Date()).getTime() + 10*60000);
          $cookies.put('unifitoken', data.cookie, {'expires': expireDate});
        }
        var siteIndex;
        for(siteIndex = 0; siteIndex < data.data.length; siteIndex++) {
          var wlan = data.data[siteIndex];
          wlan.status = 'Select';
          vm.zones.forEach(function(item){
            if (item.details.wlangroup === wlan._id) {
              wlan.status = 'Created';
            }
          });
          vm.wlans.push(wlan);
        }
      }).error(function(error){
        vm.error = 'There was a problem Creating your Zones. Please check you controller.';
      });
    };
    vm.getZones();
    vm.getWlans();

    vm.selectedSite = function(wlan) {
      vm.selected.push(wlan);
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.wlans.length; siteIndex++) {
        if (wlan._id ===  vm.wlans[siteIndex]._id) {
          vm.wlans[siteIndex].status = 'Selected';
        }
      }
    };

    vm.deselectSite = function(wlan) {
      vm.selectAllButton = 'Select All';
      var selectIndex;
      for(selectIndex = 0; selectIndex < vm.selected.length; selectIndex++) {
        if (wlan.name ===  vm.selected[selectIndex].name) {
          vm.selected.splice(selectIndex, selectIndex+1);
        }
      }
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.wlans.length; siteIndex++) {
        if (wlan._id ===  vm.wlans[siteIndex]._id) {
          vm.wlans[siteIndex].status = 'Select';
        }
      }
    };

    vm.selectAll = function() {
      var siteIndex;
      if (vm.selectAllButton === 'Select All') {
        for(siteIndex = 0; siteIndex < vm.wlans.length; siteIndex++) {
          if (vm.wlans[siteIndex].status === 'Select') {
            vm.selected.push(vm.wlans[siteIndex]);
            vm.wlans[siteIndex].status = 'Selected';
          }
        }
        vm.selectAllButton = 'Deselect All';
      } else {
        for(siteIndex = 0; siteIndex < vm.wlans.length; siteIndex++) {
          if (vm.wlans[siteIndex].status === 'Selected') {
            vm.selected = [];
            vm.wlans[siteIndex].status = 'Select';
          }
        }
        vm.selectAllButton = 'Select All';
      }
    };

    vm.bulkCreate = function() {
      var bulk = {
        venueId: vm.controller.venue,
        zones: []
      };
      vm.selected.forEach(function(item){
        var zone = {
          name: item.name,
          details: {wlangroup:item._id}
        };
        if (vm.controller.provider === 6) {
          zone.details.siteid = zone.details.wlangroup;
          delete zone.details['wlangroup'];
        }
        bulk.zones.push(zone);
      });
      Api.bulkZone(vm.controller.venue, bulk).success(function(data){
        data = {};
        $modalInstance.close();
      }).error(function(error){
        vm.error = 'There was a problem Creating your Zones. Please check you controller.';
      });
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
