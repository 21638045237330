<div id="newitem">
  <div class="col-md-8 col-md-offset-2">
    <div ng-show="editclservice.errorMessage">
      <div class="alert alert-danger" role="alert">
        <button ng-click="editclservice.dismiss()" type="button" class="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
        <span class="sr-only">Error: </span>
        {{editclservice.error}}
      </div>
    </div>
  </div>
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center">Edit Cloud Service: {{editclservice.cloudserviceDetails.type}}</h3>
    </div>
    <div class="panel-body">
      <div ng-show="editclservice.cloudserviceDetails.type === 'Twilio'" class="col-sm-8 col-sm-offset-2">
        <form name="twilioForm" novalidate>
          <div class="panel panel-default panel-form">
            <div class="panel-heading clearfix">
              <h4>Twilio Settings</h4>
            </div>
            <div class="panel-body">
              <div class="form-group" ng-class="{ 'has-error': twilioForm.apiKey.$invalid && editclservice.submitted }">
                <h4>AUTH TOKEN:</h4>
                <input type="text" class="form-control" id="apiKey" name="apiKey" ng-model="editclservice.cloudserviceDetails.apikey" placeholder="eg. xxxxxxxxxx" required>
              </div>
              <div class="help-block" ng-messages="twilioForm.apiKey.$error" ng-if="editclservice.submitted">
                <p class="text-danger" ng-message="required"><strong>AUTH TOKEN is required.</strong></p>
              </div>
              <div class="form-group" ng-class="{ 'has-error': twilioForm.sid.$invalid && editclservice.submitted }">
                <h4>ACCOUNT SID:</h4>
                <input type="text" class="form-control" id="sid" name="sid" ng-model="editclservice.cloudserviceDetails.accountsid" placeholder="eg. xxxxxxxxxx" required>
              </div>
              <div class="help-block" ng-messages="twilioForm.sid.$error" ng-if="editclservice.submitted">
                <p class="text-danger" ng-message="required"><strong>ACCOUNT SID is required.</strong></p>
              </div>
              <div class="form-group" ng-class="{ 'has-error': twilioForm.phone.$invalid && editclservice.submitted }">
                <h4>ACCOUNT PHONE:</h4>
                <input type="text" class="form-control" id="phone" name="phone" ng-model="editclservice.cloudserviceDetails.phonenumber" placeholder="eg. 353861212122" required>
              </div>
              <div class="help-block" ng-messages="twilioForm.phone.$error" ng-if="editclservice.submitted">
                <p class="text-danger" ng-message="required"><strong>ACCOUNT PHONE is required.</strong></p>
              </div>
            </div>
          </div>
        </form>
        <button class="btn btn-danger" ng-click="editclservice.udpateCloudService(!twilioForm.$invalid, editclservice.cloudserviceDetails)">Submit</button>
      </div>
      <div ng-show="editclservice.cloudserviceDetails.type === 'MailChimp'" class="col-sm-8 col-sm-offset-2">
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>MailChimp Settings</h4>
          </div>
          <div class="panel-body">
            <form name="mailchimpForm" novalidate>
              <div class="form-group" ng-class="{ 'has-error': mailchimpForm.apiKey.$invalid && editclservice.submitted }">
                <h4>API KEY:</h4>
                <input type="text" class="form-control" id="apiKey" name="apiKey" ng-model="editclservice.cloudserviceDetails.apikey" placeholder="eg. xxxxxxxxxx-xyz" ng-change="editclservice.updateApiKey()" required>
              </div>
              <div class="help-block" ng-messages="mailchimpForm.apiKey.$error" ng-if="editclservice.submitted">
                <p class="text-danger" ng-message="required"><strong>API KEY is required.</strong></p>
              </div>
              <div ng-show="editclservice.updatedApi">
                <div class="alert alert-success" role="alert" style="width: 40%;">
                  <button ng-click="editclservice.dismiss();" type="button" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                  <span class="sr-only">Alert:</span>
                  {{editclservice.apiMessage}}
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-xs-12">
                    <h4>Select Default List:</h4>
                    <p>Choose a mailing list from the dropdown menu or create a
                      <button ng-click="editclservice.createNewList()" type="button" class="btn btn-danger btn-sm">New List</button>
                    </p>
                    <select class="form-control" ng-model="editclservice.cloudserviceDetails.listidentifier">
                      <option ng-repeat="list in editclservice.lists" value="{{list.id}}">{{list.name}} - {{list.id}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
            <button class="btn btn-danger" ng-click="editclservice.udpateCloudService(!mailchimpForm.$invalid, editclservice.cloudserviceDetails)">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
