<form name="createEvent" novalidate>
  <h2 class="text-center">Edit Event - {{editevent.event.name}}</h2>
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <ul class="breadcrumb">
        <li ui-sref-active="active">
          <a ui-sref="admin.editevent.editsetup" ng-hide="true"><i class="fa fa-2x fa-sliders"></i></br>Setup</a>
          <a href="" ng-click="editevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, 'admin.editevent.editsetup')"><i class="fa fa-2x fa-sliders"></i></br>Setup</a>
        </li>
        <li ui-sref-active="active">
          <a ui-sref="admin.editevent.editnetwork" ng-hide="true"><i class="fa fa-2x fa-cogs"></i></br>Network</a>
          <a ng-click="editevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, 'admin.editevent.editnetwork')" href=""><i class="fa fa-2x fa-cogs"></i></br>Network</a>
        </li>
        <li ui-sref-active="active">
          <a ui-sref="admin.editevent.editsplash" ng-hide="true"><i class="fa fa-2x fa-mobile"></i></br>Splash Page</a>
          <a ng-click="editevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, 'admin.editevent.editsplash')" href=""><i class="fa fa-2x fa-mobile"></i></br>Splash Page</a>
        </li>
        <li ui-sref-active="active" role="presentation">
          <a ui-sref="admin.editevent.editconfirm" ng-hide="true"><i class="fa fa-2x fa-check-circle-o"></i></br>Confirm</a>
          <a href="" ng-click="editevent.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, 'admin.editevent.confirm')"><i class="fa fa-2x fa-check-circle-o"></i></br>Confirm</a>
        </li>
      </ul>
    </div>
    <div class="panel-body">
    <div data-ui-view></div>
    </div>
</form>
