(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name organisations.controller:OrgdeletemodalCtrl
   *
   * @description
   *
   */
  angular
    .module('modalservice')
    .controller('BulkVenueCreateModalCtrl', BulkVenueCreateModalCtrl);

  function BulkVenueCreateModalCtrl($modalInstance, Api, infrastructure, $cookies) {
    var vm = this;
    vm.ctrlName = 'BulkVenueCreateModalCtrl';
    vm.controller = infrastructure;
    vm.sites = [];
    vm.venues = [];
    vm.selected = [];
    vm.error = '';
    vm.selectAllButton = 'Select All';

    vm.getVenues= function () {
      Api.getVenues().success(function(data){
        data.venues.forEach(function(item){
          if(item.siteid){
            vm.venues.push(item);
          }
        });
      }).error(function(error){
        console.log(error.message);
        vm.error = 'There was a problem Creating your Venues. Please check you controller.';
      });
    };

    vm.getSites = function () {
      Api.getSites(vm.controller.provider, vm.controller).success(function(data){
        var siteIndex;
        if(data.cookie){
          var cookie = $cookies.get('unifitoken');
          if (cookie === undefined) {
            var expireDate =  new Date((new Date()).getTime() + 10*60000);
            $cookies.put('unifitoken', data.cookie, {'expires': expireDate});
          }
        }
        for(siteIndex = 0; siteIndex < data.data.length; siteIndex++) {
          var site = data.data[siteIndex];
          site.status = 'Select';
          vm.venues.forEach(function(item){
            if (item.siteid === site.id) {
              site.status = 'Created';
            }
          });
          vm.sites.push(site);
        }
      }).error(function(error){
        vm.error = 'There was a problem Creating your Venues. Please check you controller.';
      });
    };
    vm.getVenues();
    vm.getSites();

    vm.selectedSite = function(site) {
      vm.selected.push(site);
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.sites.length; siteIndex++) {
        if (site.id ===  vm.sites[siteIndex].id) {
          vm.sites[siteIndex].status = 'Selected';
        }
      }
      vm.selectAllButton = 'Deselect All';
    };

    vm.deselectSite = function(site) {
      var selectIndex;
      for(selectIndex = 0; selectIndex < vm.selected.length; selectIndex++) {
        if (site.id ===  vm.selected[selectIndex].id) {
          vm.selected.splice(selectIndex, selectIndex+1);
        }
      }
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.sites.length; siteIndex++) {
        if (site.id ===  vm.sites[siteIndex].id) {
          vm.sites[siteIndex].status = 'Select';
        }
      }
      if (vm.selected.length === 0) {
        vm.selectAllButton = 'Select All';
      }
    };

    vm.selectAll = function() {
      var siteIndex;
      if (vm.selectAllButton === 'Select All') {
        for(siteIndex = 0; siteIndex < vm.sites.length; siteIndex++) {
          if (vm.sites[siteIndex].status === 'Select') {
            vm.selected.push(vm.sites[siteIndex]);
            vm.sites[siteIndex].status = 'Selected';
          }
        }
        vm.selectAllButton = 'Deselect All';
      } else {
        for(siteIndex = 0; siteIndex < vm.sites.length; siteIndex++) {
          if (vm.sites[siteIndex].status === 'Selected') {
            vm.selected = [];
            vm.sites[siteIndex].status = 'Select';
          }
        }
        vm.selectAllButton = 'Select All';
      }
    };

    vm.bulkCreate = function() {
      var bulk = {
        infrastructure: vm.controller,
        venues: vm.selected.map(function(item){
          return {
            id: item.id,
            name: item.site
          }
        })
      };
      bulk.infrastructure.providerId = bulk.infrastructure.provider;

      if (bulk.infrastructure.providerId === 5) {
        bulk.infrastructure.details = {"mOrgId": bulk.infrastructure.username};
      } else {
        bulk.infrastructure.details = JSON.stringify({});
      }


      Api.setGuestSettings(bulk.infrastructure.provider, bulk).then(function successCallback(response) {
        Api.createBulkVenues(bulk.infrastructure.provider, bulk).success(function(data){
          data = {};
          $modalInstance.close();
        }).error(function(error){
          console.log(error.message);
          vm.error = 'There was a problem Creating your Venues. Please check you controller.';
        });
        // }
      }, function errorCallback(response) {
          console.log(error.message);
          vm.error = 'There was a problem Creating your Venues. Please check you controller.';
      });

    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
