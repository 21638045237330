(function () {
  'use strict';

  angular
    .module('editvenue')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editvenue', {
        url: '/editvenue/:venueid',
        templateUrl: 'editvenue/views/editvenue.tpl.html',
        controller: 'EditvenueCtrl',
        controllerAs: 'editvenue'
      });
  }
}());
