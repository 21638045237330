<div class="simpleDemo">
  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="row row-align">
        <h3>Customer Segmentation</h3>
      </div>
    </div>
    <div class="panel-body">
      <div class="row margin_top">
        <div class="col-md-6">
          <div class="panel panel-default">
            <div class="panel-heading">
              <div class="row row-align">
                <p>Users by Tag</p>
              </div>
            </div>
            <div class="panel-body">
              <canvas id="base" class="chart chart-bar" chart-data="tags.barChart.data" chart-labels="tags.barChart.labels" chart-options="tags.barChart.options" chart-series="tags.barChart.series" chart-colors="tags.chartColors">
              </canvas>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="panel panel-default">
            <div class="panel-heading">
              <div class="row row-align">
                <p>Location Tags
                  <button ng-click="tags.createTag()" class="btn btn-danger pull-right">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </p>
              </div>
            </div>
            <div class="panel-body">
              <table class="table">
                <thead>
                  <tr>
                    <th>Tags</th>
                    <th>Description</th>
                    <th>Duration</th>
                    <th>Users</th>
                  </tr>
                </thead>
                <tbody>
                  <tr ng-repeat="tag in tags.tags">
                    <td>{{tag.name}}</td>
                    <td>{{tag.description}}</td>
                    <td>{{tag.duration}} mins</td>
                    <td>{{tag.users.length}}</td>
                    <td>
                      <div class="pull-right">
                        <button class="btn btn-danger" tooltip-placement="left" tooltip="Update Tag" ng-click="tags.updateTag(tag._id)">
                          <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button class="btn btn-danger btn-md" ng-click="tags.deleteTag(tag._id)" tooltip-placement="left" tooltip="Delete Tag">
                          <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-default">
            <div class="panel-heading">
              <div class="row row-align">
                <p>Generate Email List from Tags</p>
              </div>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="panel panel-info pheight">
                    <div class="panel-heading">
                      Tags
                    </div>
                    <div class="panel-body">
                      <ul dnd-list="tags.selectFrom">
                        <li ng-repeat="item in tags.selectFrom" dnd-draggable="item" dnd-moved="tags.selectFrom.splice($index, 1)" dnd-effect-allowed="move" dnd-selected="models.selected = item" ng-class="{'selected': models.selected === item}">
                          {{item.name}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="panel panel-success">
                    <div class="panel-heading">
                      Include
                    </div>
                    <div class="panel-body">
                      <ul dnd-list="tags.include">
                        <li ng-repeat="item in tags.include" dnd-draggable="item" dnd-moved="tags.include.splice($index, 1)" dnd-effect-allowed="move" dnd-selected="models.selected = item" ng-class="{'selected': models.selected === item}">
                          {{item.name}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="panel panel-danger">
                    <div class="panel-heading">
                      Exclude
                    </div>
                    <div class="panel-body">
                      <ul dnd-list="tags.exclude">
                        <li ng-repeat="item in tags.exclude" dnd-draggable="item" dnd-moved="tags.exclude.splice($index, 1)" dnd-effect-allowed="move" dnd-selected="models.selected = item" ng-class="{'selected': models.selected === item}">
                          {{item.name}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class=col-md-12>
                  <button class="btn btn-danger" ng-click="tags.resetEmail()">
                    Reset
                  </button>
                  <button class="btn btn-danger" ng-click="tags.generateEmailList()" ng-disabled="tags.include.length <= 0 && tags.exclude.length <=0">
                    Generate Email List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="panel panel-default" ng-show="tags.gotemail">
            <div class="panel-heading">
              <div class="row row-align">
                <p>Email List</p>
              </div>
            </div>
            <div class="panel-body">
              <div class="row">
                <table class="table table-condensed table-bordered table-striped">
                  <tr dir-paginate="email in tags.emailList | itemsPerPage: 10">
                    <td>{{email.login}}</td>
                  </tr>
                </table>
                <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <p>Send to MailChimp - Select List</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <select class="form-control" ng-model="tags.selectedList" ng-options="item.id as item.name for item in tags.mailChimpList">
                  </select>
                </div>
                <div class="col-md-4">
                  <button class="btn btn-danger" ng-click="tags.sendToList()">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
