<div class="modal-header">
  <img class="modal-img" src="./images/login/modal_image.png">
</div>
<div class="modal-body">
  <h4>Create New Tag</h4>
  <form class="form">
    <div class="form-group">
      <label for="tagname">Name</label>
      <input type="text" class="form-control" id="tagname" placeholder="Tag Name" ng-model="tagmodal.tagName">
    </div>
    <div class="form-group">
      <label for="tagdescript">Description</label>
      <input type="text" class="form-control" id="tagdescript" placeholder="Tag Description" ng-model="tagmodal.tagDescription">
    </div>
    <div class="form-group">
      <label for="tagduration">Duration (minutes)</label>
      <input type="number" class="form-control" id="tagduration" ng-model="tagmodal.tagDuration">
    </div>
    <div class="form-group">
      <label>Zones</label>
    <ul class="">
      <li class="checkbox" dir-paginate="zone in tagmodal.zones |itemsPerPage: 10">
        <label>
          <input type="checkbox" checklist-model="tagmodal.zoneList" checklist-value="{zoneId:zone.id}">
          {{zone.name}}
        </label>
      </li>
    </ul>
    <dir-pagination-controls max-size="10" direction-links="true" boundary-links="true"></dir-pagination-controls>
  </div>
  </form>
</div>
<div class="modal-footer">
  <button class="btn btn-warning" type="button" ng-click="tagmodal.cancel()">Cancel</button>
  <button class="btn btn-danger" type="button" ng-click="tagmodal.ok()">Submit</button>
</div>
