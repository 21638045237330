(function () {
  'use strict';

  angular
    .module('newlist')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.newlist', {
        url: '/newlist/:apiKey/:venueid',
        templateUrl: 'newlist/views/newlist.tpl.html',
        controller: 'NewlistCtrl',
        controllerAs: 'newlist'
      });
  }
}());
