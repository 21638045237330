(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name tandceditor.controller:TandceditorCtrl
   *
   * @description
   *
   */
  angular
    .module('tandceditor')
    .controller('TandceditorCtrl', TandceditorCtrl);

  function TandceditorCtrl($stateParams, Auth, Tandc) {
    var vm = this;
    vm.venue = $stateParams.venueid || undefined;
    vm.htmlVariable = '';
    vm.edit = false;
    vm.tandcID;
    console.log(vm.venue);

    var orgid = Auth.getAuthData().operator.organisation;

    var checkTandC = function () {
      if (vm.venue) {
        Tandc.getTandCbyVenue(orgid, vm.venue).then(function (result) {
          console.log(result);
          vm.htmlVariable = result.data.tandc;
          vm.tandcID = result.data.id;
          if (result.status === 200 && result.data.tandc.length > 0) {
            vm.edit = true;
          }
          console.log(vm.edit);
          console.log(result);
        }, function (err) {
          console.log(err);
        });
      } else {
        Tandc.getTandCbyOrg(orgid).then(function (result) {
          vm.htmlVariable = result.data.tandc;
          vm.tandcID = result.data.id;
          if (result.status === 200 && result.data.tandc.length > 0) {
            vm.edit = true;
          }
          console.log(vm.edit);
          console.log(result);
        }, function (err) {
          console.log(err);
        });
      }
    };

    vm.createTandC = function () {
      var tandc = {
        org: orgid,
        tandc: vm.htmlVariable
      };

      if (vm.venue) {
        tandc.venue = vm.venue;
      }

      if (!vm.edit) {
        Tandc.createTandC(tandc).then(function (result) {
          console.log(result);
        }, function (err) {
          console.log(err);
        });
      } else {
        tandc.id = vm.tandcID;
        console.log(vm.tandc);
        Tandc.updateTandC(tandc).then(function (result) {
          console.log(result);
        }, function (err) {
          console.log(err);
        });
      }
    };

    vm.deleteTandC = function () {
      var tandc = {
        org: orgid,
        id: vm.tandcID
      };
      Tandc.deleteTandC(tandc).then(function (result) {
        vm.htmlVariable = '';
        vm.edit = false;
        console.log(result);
      }, function (err) {
        console.log(err);
      });
    };
    checkTandC();
  }
}());
