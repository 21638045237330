(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name orgInfrastructures.controller:OrgInfrastructuresCtrl
   *
   * @description
   *
   */
  angular
    .module('orgInfrastructures')
    .controller('OrgInfrastructuresCtrl', OrgInfrastructuresCtrl);

  function OrgInfrastructuresCtrl(Api, $log, $q, $stateParams, $state, $modal) {
    var vm = this;
    vm.ctrlName = 'OrgInfrastructuresCtrl';

    vm.infrastructures = [];
    vm.providers = [];

    vm.getInfrastructures = function(){
      Api.getOrgInfrastructures().success(function(data) {
        vm.infrastructures = data.infrastructures;
        console.log(vm.infrastructures);
      }).error(function() {
        $log.info('get Infrastructure error');
      });
    };
    Api.getProviders().then(function(res){
      vm.providers = res.data.providers;
    }, function(err){
      console.log(err);
    });
    vm.createInfrastructure = function() {
      var newModalInstance = $modal.open({
        templateUrl: 'org-infrastructures/views/newinfrastructuremodel.tpl.html',
        controller: 'NewInfrastructuremodalCtrl as newinfrastructuremodel'
      });

      newModalInstance.result.then(function() {
        vm.getInfrastructures();
        console.log('submitted');
      });
    };

    vm.deleteInfrastructure = function(id) {
      var newModalInstance = $modal.open({
        templateUrl: 'org-infrastructures/views/orginfradeletemodal.tpl.html',
        controller: 'OrgInfradeletemodalCtrl as orginfradeleteModal'
      });

      newModalInstance.result.then(function() {
        Api.deleteOrgInfrastructure(id).success(function(data) {
          $log.info(data);
          vm.getInfrastructures();
        }).error(function() {
          $log.info('delete Infrastructure error');
        });
      });
    };

    vm.editInfrastructure = function (id) {
      var newModalInstance = $modal.open({
        templateUrl: 'org-infrastructures/views/orginfraeditmodal.tpl.html',
        controller: 'OrgInfraeditmodalCtrl as orginfraeditModal',
        resolve: {
          infrastructureId: id
        }
      });

      newModalInstance.result.then(function() {
        vm.getInfrastructures();
        console.log('submitted');
      });
    };

    vm.configController = function (infrastructure) {
      var newModalInstance = $modal.open({
        templateUrl: 'modalservice/views/bulkVenueCreateModal.tpl.html',
        controller: 'BulkVenueCreateModalCtrl as bulkVenueCreateModal',
        resolve: {
          infrastructure: function(){
            return infrastructure;
          }
        }
      });
      newModalInstance.result.then(function() {
        var modalMessage = {
          ok: 'Ok',
          messageHeader: 'Your venue has been queued for creation, it will appear in the venue list on completion.',
        };

        var modalInstance = $modal.open({
          templateUrl: 'modalservice/views/modalservice.tpl.html',
          controller: 'ModalserviceCtrl as modalService',
          resolve: {
            message: function () {
              return modalMessage;
            }
          }
        });
        modalInstance.result.then(function () {
          $state.go('admin.venues');
        });
      });
    };

    vm.matchProvider = function(input, id){
      var returning = false;
      vm.providers.forEach(function(item){
        if(item.id === id){
          if (item.name.toLowerCase() === input){
            returning = true;
          }
        }
      });
      return returning;
    };


    vm.getInfrastructures();

  }
}());
