(function () {
  'use strict';

  angular
    .module('operators')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.operators', {
        url: '/operators',
        templateUrl: 'operators/views/operators.tpl.html',
        controller: 'OperatorsCtrl',
        controllerAs: 'operators'
      });
  }
}());
