(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name event.controller:EventCtrl
   *
   * @description
   *
   */
  angular
    .module('event')
    .controller('EventCtrl', EventCtrl);

  function EventCtrl($stateParams, Api) {
    var vm = this;
    vm.ctrlName = 'EventCtrl';
    vm.socialLoginTypes = [];
    Api.getEvent($stateParams.eventid, $stateParams.venueid).success(function (data) {
      vm.event = data.event;
      for (var i =0; i < vm.event.socialLoginTypes.length; i++){
        vm.socialLoginTypes.push(vm.event.socialLoginTypes[i].id);
      }
    });
  }
}());
