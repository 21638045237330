(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name support.controller:SupportCtrl
   *
   * @description
   *
   */
  angular
    .module('support')
    .controller('SupportCtrl', SupportCtrl);

  function SupportCtrl($rootScope, $stateParams, $state, $log, Api, Auth) {
    var vm = this;
    vm.ctrlName = 'SupportCtrl';
    vm.venueid = $stateParams.venueid;
    vm.venues = [];
    vm.Message = [];
    vm.ErrorMessage = false;
    vm.EmailConfirmMessage = false;
    vm.EmailErrorMessage = false;
    vm.EmailMessage = [];
    $rootScope.activeUser = '';
    vm.subbmited = false;

    vm.subjects = ['Dashboard', 'Splashpage', 'Other'];

    if (Auth.getAuthData()) {
      vm.profileInfo = Auth.getAuthData().operator;
      $rootScope.activeUser = Auth.getAuthData().operator;
    }
    //console.log('admin', vm.profileInfo.role);

    function getVenues() {
      Api.getVenues().success(function (data) {
        vm.venues = data.venues;
        vm.limit = vm.venues.length;
        vm.all = vm.venues.length;
      }).error(function () {
        $log.info('get venues error');
      });
    }
    getVenues();

    vm.createTicket = function (isvalid) {
      vm.submitted = true;
      var data = '';
      data = JSON.stringify({
        name: vm.name,
        email: vm.email,
        venue: vm.venue,
        subject: vm.subject,
        query: vm.query
      });
      console.log('data', data);
      if (isvalid) {
        Api.createTicket(data).success(function () {
          vm.EmailConfirmMessage = true;
          vm.EmailMessage = 'Email sent, thank you';
          $state.go('admin.support');
        }).error(function (err) {
          vm.ErrorMessage = true;
          vm.Message = err.message;
        });
      }
    };

    vm.dismissError = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

  }
}());
