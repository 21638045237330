(function () {
  'use strict';

  angular
    .module('eventcreation')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.eventcreation', {
        url: '/eventcreation',
        templateUrl: 'eventcreation/views/eventcreation.tpl.html',
        controller: 'EventcreationCtrl',
        controllerAs: 'eventcreation'
      })
      .state('admin.eventcreation.evcsetup', {
        url: '/evcsetup',
        templateUrl: 'eventcreation/views/evcsetup.tpl.html'
      })
      .state('admin.eventcreation.evcnetwork', {
        url: '/evcnetwork',
        templateUrl: 'eventcreation/views/evcnetwork.tpl.html'
      })
      .state('admin.eventcreation.evcsplash', {
        url: '/evcsplash',
        templateUrl: 'eventcreation/views/evcsplash.tpl.html'
      })
      .state('admin.eventcreation.evcfeature', {
        url: '/evcfeature',
        templateUrl: 'eventcreation/views/evcfeature.tpl.html'
      })
      .state('admin.eventcreation.evcconfirm', {
        url: '/evcconfirm',
        templateUrl: 'eventcreation/views/evcconfirm.tpl.html'
      });
  }
}());
