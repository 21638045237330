<div id="cloudservices">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="text-center">Add Cloud Service</h3>
    </div>
    <div class="panel-body">
      <div class="col-md-8 col-md-offset-2">
        <div ng-show="addclservice.errorMessage">
          <div class="alert alert-danger" role="alert">
            <button ng-click="addclservice.dismiss()" type="button" class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
            <span class="sr-only">Error: </span>
            {{addclservice.error}}
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Cloud Service Type </h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <select name="type" id="type" ng-options="type.name for type in addclservice.cloudservicetypes track by type.id" ng-model="addclservice.selectedType" class="form-control">
              </select>
            </div>
          </div>
        </div>
          <div>
            <div ng-show="addclservice.selectedType.name === 'MailChimp'">
              <div class="panel panel-default panel-form">
                <div class="panel-heading clearfix">
                  <h4>MailChimp Settings</h4>
                </div>
                <div class="panel-body">
                  <form name="mailchimpForm" novalidate>
                    <div class="form-group" ng-class="{ 'has-error': mailchimpForm.apiKey.$invalid && addclservice.submitted }">
                      <h4>API KEY:</h4>
                      <input type="text" class="form-control" id="apiKey" name="apiKey" ng-model="addclservice.apiKey" placeholder="eg. xxxxxxxxxx-xyz" ng-change="addclservice.updateApiKey()" required>
                    </div>
                    <div class="help-block" ng-messages="mailchimpForm.apiKey.$error" ng-if="addclservice.submitted">
                      <p class="text-danger" ng-message="required"><strong>API KEY is required.</strong></p>
                    </div>
                    <div ng-show="addclservice.updatedApi">
                      <div class="alert alert-success" role="alert" style="width: 40%;">
                        <button ng-click="addclservice.dismiss();" type="button" class="close" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                        <span class="sr-only">Alert:</span>
                        {{addclservice.apiMessage}}
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-xs-12">
                          <h4>Select Default List:</h4>
                          <p>Choose a mailing list from the dropdown menu or create a
                            <button ng-click="addclservice.createNewList()" type="button" class="btn btn-danger btn-sm">New List</button>
                          </p>
                          <select class="form-control" ng-model="addclservice.newListId">
                            <option ng-repeat="list in addclservice.lists" value="{{list.id}}">{{list.name}} - {{list.id}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                  <button class="btn btn-danger" ng-click="addclservice.createCloudService(!mailchimpForm.$invalid, {apikey: addclservice.apiKey, listidentifier: addclservice.newListId})">Submit</button>
                </div>
              </div>
            </div>
            <div ng-show="addclservice.selectedType.name === 'Twilio'">
              <form name="twilioForm" novalidate>
                <div class="panel panel-default panel-form">
                  <div class="panel-heading clearfix">
                    <h4>Twilio Settings</h4>
                  </div>
                  <div class="panel-body">
                    <div class="form-group" ng-class="{ 'has-error': twilioForm.apiKey.$invalid && addclservice.submitted }">
                      <h4>AUTH TOKEN:</h4>
                      <input type="text" class="form-control" id="apiKey" name="apiKey" ng-model="addclservice.apiKey" placeholder="eg. xxxxxxxxxx" required>
                    </div>
                    <div class="help-block" ng-messages="twilioForm.apiKey.$error" ng-if="addclservice.submitted">
                      <p class="text-danger" ng-message="required"><strong>AUTH TOKEN is required.</strong></p>
                    </div>
                    <div class="form-group" ng-class="{ 'has-error': twilioForm.sid.$invalid && addclservice.submitted }">
                      <h4>ACCOUNT SID:</h4>
                      <input type="text" class="form-control" id="sid" name="sid" ng-model="addclservice.accountsid" placeholder="eg. xxxxxxxxxx" required>
                    </div>
                    <div class="help-block" ng-messages="twilioForm.sid.$error" ng-if="addclservice.submitted">
                      <p class="text-danger" ng-message="required"><strong>ACCOUNT SID is required.</strong></p>
                    </div>
                    <div class="form-group" ng-class="{ 'has-error': twilioForm.phone.$invalid && addclservice.submitted }">
                      <h4>ACCOUNT PHONE:</h4>
                      <input type="text" class="form-control" id="phone" name="phone" ng-model="addclservice.phonenumber" placeholder="eg. 353861212122" required>
                    </div>
                    <div class="help-block" ng-messages="twilioForm.phone.$error" ng-if="addclservice.submitted">
                      <p class="text-danger" ng-message="required"><strong>ACCOUNT PHONE is required.</strong></p>
                    </div>
                  </div>
                </div>
              </form>
              <button class="btn btn-danger" ng-click="addclservice.createCloudService(!twilioForm.$invalid, {apikey: addclservice.apiKey, accountsid: addclservice.accountsid, phonenumber: addclservice.phonenumber})">Submit</button>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
