<div class="row">
  <div ng-show="duplicateevent.setupPassed">
    <div class="col-md-2">
      <div class="confSuccess">
        <i class="fa fa-4x fa-check-circle"></i>
      </div>
    </div>
    <div class="col-md-8">
      <h4 class="text-left">Setup</h4>
      <ul class="confUl">
        <li>
          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Event Name,</li>
        <li>
          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Start/End Date,</li>
        <li>
          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Event Image,</li>
        <li>
          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Access Points</li>
      </ul>
    </div>
    <div class="col-md-2">
      <button class="btn btn-button btn-block btn-select" ui-sref="admin.duplicateevent.duplicatesetup">edit</button>
    </div>
  </div>
  <div ng-hide="duplicateevent.setupPassed">
    <div class="col-md-2">
      <div class="confFail">
        <i class="fa fa-4x fa-times-circle"></i>
      </div>
    </div>
    <div class="col-md-8">
      <h4 class="text-left">Setup</h4>
      <p>Setup Incomplete</p>
    </div>
    <div class="col-md-2">
      <button class="btn btn-button btn-block btn-select" ui-sref="admin.duplicateevent.duplicatesetup">edit</button>
    </div>
  </div>
</div>
<div class="row">
  <div ng-show="duplicateevent.netPassed">
    <div class="col-md-2">
      <div class="confSuccess">
        <i class="fa fa-4x fa-check-circle"></i>
      </div>
    </div>
    <div class="col-md-8">
      <h4 class="text-left">Network</h4>
      <ul class="confUl">
        <li>
          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Network Name,</li>
        <li>
          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Network Optomization,</li>
        <li>
          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Network Bandwidth</li>
      </ul>
    </div>
    <div class="col-md-2">
      <button class="btn btn-button btn-block btn-select" ui-sref="admin.duplicateevent.duplicatenetwork">edit</button>
    </div>
  </div>
  <div ng-hide="duplicateevent.netPassed">
    <div class="col-md-2">
      <div class="confFail">
        <i class="fa fa-4x fa-times-circle"></i>
      </div>
    </div>
    <div class="col-md-8">
      <h4 class="text-left">Network</h4>
      <p>Network Incomplete</p>
    </div>
    <div class="col-md-2">
      <button class="btn btn-button btn-block btn-select" ui-sref="admin.duplicateevent.duplicatenetwork">edit</button>
    </div>
  </div>
</div>
<div class="row">
  <div ng-show="duplicateevent.splashPassed">
    <div class="col-md-2">
      <div class="confSuccess">
        <i class="fa fa-4x fa-check-circle"></i>
      </div>
    </div>
    <div class="col-md-8">
      <h4 class="text-left">Splash Page</h4>
      <ul class="confUl">
        <li>
          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Background Image,</li>
        <li>
          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Venue Logo,</li>
        <li>
          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Social Media,</li>
        <li>
          <span class="confSuccess"><i class="fa fa-check-circle-o"></i></span> Redirection Url</li>
      </ul>
    </div>
    <div class="col-md-2">
      <button class="btn btn-button btn-block btn-select" ui-sref="admin.duplicateevent.duplicatesplash">edit</button>
    </div>
  </div>
  <div ng-hide="duplicateevent.splashPassed">
    <div class="col-md-2">
      <div class="confFail">
        <i class="fa fa-4x fa-times-circle"></i>
      </div>
    </div>
    <div class="col-md-8">
      <h4 class="text-left">Splash Page</h4>
      <p>Splash Page Incomplete</p>
    </div>
    <div class="col-md-2">
      <button class="btn btn-button btn-block btn-select" ui-sref="admin.duplicateevent.duplicatesplash">edit</button>
    </div>
  </div>
</div>
</div>
<div class="panel-footer">
  <div class="pull-right">
    <button class="btn btn-default" ng-click="duplicateevent.cancel()">Cancel</button>
    <button class="btn btn-default btn-danger" ng-click="duplicateevent.updateEvent()" ng-disabled="!duplicateevent.splashPassed || !duplicateevent.netPassed ||  !duplicateevent.setupPassed">Create Event</button>
  </div>
  <div class="clearfix"></div>
</div>
