(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cloudservices.controller:CloudservicesCtrl
   *
   * @description
   *
   */
  angular
    .module('cloudservices')
    .controller('CloudservicesCtrl', CloudservicesCtrl);

  function CloudservicesCtrl($state, Cloudservices, $modal, $stateParams) {
    var vm = this;
    vm.ctrlName = 'CloudservicesCtrl';

    vm.cloudservicesLoaded = false;
    vm.cloudservicesLoadedError = false;
    vm.cloudservices = [];
    vm.ErrorMessage = false;
    vm.Message = [];
    vm.venueid = $stateParams.venueid;

    Cloudservices.getCloudServices($stateParams.venueid).success(function (data) {
      vm.cloudservices = data.cloudServices;
      vm.cloudservicesLoaded = true;
    }).error(function (err) {
      vm.cloudservicesLoadedError = true;
      vm.cloudservicesLoaded = true;
      console.log('Error: ', err);
    });

    function reloadAll() {
      Cloudservices.getCloudServices($stateParams.venueid).success(function (data) {
        vm.cloudservices = data.cloudServices;
        vm.cloudservicesLoaded = true;
      }).error(function (err) {
        vm.cloudservicesLoadedError = true;
        vm.cloudservicesLoaded = true;
        console.log('Error: ', err);
      });
    }

    vm.deleteEvent = function (cloudservicesid) {
      var modalInstance = $modal.open({
        templateUrl: 'cloudservices/views/clservicedeletemodal.tpl.html',
        controller: 'ClservicedeletemodalCtrl as clservicedeleteModal'
      });

      modalInstance.result.then(function () {

        Cloudservices.deleteCloudService(vm.venueid, cloudservicesid).success(function () {
          reloadAll(vm.venueid, cloudservicesid);
        }).error(function (err) {
          console.log('Delete Error: ', err);
          if (err.message.code === '23503') {
            console.log('Error Code: ', err.message.code);
            vm.ErrorMessage = true;
            vm.Message = 'Error Deleting Cloud Service!';
          } else {
            vm.ErrorMessage = true;
            vm.Message = err.message;
          }
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };
    
  }
}());
