(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:EventsCtrl
   *
   * @description
   *
   */
  angular.module('events').controller('EventsCtrl', EventsCtrl);

  function EventsCtrl($state, Api, $log, $modal, $rootScope, Cloudservices, Twilio) {
    var vm = this;
    vm.events = [];
    vm.venues = [];

    vm.showArchieved = false;
    vm.showCurrent = true;

    vm.maxDate = new Date(253402300740905).toISOString();

    vm.setEventView = function () {
      if (vm.showCurrent) {
        vm.showArchieved = true;
        vm.showCurrent = false;
      } else {
        vm.showArchieved = false;
        vm.showCurrent = true;
      }
      console.log(vm.showCurrent, vm.showArchieved);
    };

    vm.limits = [1, 5, 10, 15, 20, 50, 'all'];
    vm.all = 0;
    vm.limit = 0;

    vm.setLimit = function (limit) {
      console.log(limit);
      if (limit === 'all') {
        vm.limit = vm.all;
      }
    };

    Api.getVenues().success(function (data) {
      vm.venues = data.venues;
      $log.info(vm.venues);
    }).error(function () {
      $log.info('get venues error');
    });

    function getEvents() {
      vm.events = [];
      if ($rootScope.activeUser.role === 2) {
        Api.getAllEventsByVenue(parseInt($rootScope.activeUser.details.venue)).then(function (res) {
          vm.events = res.data.events;

          vm.limit = vm.events.length;
          vm.all = vm.events.length;
          for (var i = 0; i < vm.venues.length; i++) {
            for (var j = 0; j < vm.events.length; j++) {
              if (vm.events[j].venue === vm.venues[i].id) {
                vm.events[j].venueName = vm.venues[i].venuetitle;
              }
            }
          }
        }, function (err) {
          console.log(err);

        });
      } else {
        Api.getAllEvents().success(function (data) {
          vm.events = data.events;

          vm.limit = vm.events.length;
          vm.all = vm.events.length;
          for (var i = 0; i < vm.venues.length; i++) {
            for (var j = 0; j < vm.events.length; j++) {
              if (vm.events[j].venue === vm.venues[i].id) {
                vm.events[j].venueName = vm.venues[i].venuetitle;
              }
            }
          }
          $log.info(data.events);

        }).error(function () {
          $log.info('get events error');
        });
      }
    }

    vm.showErrors = function (details) {
      var newDetails = details;
      var modalMessage = {};
      if (!newDetails.unifi) {
        modalMessage.xirrus = true;
        modalMessage.messageHeader = 'Unreachable Access Points';
        modalMessage.messageBody = 'The following access points were unreachable';
        modalMessage.accesspoints = details.unreachable_ap;
      } else {
        modalMessage.messageHeader = 'Event Error';
        modalMessage.messageBody = 'Configuration error, check venue network configuration';
      }

      var modalInstance = $modal.open({
        templateUrl: 'events/views/errormodal.tpl.html',
        controller: 'ErrormodalCtrl as modalService',
        resolve: {
          message: function () {
            return modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {});
    };

    vm.deleteEvent = function (eventid, venueid) {
      var modalInstance = $modal.open({
        templateUrl: 'events/views/deletemodal.tpl.html',
        controller: 'DeletemodalCtrl as deleteModal'
      });

      modalInstance.result.then(function () {
        Api.deleteEvent(eventid, venueid).success(function (data) {
          $log.info(data);
          getEvents();
        });
      }, function (err) {
        console.log(err);
      });
    };

    vm.sendMessage = function (venue, event) {
      var twilio = {};
      var provider = 0;
      var config = {
        test: 'test'
      };
      Cloudservices.getCloudServiceByName(venue, 'twilio').then(function (resp) {
        twilio = resp.data.cloudservices[0];
        console.log(twilio);
      }, function (err) {
        console.log(err);
      });
      Api.getInfrastructures(venue).then(function (resp) {
        provider = resp.data.infrastructures[0].provider;
      }, function (err) {
        console.log(err);
      });
      var modalInstance = $modal.open({
        templateUrl: 'twilio-modal/views/twilio-modal.tpl.html',
        controller: 'TwilioModalCtrl as TwilioModalCtrl',
        resolve: {
          config: function () {
            return config;
          }
        }
      });

      modalInstance.result.then(function (message) {
        var callObj = {
          venueid: venue,
          providerid: provider,
          eventid: event,
          accountSid: twilio.accountsid,
          authToken: twilio.apikey,
          fromNum: twilio.phonenumber,
          message: message
        };
        Twilio.sendSMS(callObj).then(function (res) {
          console.log(res);
        }, function (err) {
          console.log(err);
        });
      });
    };

    getEvents();
  }
}());
