(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name leoperations.directive:backImg
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="leoperations">
       <file name="index.html">
        <back-img></back-img>
       </file>
     </example>
   *
   */
  angular
    .module('leoperations')
    .directive('backImg', backImg);

  function backImg() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'directives/back-img-directive.tpl.html',
      replace: false,
      controllerAs: 'backImg',
      controller: function () {
        // var vm = this;
        // vm.name = 'backImg';
      },
      link: function (scope, element, attrs) {
        var url = attrs.backImg;
        element.css({
            'background-image': 'url(' + url +')',
            'background-size' : 'cover'
        });
      }
    };
  }
}());
