(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addsurvey.controller:AddsurveyCtrl
   *
   * @description
   *
   */
  angular
    .module('addsurvey')
    .controller('AddsurveyCtrl', AddsurveyCtrl);

  function AddsurveyCtrl($state, $stateParams, Api) {
    var vm = this;
    vm.ctrlName = 'AddsurveyCtrl';
    vm.selectedSurvey = false;
    vm.numberOfQuestionsTotal = 5;
    vm.numberOfQuestions = 0;
    vm.surveyObjects = [];
    vm.surveyId = 0;

    //setSurvey
    function addEmptySurveyObjects(){
      for (var i=0; i<vm.numberOfQuestionsTotal; i++){
        var surveyObj = { 'id': i, 'show': false};
        vm.surveyObjects.push(surveyObj);
      }
    }

    /*
    {"survey":[{"question":"Do you like apples?","answers":[{"answer":"yes","count":0},{"answer":"no","count":0}]},{"question":"Do you like pears?","answers":[{"answer":"yes","count":0},{"answer":"no","count":4}]},{"question":"Do you like kiwi?","answers":[{"answer":"yes","count":0},{"answer":"no","count":4}]}]}
    */

    addEmptySurveyObjects();

    vm.addQuestion = function () {
      if(vm.numberOfQuestions < vm.numberOfQuestionsTotal) {
        vm.surveyObjects[vm.numberOfQuestions].show = true;
        vm.numberOfQuestions = vm.numberOfQuestions + 1;
      }
    };

    vm.removeQuestion = function () {
      if(vm.numberOfQuestions > 0) {
        vm.numberOfQuestions = vm.numberOfQuestions - 1;
        vm.surveyObjects[vm.numberOfQuestions].show = false;
      }
    };

    vm.setSurveyObject = function () {
      var details = [];
      //add survey
      for (var i=0; i < vm.numberOfQuestions; i++) {
        if (vm.surveyObjects[i].survey !==null && vm.surveyObjects[i].survey && vm.surveyObjects[i].survey.question !== null && vm.surveyObjects[i].survey.question){
          var answers = [];
          if (vm.surveyObjects[i].survey.answer1 !== null && vm.surveyObjects[i].survey.answer1){
            answers.push({'answer': vm.surveyObjects[i].survey.answer1, 'count':0});
          }
          if (vm.surveyObjects[i].survey.answer2 !== null && vm.surveyObjects[i].survey.answer2){
            answers.push({'answer': vm.surveyObjects[i].survey.answer2, 'count':0});
          }
          if (vm.surveyObjects[i].survey.answer3 !== null && vm.surveyObjects[i].survey.answer3){
            answers.push({'answer': vm.surveyObjects[i].survey.answer3, 'count':0});
          }
          if (vm.surveyObjects[i].survey.answer4 !== null && vm.surveyObjects[i].survey.answer4){
            answers.push({'answer': vm.surveyObjects[i].survey.answer4, 'count':0});
          }
          var surveyObj = {
            'question': vm.surveyObjects[i].survey.question,
            'answers': answers
          };
          details.push(surveyObj);
        }
      }
      //var eventName = vm.newEvent.name;
      var surveyObj = { survey: details};
      var survey = {name: "name", isActive: true, details: surveyObj};
      return survey;
    };

    vm.createSurvey = function () {
      var surveyObj = vm.setSurveyObject();
      Api.createSurvey(surveyObj, $stateParams.eventid).success(function () {
        $state.go('admin.events');
      }).error(function (error) {
        $log.info(error);
      });
    };
  }
}());
