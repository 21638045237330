<div id="editzone">
  <h2 class="text-center">Edit Zone</h2>
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
    </div>
    <div class="panel-body">
      <form name="createzone" class="col-md-8 col-md-offset-2" novalidate>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>ZONE NAME</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="zonename" name="zonename" ng-model="editzone.zone.name" placeholder="eg. Some zone" required>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>DESCRIPTION</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="details" name="details" ng-model="editzone.zone.details.description" placeholder="eg. zone covers conference centre main suite" required>
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-show="editzone.provider == 2">
          <div class="panel-heading clearfix">
            <h4>WLAN GROUP</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="details" name="details" ng-model="editzone.zone.details.wlangroup" placeholder="eg. zone covers conference centre main suite">
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form" ng-if="editzone.provider == 6">
          <div class="panel-heading clearfix">
            <h4>ZONE UUID</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <input type="text" class="form-control" id="details" name="details" ng-model="editzone.zone.details.siteid" placeholder="eg. zone Id from ruckus controller">
            </div>
          </div>
        </div>
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>ACCESSPOINTS</h4>
          </div>
          <div class="panel-body">
            <ul id="double">
              <li class="iplisting" dir-paginate="ap in editzone.accesspoints | orderBy: 'name'| filter:apSearch | itemsPerPage: 2500">
                <label>
                  <input type="checkbox" checklist-model="editzone.selectedaccesspoints" checklist-value="ap"> {{ap.name}}
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="pull-right">
          <button class="btn btn-default" ng-click="editzone.cancel()">Cancel</button>
          <button ng-click="editzone.updateZone()" class="btn btn-default btn-danger">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>
