(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name operators.controller:OperatorsCtrl
   *
   * @description
   *
   */
  angular
    .module('operators')
    .controller('OperatorsCtrl', OperatorsCtrl);

  function OperatorsCtrl(Api, $log, Auth, $modal, $rootScope, $state) {

    if($rootScope.activeUser.role === 2){
      $state.go('admin.events');
    }

    var vm = this;
    vm.ctrlName = 'OperatorsCtrl';
    vm.operators = [];

    vm.limits = [1,5,10,15,20,50,'all'];
    vm.all= 0;
    vm.limit = 0;

    vm.setLimit = function(limit){
      console.log(limit);
      if(limit === 'all'){
        vm.limit = vm.all;
      }
    };

    if (Auth.getAuthData()) {
      vm.thisOperator = Auth.getAuthData().operator;
    }

    function getOperators() {
      Api.getOperators().success(function (data) {
        vm.operators = data.operators;
        vm.limit = vm.operators.length;
        vm.all = vm.operators.length;
        $log.info(vm.operators);
      }).error(function (err) {
        $log.info(err);
      });
    }

    vm.deleteOperator = function (operator) {
      if (operator.login === vm.thisOperator.login) {
        var modalInstance = $modal.open({
          templateUrl: 'operators/views/modal.tpl.html',
          controller: 'ModalCtrl as modal',
          resolve: {
            message: {
              message: 'Cannot delete logged in Operator'
            }
          }
        });

        modalInstance.result.then(function () {
          $log.info('ok');
        }, function () {
          $log.info('canceled');
        });

      } else {
        var thisModalInstance = $modal.open({
          templateUrl: 'operators/views/modal.tpl.html',
          controller: 'ModalCtrl as modal',
          resolve: {
            message: {
              message: 'Are you sure you want to delete operator: ' + operator.name
            }
          }
        });

        thisModalInstance.result.then(function () {
          Api.deleteOperator(operator.id).success(function () {
            $log.info('operator ' + operator.name + ' deleted');
            getOperators();
          }).error(function (err) {
            $log.info(err);
          });
        }, function () {
          $log.info('canceled');
        });
      }
    };

    vm.resendEmail = function(user) {
      var thisModalInstance = $modal.open({
        templateUrl: 'operators/views/emailRetryModal.tpl.html',
        controller: 'EmailRetryModalCtrl as emailModal',
        resolve: {
          user: user
        }
      });

      thisModalInstance.result.then(function (result) {
        $log.info('successclose');
        Api.resendEmail(user).success(function () {
          var modalMessage = {
            ok: 'Ok',
            messageHeader: 'Email Sent to Operator With Login Details.',
          };
          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return modalMessage;
              }
            }
          });
          modalInstance.result.then(function () {
              getOperators();
          });
        }).error(function (err) {
          $log.info(err);
        });
        // update with resend
      }, function () {
        var modalMessage = {
          ok: 'Ok',
          messageHeader: 'Failed To Re-Send Email',
        };
        var modalInstance = $modal.open({
          templateUrl: 'modalservice/views/modalservice.tpl.html',
          controller: 'ModalserviceCtrl as modalService',
          resolve: {
            message: function () {
              return modalMessage;
            }
          }
        });
        modalInstance.result.then(function () {
            getOperators();
        });
      });
    };


    // vm.updatePassword = function(id) {
    //   var modalInstance = $modal.open({
    //     templateUrl: 'operators/views/passwordmodal.tpl.html',
    //     controller: 'PasswordmodalCtrl as modal',
    //     resolve: {
    //       message: {
    //         message: 'Update Operator Password'
    //       }
    //     }
    //   });
    //   modalInstance.result.then(function (password) {
    //     var data = {
    //       password: password
    //     };
    //     Api.updateOperatorPassword(id, data).success(function (result) {
    //       $log.info(result);
    //       getOperators();
    //     }).error(function (err) {
    //       $log.info(err);
    //     });
    //   }, function () {
    //     $log.info('canceled');
    //   });
    // };

    getOperators();
  }
}());
