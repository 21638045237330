(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editevent.controller:CancelediteventCtrl
   *
   * @description
   *
   */
  angular
    .module('editevent')
    .controller('CancelediteventCtrl', CancelediteventCtrl);

  function CancelediteventCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'CancelediteventCtrl';
    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
