(function () {
  'use strict';

  angular
    .module('newoperator')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.newoperator', {
        url: '/newoperator',
        templateUrl: 'newoperator/views/newoperator.tpl.html',
        controller: 'NewoperatorCtrl',
        controllerAs: 'newoperator'
      });
  }
}());
