(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name networktype.controller:NetworktypeCtrl
   *
   * @description
   *
   */
  angular
    .module('networktype')
    .controller('NetworktypeCtrl', NetworktypeCtrl);

  function NetworktypeCtrl($stateParams, Api, $log, $state) {
    var vm = this;

    var venueid = $stateParams.venueid;

    vm.networkType = {};
    vm.selectedStrategies = [];
    vm.submitted = false;
    vm.provider = '';

    vm.setProvider = function(){
      vm.provider = $stateParams.provider;
      if(vm.provider !== '1'){
        vm.networkType.vlanId = 700;
      } else {
        vm.networkType.vlanId = '';
      }
    };

    vm.createNetworkType = function (valid) {
      vm.networkType.networkStrategies = vm.selectedStrategies;
      //$log.info(vm.networkType);
      vm.submitted = true;
      if (valid) {
        Api.createNetworkType(venueid, vm.networkType).then(function (success) {
          $log.info(success);
          $state.go('admin.managenetwork', {
            venueid: venueid
          });
        }, function (err) {
          $log.info(err);
        });
      }
    };
    vm.setProvider();
  
    Api.getCategories(vm.provider).then(function (data) {
      vm.ntstrategies = data.data.categories;
      $log.info(vm.ntstrategies);
    }, function (err) {
      $log.info('Error: ', err);
    });
  }
}());
