(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name Oprrator Analytics Factory
   *
   * @description  For communication with the liquidedge analytics api
   *
   */
  angular
    .module('leoperations')
    .factory('AnalyticsApi', Analytics);

  function Analytics(analyticsbase, $http, $base64, Auth, $cookies) {
    var AnalyticsBase = {};
    AnalyticsBase.someValue = 'Analytics';
    var endpoint = '/analytics-web/v1';
    var getHeader = function() {
      var token = Auth.getAuthData().token;
      var unifitoken = $cookies.get('unifitoken');
      var header = {
        'x-access-token': token
      };
      if (unifitoken) {
        header.unifitoken = unifitoken;
      }
      return header;
    };

    // Live Anayltics
    AnalyticsBase.getClientsOnlineByVenue = function (venueid, provider) {
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + endpoint + '/liveanalytic/venue/' + venueid + '/provider/' + provider + '?org=' + orgid, {
        headers: getHeader()
      });
    };
    AnalyticsBase.getClientsOnlineByEvent = function (venueid, provider, eventid) {
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + endpoint + '/liveanalytic/venue/' + venueid + '/provider/' + provider + '/event/' + eventid + '?org=' + orgid, {
        headers: getHeader()
      });
    };

    // Dwell Time
    AnalyticsBase.getDwellTimeByVenue = function (venueid, start, end) {
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + endpoint + '/dwelltime/venue/' + venueid + '?org=' + orgid + '&start=' + start + '&end=' + end, {
        headers: getHeader()
      });
    };
    AnalyticsBase.getDwellTimeByEvent = function (venueid, eventid, start, end) {
      var orgid = Auth.getAuthData().operator.organisation;
      return $http.get(analyticsbase + endpoint + '/dwelltime/venue/' + venueid + '/event/' + eventid + '?org=' + orgid + '&start=' + start + '&end=' + end, {
        headers: getHeader()
      });
    };

    //User Journey
    AnalyticsBase.getUserJourney = function (venueid, user, date) {
      return $http.get(analyticsbase + endpoint + '/userjourney/venue/' + venueid + '/user/' + user + '?date=' + date, {
        headers: getHeader()
      });
    };

    return AnalyticsBase;
  }
}());
