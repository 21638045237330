<div class="panel panel-default">
  <div class="panel-heading">
    <div class="row row-align">
      <h3>Images
        <button class="btn btn-md btn-danger pull-right mgn10r" ng-click="manageimages.uploadImage()" tooltip-placement="left" tooltip="Add Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
      </h3>
    </div>
  </div>
  <div class="panel-body">
    <div ng-show="manageimages.role === 1" class="row tabrow">
      <div class="col-md-9 col-md-offset-3">
        <div class="col-md-12">
          <label for="provider" class="control-label pull-left">Venue</label>
          <select class="form-control" ng-model="manageimages.venueId" ng-options="venue.id as venue.venuetitle for venue in manageimages.venues" ng-change="manageimages.getImagesByVenue(manageimages.venueId)">
          </select>
        </div>
      </div>
    </div>
    <div class="row tabrow">
      <div class="col-md-9 col-md-offset-3">
        <div class="col-md-6">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-search"></i>
            </span>
            <input type="text" class="form-control" placeholder="Search Images" ng-model="imageSearch">
          </div>
        </div>
        <div class="col-md-6">
          <select class="form-control" ng-model="manageimages.limit" ng-options="limit for limit in manageimages.limits" ng-change="manageimages.setLimit(manageimages.limit)">
            <option value="">Limit Results</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row fix">
      <div class="col-md-12">
        <div class="col-md-3 thumb" ng-repeat="image in manageimages.imageList | filter:imageSearch | limitTo:manageimages.limit">
          <img class="img-responsive" ng-src="{{staticUrl}}{{image.imageuri}}" />
          <div class="row">
            <button class="btn btn-danger btn-md pull-right" ng-click="manageimages.deleteImage(image)" tooltip-placement="left" tooltip="Delete Image">
              <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
