(function () {
  'use strict';

  angular
    .module('editsurvey')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editsurvey', {
        url: '/editsurvey/:surveyid/event/:eventid',
        templateUrl: 'editsurvey/views/editsurvey.tpl.html',
        controller: 'EditsurveyCtrl',
        controllerAs: 'editsurvey'
      });
  }
}());
