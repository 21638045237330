<div class="modal-header">
  <h4>{{bulkApCreateModal.controller.controlleraddress}}</h4>
</div>
<div class="modal-body">
  <div ng-show="bulkApCreateModal.error" class="center-error">
    <h5>{{bulkVenueCreateModal.error}}</h5>
  </div>
  <div ng-if="bulkApCreateModal.aps.length < 1 && !bulkApCreateModal.error" class="centered">
    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    <span class="sr-only">Loading...</span>
  </div>
  <div ng-show="bulkApCreateModal.aps.length > 0">
    <div class="scrollbox">
      <table class="table table-responsive eventsTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Mac</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody ng-repeat="ap in bulkApCreateModal.aps">
          <tr>
            <td>
              {{ap.name}}
            </td>
            <td>
              {{ap.mac}}
            </td>
            <td>
              <button class="btn btn-danger" ng-show="ap.status === 'Select'" ng-click="bulkApCreateModal.selectedSite(ap)">{{ap.status}}</button>
              <button class="btn btn-success" ng-show="ap.status === 'Selected'" ng-click="bulkApCreateModal.deselectSite(ap)">Select <i class="fa fa-check" aria-hidden="true"></i></button>
              <button class="btn btn-success" ng-show="ap.status === 'Created'" disabled>{{ap.status}}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr width="100%">
    <button class="btn btn-danger pull-right" ng-click="bulkApCreateModal.selectAll()">
      {{bulkApCreateModal.selectAllButton}}
    </button>
    </br>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button" ng-click="bulkApCreateModal.bulkCreate()" ng-disabled="bulkApCreateModal.selected.length < 1">Create Selected</button>
  <button class="btn btn-warning" type="button" ng-click="bulkApCreateModal.cancel()">Cancel</button>
</div>
