(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.controller:ErrormodalCtrl
   *
   * @description
   *
   */
  angular
    .module('events')
    .controller('ErrormodalCtrl', ErrormodalCtrl);

  function ErrormodalCtrl($modalInstance, message) {
    var vm = this;
    vm.message = message;

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
