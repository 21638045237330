<div class="modal-header">
  <img class="modal-img" src="./images/login/modal_image.png">
</div>
<div class="modal-body">
  <div ng-show="networkTypeModal.error" class="center-error">
    <h5>{{bulkVenueCreateModal.error}}</h5>
  </div>
  <div ng-hide="createFromController.error.length > 1">
    <h4>Would you like to create from controller?</h4>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button" ng-click="networkTypeModal.ok()">Yes</button>
  <button class="btn btn-info" type="button" ng-click="networkTypeModal.no()" ng-hide="networkTypeModal.error.length > 1">No</button>
  <button class="btn btn-warning" type="button" ng-click="networkTypeModal.cancel()">Cancel</button>
</div>
