(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name admin.controller:AdminCtrl
   *
   * @description
   *
   */
  angular
    .module('admin')
    .controller('AdminCtrl', AdminCtrl);

  function AdminCtrl($rootScope, Auth, $cookies, $state, $localStorage, $location) {
    var vm = this;
    vm.ctrlName = 'AdminCtrl';

    $rootScope.bodyClass = 'admin';

    $rootScope.activeUser = '';

    vm.profileInfo = {};

    if (Auth.getAuthData()) {
      vm.profileInfo = Auth.getAuthData().operator;
      $rootScope.activeUser = Auth.getAuthData().operator;
    }
    vm.storage = $localStorage;

    if (angular.isUndefined(vm.storage.alerts)) {
      vm.storage.alerts = [];
    }

    vm.logout = function () {
      $cookies.remove('leData');
      Auth.setIslogged(false);
      $state.go('main.home');
    };

    vm.dismissAlert = function (alert) {
      var index = vm.storage.alerts.indexOf(alert);
      if (index > -1) {
        vm.storage.alerts.splice(index, 1);
      }
    };

    vm.getAlerts = function () {
      if (vm.storage.alerts.length >= 1) {
        return true;
      }
      return false;
    };
    vm.items = [
      'The first choice!',
      'And another choice for you.',
      'but wait! A third!'
    ];

    vm.status = {
      isopen: false
    };

    vm.toggled = function (open) {
      console.log('Dropdown is now: ', open);
    };

    vm.toggleDropdown = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      vm.status.isopen = !vm.status.isopen;
    };

    vm.support = function () {
      $state.go('admin.support');
    };
    
    vm.getClass = function (path) {
      return ($location.path().substr(0, path.length) === path) ? 'active' : '';
    };
  }
}());
