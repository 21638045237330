(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eventcreation.controller:SsidchecksCtrl
   *
   * @description
   *
   */
  angular
    .module('eventcreation')
    .controller('SsidchecksCtrl', SsidchecksCtrl);

  function SsidchecksCtrl($modalInstance, message) {
    var vm = this;
    vm.ctrlName = 'SsidchecksCtrl';
    vm.message = message;

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
