(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addap.controller:AddapCtrl
   *
   * @description
   *
   */
  angular
    .module('addap')
    .controller('AddapCtrl', AddapCtrl);

  function AddapCtrl(Api, $stateParams, $log, $state) {
    var vm = this;
    vm.accesspoint = {};
    vm.accesspoint.isAccessable = 'false';
    vm.ErrorMessage = false;
    vm.Message = [];
    vm.submitted = false;

    vm.dismiss = function () {
      vm.Message = {};
      vm.ErrorMessage = false;
    };

    vm.setProvider = function () {
      vm.accesspoint.provider = $stateParams.provider;
    };

    vm.createAccesspoint = function (isValid) {
      console.log('called');
      vm.submitted = true;
      if (isValid) {
        Api.addAccessPoint($stateParams.venueid, vm.accesspoint).success(function (data) {
          console.log(data);
          $state.go('admin.managenetwork', {
            'venueid': $stateParams.venueid
          });
        }).error(function (err) {
          vm.ErrorMessage = true;
          vm.Message = err.message;
          console.log(err);
        });
      }
    };
    vm.setProvider();
  }
}());
