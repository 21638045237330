(function () {
  'use strict';

  angular
    .module('addsurvey')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addsurvey', {
        url: '/addsurvey/event/:eventid',
        templateUrl: 'addsurvey/views/addsurvey.tpl.html',
        controller: 'AddsurveyCtrl',
        controllerAs: 'addsurvey'
      });
  }
}());
