<h2>{{editeventnew.event.eventName}}</h2>
<div class="panel panel-default">
  <div class="eventPadding">
    <div class="row">
      <div class="col-md-6">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h4>Event Summary
               <button class="btn btn-danger btn-md pull-right" ng-click="editeventnew.updateSummary()" tooltip-placement="left" tooltip="Edit Event Details">
              <span class="fa fa-pencil-square-o" aria-hidden="true"></span></button></h4>
          </div>
          <div class="panel-body">
            <p>Name: {{editeventnew.event.name}}</p>
            <p>Description: {{editeventnew.event.description}}
            <p>Start Date: {{editeventnew.event.starttime | date:'medium'}}</p>
            <p ng-hide="editeventnew.noend">End Date: {{editeventnew.event.endtime | date:'medium'}}</p>
            <p>Redirection URL: {{editeventnew.event.redirectionurl}}
          </div>
          <div class="panel-heading">
            <h4>Event Network <button class="btn btn-danger btn-md" ng-click="editeventnew.updateNetwork()" tooltip-placement="left" tooltip="Edit Event Details" ng-show="editeventnew.networkEditEnabled">
              <span class="fa fa-pencil-square-o" aria-hidden="true"></span></button></h4>
          </div>
          <div class="panel-body">
            <p>Network Name: {{editeventnew.event.ssidname}}</p>
            <p>Network Type: {{editeventnew.event.networktypename}}</p>
            <!-- <p>Bandwith Limit: {{editeventnew.event.trafficlimitsta / 1000}}MB</p>
            {{editeventnew.event.accesspoints}} -->
            <p ng-if="editeventnew.event.accesspoints[0].provider === 1">Bandwith Limit: {{editeventnew.event.trafficlimitsta}}</p>
          </div>
        </div>
        <button class="btn btn-danger btn-md pull-right" ng-click="editeventnew.endEvent()" tooltip-placement="left" tooltip="End Event">
       <span class="fa fa-stop" aria-hidden="true"></span></button>
      </div>
      <!-- new code -->
      <div class="col-xs-9 col-sm-6 col-md-5 col-md-offset-1">
        <!-- <h3 class="text-center">Splash Page</h3> -->
        <div class="col-md-10 col-md-offset-1">
          <div class="splashBackground" ng-style="{ 'background-image': 'url(' + staticUrl + editeventnew.bckgrndImg + ')' }">
            <div class="content">
              <button class="btn btn-danger btn-md pull-right" ng-click="editeventnew.updateSplash()" tooltip-placement="left" tooltip="Edit Event Details">
                <span class="fa fa-pencil-square-o" aria-hidden="true"></span></button>
              <div class="row venueLogo">
                <div class="col-xs-8 col-xs-offset-2" ng-show="editeventnew.venueLogoAdded">
                  <img ng-src="{{staticUrl}}{{editeventnew.venueLogo}}" class="img-responsive" />
                </div>
              </div>
              <div class="row login-mod well well-sm" ng-show="editeventnew.bckgrndImgAdded && editeventnew.spEmail">
                <div class="scaled">
                  <p class="railtext">
                    Connect to our FREE WIFI with
                  </p>
                  <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>
                    <div class="form-group" ng-class="{ 'has-error' : loginForm.email.$error && authentication.formSubmitted }">
                      <input type="email" name="email" class="form-control" placeholder="Email">
                    </div>
                    <div class="form-group railtext">
                      <input type="checkbox" ng-model="authentication.terms" required name="terms">
                      <a ng-click="">Terms and Conditions</a>
                    </div>
                    <div class="row text-center">
                      <div class="col-md-12 col-xs-12 col-sm-12">
                        <button type="submit" class="btn btn-danger">
                          CONNECT
                        </button>
                      </div>
                    </div>
                  </form>
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/liquidedge-logo-act.png" alt="Liquidedge">
                  </div>
                </div>
              </div>
              <div class="row login-mod well well-sm" ng-show="editeventnew.bckgrndImgAdded && editeventnew.spRegister">
                <div class="scaled">
                  <p class="railtext">
                    Connect to our FREE WIFI with
                  </p>
                  <form class="loginForm" name="loginForm" ng-submit="" role="form" novalidate>
                    <div class="form-group" ng-class="{ 'has-error' : loginForm.email.$error && authentication.formSubmitted }">
                      <input type="email" name="email" class="form-control" placeholder="Email">
                    </div>
                    <div class="form-group" ng-class="{ 'has-error' : loginForm.email.$error && authentication.formSubmitted }">
                      <input type="password" name="password" class="form-control" placeholder="Password">
                    </div>
                    <div class="form-group railtext">
                      <input type="checkbox" ng-model="authentication.terms" required name="terms">
                      <a ng-click="">Terms and Conditions</a>
                    </div>
                    <div class="row text-center">
                      <div class="col-md-12 col-xs-12 col-sm-12">
                        <button type="submit" class="btn btn-danger">
                          CONNECT
                        </button>
                      </div>
                    </div>
                  </form>
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/liquidedge-logo-act.png" alt="Liquidedge">
                  </div>
                </div>
              </div>
              <div class="row video-mod" ng-show="editeventnew.bckgrndImgAdded && editeventnew.spVideo">
                <div class="scaled">
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/youtube.jpg" alt="Youtube Video">
                  </div>
                </div>
              </div>
              <div class="footer-mod" ng-show="editeventnew.bckgrndImgAdded && editeventnew.spVideo">
                <div class="centered">
                  <strong class="railtext">
                    Connect to our FREE WIFI with
                  </strong>
                  <div class="btn-deck">
                    <button type="submit" class="btn btn-danger btn-xs" ng-click="">
                      More Info
                    </button>
                    <button type="submit" class="btn btn-info btn-xs" ng-click="">
                      No, Thanks
                    </button>
                  </div>
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/liquidedge-logo-act.png" alt="Liquidedge">
                  </div>
                </div>
              </div>
              <div class="footer-mod" ng-show="editeventnew.bckgrndImgAdded && editeventnew.spLink">
                <div class="centered">
                  <strong class="railtext">
                    Connect to our FREE WIFI with
                  </strong>
                  </br>
                  <button type="submit" class="btn btn-danger btn-deck btn-xs" ng-click="">
                    CONNECT
                  </button>
                  <div class="leLogo">
                    <img class="img-responsive" src="./images/liquidedge-logo-act.png" alt="Liquidedge">
                  </div>
                </div>
              </div>
              <div class="footer-mod" ng-show="editeventnew.bckgrndImgAdded && editeventnew.spSocial">
                <div class="socialCentred">
                  <div class="DocumentList">
                    <strong class="railtext">
                      Connect to our FREE WIFI with
                    </strong>
                    <div class="row">
                      <div class="socIconsList" ng-repeat="social in editeventnew.socialLogins" ng-show="editeventnew.openAuthList.indexOf(social.id) > -1">
                        <button class="btn btn-social" style="background-image: url({{adminStaticUrl}}{{social.imageuri}});background-size: 100% 100%;">
                          <div class="content">
                          </div>
                        </button>
                      </div>
                    </div>
                    <div class="row" ng-show="editeventnew.anonymousLogin.on">
                      <a>Continue Without Login</a> <br />
                      <br />
                    </div>
                    <div class="" style="margin-left: auto;margin-right: auto;  display: block;width: 10vw;height: auto;">
                      <img class="img-responsive" src="./images/liquidedge-logo-act.png" alt="Liquidedge">
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
