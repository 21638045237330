(function () {
  'use strict';

  angular
    .module('newvenueanalytics')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.newvenueanalytics', {
        url: '/newvenueanalytics/:venueid/:class',
        templateUrl: 'newvenueanalytics/views/newvenueanalytics.tpl.html',
        controller: 'NewvenueanalyticsCtrl',
        controllerAs: 'newvenueanalytics'
      });
  }
}());
