(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name modalservice.controller:BulkNetworkTypeCreateModalCtrl
   *
   * @description
   *
   */
  angular
    .module('modalservice')
    .controller('BulkNetworkTypeCreateModalCtrl', BulkNetworkTypeCreateModalCtrl);

  function BulkNetworkTypeCreateModalCtrl($modalInstance, Api, infrastructure, $cookies) {
    var vm = this;
    vm.ctrlName = 'BulkNetworkTypeCreateModalCtrl';
    vm.controller = infrastructure;
    vm.netTypes = [];
    vm.userGroups = [];
    vm.selected = [];
    vm.error = '';
    vm.selectAllButton = 'Select All';

    vm.getNetTypes= function () {
      Api.getNetworkTypes(vm.controller.venue).success(function(data){
        data.networkTypes.forEach(function(item){
          if(item.details.usergroup){
            vm.netTypes.push(item);
          }
        });
      }).error(function(error){
        vm.error = 'There was a problem Creating your Network Type. Please check you controller.';
      });
    };

    vm.getUserGroups = function () {
      Api.getUserGroups(vm.controller.provider, vm.controller).success(function(data){
        var siteIndex;
        var cookie = $cookies.get('unifitoken');
        if (cookie === undefined) {
          var expireDate =  new Date((new Date()).getTime() + 10*60000);
          $cookies.put('unifitoken', data.cookie, {'expires': expireDate});
        }
        for(siteIndex = 0; siteIndex < data.data.length; siteIndex++) {
          var usergroup = data.data[siteIndex];
          usergroup.status = 'Select';
          vm.netTypes.forEach(function(item){
            if (item.details.usergroup === usergroup._id) {
              usergroup.status = 'Created';
            }
          });
          vm.userGroups.push(usergroup);
        }
      }).error(function(error){
        vm.error = 'There was a problem Creating your Network Type. Please check you controller.';
      });
    };
    vm.getNetTypes();
    vm.getUserGroups();

    vm.selectedSite = function(userGroup) {
      vm.selected.push(userGroup);
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.userGroups.length; siteIndex++) {
        if (userGroup._id ===  vm.userGroups[siteIndex]._id) {
          vm.userGroups[siteIndex].status = 'Selected';
        }
      }
    };

    vm.deselectSite = function(userGroup) {
      var selectIndex;
      for(selectIndex = 0; selectIndex < vm.selected.length; selectIndex++) {
        if (userGroup.name ===  vm.selected[selectIndex].name) {
          vm.selected.splice(selectIndex, selectIndex+1);
        }
      }
      var siteIndex;
      for(siteIndex = 0; siteIndex < vm.userGroups.length; siteIndex++) {
        if (userGroup._id ===  vm.userGroups[siteIndex]._id) {
          vm.userGroups[siteIndex].status = 'Select';
        }
      }
    };

    vm.selectAll = function() {
      var siteIndex;
      if (vm.selectAllButton === 'Select All') {
        for(siteIndex = 0; siteIndex < vm.userGroups.length; siteIndex++) {
          if (vm.userGroups[siteIndex].status === 'Select') {
            vm.selected.push(vm.userGroups[siteIndex]);
            vm.userGroups[siteIndex].status = 'Selected';
          }
        }
        vm.selectAllButton = 'Deselect All';
      } else {
        for(siteIndex = 0; siteIndex < vm.userGroups.length; siteIndex++) {
          if (vm.userGroups[siteIndex].status === 'Selected') {
            vm.selected = [];
            vm.userGroups[siteIndex].status = 'Select';
          }
        }
        vm.selectAllButton = 'Select All';
      }
    };

    vm.bulkCreate = function() {
      var bulk = {
        venueId: vm.controller.venue,
        networkTypes: []
      };
        vm.selected.forEach(function(item){
          var networkType = {
            name: item.name,
            details: {usergroup:item._id},
            vlanId: 700,
            overallbandwidth:null
          };
          bulk.networkTypes.push(networkType);
        });
        Api.createBulkNetworkType(vm.controller.venue, bulk).success(function(data){
          data = {};
          $modalInstance.close();
        }).error(function(error){
          vm.error = 'There was a problem Creating your Network Type. Please check you controller.';
        });
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
