(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.constant:analyticsendpoint
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .constant('analyticsendpoint', '/analytics-web/v1');
}());
