(function () {
  'use strict';

  angular
    .module('editevent')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editevent', {
        url: '/editevent/:eventid/:venueid',
        templateUrl: 'editevent/views/editevent.tpl.html',
        controller: 'EditeventCtrl',
        controllerAs: 'editevent'
      }).state('admin.editevent.editsetup', {
        url: '/editsetup',
        templateUrl: 'editevent/views/editsetup.tpl.html'
      })
      .state('admin.editevent.editnetwork', {
        url: '/editnetwork',
        templateUrl: 'editevent/views/editnetwork.tpl.html'
      })
      .state('admin.editevent.editsplash', {
        url: '/editsplash',
        templateUrl: 'editevent/views/editsplash.tpl.html'
      })
      .state('admin.editevent.confirm', {
        url: '/editconfirm',
        templateUrl: 'editevent/views/editconfirm.tpl.html'
      });
  }
}());
