(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addInfrastructure.controller:AddInfrastructureCtrl
   *
   * @description
   *
   */
  angular.module('addInfrastructure').controller('AddInfrastructureCtrl', AddInfrastructureCtrl);

  function AddInfrastructureCtrl(Api, $stateParams, $log, $state) {
    var vm = this;

    vm.infrastructure = {};
    vm.submitted = false;
    vm.providerSelected = false;

    vm.providerTypes = [];

    vm.selectedProvider = '';

    vm.setProvider = function (provider) {
      vm.providerSelected = true;
      vm.selectedProvider = provider;
      if (provider === 1) {
        vm.infrastructure.controlleraddress = 'Xirrus';
      } else {
        vm.infrastructure.controlleraddress = '';
      }
    };
    Api.getVenue($stateParams.venueid).then(function (res) {
      vm.venue = res.data.venue;
    }, function (err) {
      console.log(err);
    });

    Api.getProviders().then(function (res) {
      console.log(res);
      vm.providerTypes = res.data.providers;
    }, function (err) {
      console.log(err);
    });

    vm.create = function (isValid) {
      var thisInfrastructure = vm.infrastructure;
      vm.submitted = true;
      var data = {
        infrastructure: vm.infrastructure,
        venues: []
      };
      data.venues.push({
        id: vm.infrastructure.authentication,
        details: '{}'
      });
      vm.venue.siteid = vm.infrastructure.authentication;
      vm.venue.venueTitle = vm.venue.venuetitle;
      vm.venue.imageId = vm.venue.imageid;

      if (isValid) {
        Api.setGuestSettings(vm.infrastructure.provider, data).then(function successCallback(response) {
          console.log(response.statusCode);
          Api.updateVenue($stateParams.venueid, vm.venue).then(function successCallback(response) {
            Api.addInfrastructure($stateParams.venueid, thisInfrastructure).then(function (res) {
              $log.info(res);
              $state.go('admin.managenetwork', {
                'venueid': $stateParams.venueid
              });
            }, function (err) {
              $log.console.error(err);
            });
          }, function errorCallback(response) {
            console.log(response);
            vm.error = response;
          });
        }, function errorCallback(response) {
          console.log(response);
          vm.error = response;
        });
      }
    };
  }
}());
