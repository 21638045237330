<div class="hidden-xs">
  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="row row-align">
        <h3>Venues
          <button class="btn btn-danger btn-md pull-right" ng-click="venues.newvenue()" tooltip-placement="left" tooltip="Add Venue">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </h3>
      </div>
    </div>
    <div class="panel-body">
      <div class="row tabrow">
        <div class="col-md-9 col-md-offset-3">
          <div class="col-md-6">
            <div class="input-group">
              <span class="input-group-addon">
                <i class="fa fa-search"></i>
              </span>
              <input type="text" class="form-control" placeholder="Search Venues" ng-model="venueSearch">
            </div>
          </div>
          <div class="col-md-6">
            <select class="form-control" ng-model="venues.limit" ng-options="limit for limit in venues.limits" ng-change="venues.setLimit(venues.limit)">
              <option value="">Limit Results</option>
            </select>
          </div>
        </div>
      </div>
      <table class="table table-responsive">
        <tbody>
          <tr ng-repeat="venue in venues.venues | filter:venueSearch | limitTo:venues.limit" ng-hide="venues.venueSelected.selected && venues.venueSelected.venue !== venue.id">
            <td class="evntIMG">
              <div>
                <img ng-src="{{adminStaticUrl}}{{venue.imageuri}}" onerror="this.src='./images/le-sq.png'" class="img-responsive" />
              </div>
            </td>
            <td>
              <h4>
                {{venue.venuetitle}}</h4>
              <h4>
                {{venue.location}}</h4>
            </td>
            <td>
              <h4>Mail:
                <a ng-href="mailto:{{venue.contactemail}}">{{venue.contactemail}}</a>
              </h4>
              <h4>Phone: {{venue.contactphone}}
              </h4>
            </td>
            <td>
              <div class="pull-right">
                <button class="btn btn-danger btn-md" ng-click="venues.deleteVenue(venue.id)" tooltip-placement="left" tooltip="Delete Venue">
                  <span class="fa fa-trash-o" aria-hidden="true"></span>
                </button>
                <button class="btn btn-danger btn-md" ng-click="venues.editvenue(venue.id)" tooltip-placement="left" tooltip="Edit Venue">
                  <span class="fa fa-pencil-square-o" aria-hidden="true"></span>
                </button>
                <!-- <button class="btn btn-danger btn-md" ng-click="venues.viewAnalytics(venue.id)" tooltip-placement="left" tooltip="View Analytics">
                  <i class="fa fa-bar-chart" aria-hidden="true"></i>
                </button> -->
                <button class="btn btn-danger btn-md" ng-click="venues.manageNetwork(venue.id)" tooltip-placement="left" tooltip="Manage Network">
                  <i class="fa fa-wifi" aria-hidden="true"></i>
                </button>
                <button class="btn btn-danger btn-md" ng-click="venues.externalServices(venue.id)" tooltip-placement="left" tooltip="Cloud Services">
                  <i class="fa fa-cloud" aria-hidden="true"></i>
                </button>
                <button class="btn btn-danger btn-md" ng-click="venues.viewNewAnalytics(venue.id)" tooltip-placement="left" tooltip="View Analytics">
                  <i class="fa fa-line-chart" aria-hidden="true"></i>
                </button>
                <button class="btn btn-danger btn-md" ng-click="venues.viewCustomerSeg(venue.id)" tooltip-placement="left" tooltip="View Customer Segmentation">
                  <i class="fa fa-user-plus"></i>
                </button>
                <a href="" data-ui-sref="admin.tandceditor({venueid: venue.id})" class="btn btn-danger btn-md"><i class="fa fa-file" tooltip-placement="left" tooltip="Terms &amp; Conditions"></i></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="hidden-sm hidden-md hidden-lg">
  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="row row-align">
        <h5>Venues</h5>
      </div>
    </div>
    <div class="panel-body">
      <div class="input-group">
        <span class="input-group-addon">
          <i class="fa fa-search"></i>
        </span>
        <input type="text" class="form-control" placeholder="Search Venues" ng-model="venueSearch">
      </div>
      <ul class="list-unstyled list-margin event-list-xs">
        <li ng-repeat="venue in venues.venues | filter:venueSearch">
          <div class="row">
            <div class="col-xs-8">
              <p>
                <strong>{{venue.venuetitle}}</strong>
              </p>
              <p>Phone: {{venue.contactphone}}</p>
            </div>
            <div class="col-xs-3 col-xs-offset-1 text-centre">
              <button class="btn btn-danger btn-md" ng-click="venues.viewNewAnalytics(venue.id)" tooltip-placement="left" tooltip="View Analytics">
                <i class="fa fa-line-chart" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>