(function () {
  'use strict';

  angular
    .module('addap')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.addap', {
        url: '/addap/:venueid/:provider',
        templateUrl: 'addap/views/addap.tpl.html',
        controller: 'AddapCtrl',
        controllerAs: 'addap'
      });
  }
}());
