<div class="panel panel-default">
  <div class="eventPadding">
    <div class="row">
      <!-- <div class="col-xs-11 col-sm-7 col-ms-7"> -->
        <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>Name &amp; Duration</h4>
          </div>
          <div class="panel-body">
            <div class="form-group" ng-class="{ 'has-error' : createEvent.eventname.$error && editsummary.stepOne}">
              <label for="eventname" class="control-label pull-left">EVENT NAME</label>
              <input type="text" class="form-control" id="eventname" name="eventname" ng-model="editsummary.params.name" placeholder="eg. Big Match Day" required>
            </div>
            <div ng-messages="createEvent.eventname.$error" ng-show="createEvent.eventname.$error && editsummary.stepOne">
              <p class="text-danger" ng-message="required">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>
                  EVENT NAME is required</strong>
              </p>
            </div>
            <div class="form-group" ng-class="{ 'has-error' : createEvent.eventdescription.$error && editsummary.stepOne}">
              <label for="eventdescription" class="control-label pull-left">EVENT DESCRIPTION</label>
              <input type="text" class="form-control" id="eventdescription" name="eventdescription" ng-model="editsummary.params.description" placeholder="eg. Big Match Day" required>
            </div>
            <div ng-messages="createEvent.eventdescription.$error" ng-show="createEvent.eventdescription.$error && editsummary.stepOne">
              <p class="text-danger" ng-message="required">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>
                  EVENT DESCRIPTION is required</strong>
              </p>
            </div>
            <div class="form-row">
              <div class="form-group text-box long cell cell-pad">
                <label for="startdate" class="control-label pull-left">START DATE</label>
                <input type="text" class="form-control" min-date="editsummary.minDate" name="startDate" datepicker-popup="{{editsummary.dateFormat}}" ng-model="editsummary.params.starttime" is-open="editsummary.status.openedSt" datepicker-options="editsummary.dateOptions" ng-required="true" close-text="Close"/>
              </div>
              <div class="short cell">
                <button type="button" class="btn btn-date" ng-click="editsummary.openCalSt()">
                  <i class="glyphicon glyphicon-calendar"></i>
                </button>
              </div>
              <fieldset ng-disabled="editsummary.noend">
                <div class="form-group text-box long cell cell-pad">
                  <label for="endtime" class="control-label pull-left">END DATE</label>
                  <input type="text" class="form-control" min-date="editsummary.minDate" datepicker-popup="{{editsummary.dateFormat}}" ng-model="editsummary.params.endtime" is-open="editsummary.status.openedEnd" datepicker-options="editsummary.dateOptions" ng-required="true" close-text="Close"/>
                </div>
              </fieldset>
              <div class="short cell">
                <button type="button" class="btn btn-date" ng-click="editsummary.openCalEnd()" ng-disabled="editsummary.noend">
                  <i class="glyphicon glyphicon-calendar"></i>
                </button>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group text-box long cell cell-pad">
                <label for="starttime" class="control-label pull-left timelable">START TIME</label>
                <timepicker ng-model="editsummary.params.starttime" hour-step="editsummary.hstep" minute-step="editsummary.mstep" show-meridian="ismeridian" show-spinners="true"></timepicker>
              </div>
              <div class="short cell">
                <span class="glyphicon glyphicon-time"></span>
              </div>
              <fieldset ng-disabled="editsummary.noend">
                <div class="form-group text-box long cell cell-pad">
                  <label for="endtime" class="control-label pull-left timelable">END TIME</label>
                  <timepicker ng-model="editsummary.params.endtime" hour-step="editsummary.hstep" minute-step="editsummary.mstep" show-meridian="ismeridian" show-spinners="true"></timepicker>
                </div>
              </fieldset>
              <div class="short cell" style="vertical-align: top; padding-top: 5px">
                <p><strong>No End Date</strong></p>
                <label class="noend">
                  <input type="checkbox" ng-model="editsummary.noend">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div>
              <p class="text-danger" ng-show="editsummary.dateError">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>Start Date/End Date Are Required Fields</strong>
              </p>
              <p class="text-danger" ng-show="editsummary.stTimeInPast">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>Start Date/Time cannot be in the past</strong>
              </p>
              <p class="text-danger" ng-show="editsummary.endTimeInPast">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>End Date/Time cannot be in the past</strong>
              </p>
              <p class="text-danger" ng-show="editsummary.endBeforeStart">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>End Date/Time cannot be prior to Start Date/Time</strong>
              </p>
            </div>
          </div>
          <div class="panel panel-default panel-form">
            <div class="panel-heading clearfix">
              <h4>Redirection Url</h4>
            </div>
            <div class="panel-body">
              <div class="form-group" ng-class="{ 'has-error' : editsummary.params.redirectionurl.$error}">
                <input type="text" class="form-control" id="redirectionURL" ng-model="editsummary.params.redirectionurl" name="redirection" placeholder="eg. http://mysite.com" required>
              </div>
              <div ng-messages="editsummary.params.redirectionurl.$error" ng-show="editsummary.params.redirectionurl.$error">
                <p class="text-danger" ng-message="required">
                  <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                  <strong>
                    Redirection Url is required</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="panel panel-default panel-form">
          <div class="panel-heading clearfix">
            <h4>EVENT IMAGE</h4>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <div class="row">
                <div ng-show="editevent.eventImageAdded" class="col-md-4 col-md-offset-4">
                  <img ng-src="{{staticUrl}}{{editevent.eventImage}}" class="img-responsive"/>
                </div>
              </div>
              <div class="row">
                <button class="btn btn-danger" ng-click="editevent.selectEventImage()" tooltip-placement="left" tooltip="Select Image"><i class="fa fa-plus" aria-hidden="true"></i></button>
                <button class="btn btn-danger" ng-click="editevent.uploadEventImage()" tooltip-placement="left" tooltip="Upload Image"><i class="fa fa-cloud-upload" aria-hidden="true"></i></button>
              </div>
            </div>
            <div ng-show="!editevent.eventImageAdded && editevent.stepOne">
              <p class="text-danger">
                <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
                <strong>
                  Event Image is required</strong>
              </p>
            </div>
          </div>
        </div> -->
      <!-- </div> -->
      <!-- <div class="col-xs-8 col-sm-5 col-ms-5">
        <h3>Make your WiFi work for you!</h3>
        <p>Set up fast, secure WiFi the way you want it. Limit access time, allow or deny access to any site you want and set user bandwidth to prevent users hogging the network</p>
       <a href="">Need Help?</a>
      </div> -->
    </div>
    </div>
    <div class="panel-footer">
    <div class="pull-right">
      <button class="btn btn-default" ng-click="editsummary.cancel()">Cancel</button>
      <button class="btn btn-default btn-danger" ng-click="editsummary.ok()">Submit</button>
    </div>
    <div class="clearfix"></div>
    </div>
  </div>
</div>
