(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name managenetwork.controller:ManagenetworkCtrl
   *
   * @description
   *
   */
  angular
    .module('managenetwork')
    .controller('ManagenetworkCtrl', ManagenetworkCtrl);

  function ManagenetworkCtrl(Auth, Api, ApiAnalytic, $log, $stateParams, $state, $modal, $cookies) {
    var vm = this;

    vm.zones = [];
    vm.accesspoints = [];
    vm.infrastructures = [];
    vm.networkTypes = [];
    vm.venue = {};

    var venueid = $stateParams.venueid;
    var orgid = Auth.getAuthData().operator.organisation;
    console.log('orgid', orgid);

    vm.showZones = false;
    vm.showAPoints = false;
    vm.showTypes = false;
    vm.showVenueMap = false;
    vm.showInfra = true;

    vm.setNetworkView = function (view) {
      switch (view) {
      case 'zone':
        vm.showZones = true;
        vm.showAPoints = false;
        vm.showTypes = false;
        vm.showVenueMap = false;
        vm.showInfra = false;
        break;
      case 'apoints':
        vm.showZones = false;
        vm.showAPoints = true;
        vm.showTypes = false;
        vm.showVenueMap = false;
        vm.showInfra = false;
        break;
      case 'types':
        vm.showZones = false;
        vm.showAPoints = false;
        vm.showTypes = true;
        vm.showVenueMap = false;
        vm.showInfra = false;
        break;
      case 'infra':
        vm.showZones = false;
        vm.showAPoints = false;
        vm.showTypes = false;
        vm.showVenueMap = false;
        vm.showInfra = true;
        break;
      case 'venuemap':
        vm.showZones = false;
        vm.showAPoints = false;
        vm.showTypes = false;
        vm.showInfra = false;
        vm.showVenueMap = true;
        break;
      }
    };
    if ($cookies.get('view')) {
      vm.setNetworkView($cookies.get('view'));
    }

    function getZones(venueid) {
      Api.getZones(venueid).then(function (res) {
        vm.zones = res.data.zones;
        $log.info(vm.zones);
      }, function (err) {
        $log.info(err);
      });
    }

    function getAccessPoints(venueid) {
      Api.getAccessPoints(venueid).then(function (res) {
        vm.accesspoints = res.data.apoints;
        $log.info(vm.accesspoints);
      }, function (err) {
        $log.info(err);
      });
    }

    function getVenue(venueid) {
      Api.getVenue(venueid).then(function (res) {
        vm.venue = res.data.venue;
        $log.info(vm.venue);
      }, function (err) {
        $log.info(err);
      });
    }

    function getNetworkTypes(venueid) {
      Api.getNetworkTypes(venueid).then(function (res) {
        vm.networkTypes = res.data.networkTypes;
        $log.info(vm.networkTypes);
      }, function (err) {
        $log.info(err);
      });
    }
    function getMapsByVenue(venueid) {
      ApiAnalytic.getMapsByVenue(venueid).then(function (res) {
        vm.maps = res.data;
        $log.info(vm.maps);
      }, function (err) {
        $log.info(err);
      });
    }

    function getInfrastructures(venueid) {
      Api.getInfrastructures(venueid).then(function (res) {
        var infra = {};
        for (var item in res.data.infrastructures) {
          infra = res.data.infrastructures[item];
          var provider = JSON.parse(res.data.infrastructures[item].provider);
          infra.provider = provider;
          vm.infrastructures.push(infra);
        }
        $log.info(vm.infrastructures);
      }, function (err) {
        $log.info(err);
      });
    }

    Api.getProviders().then(function(res){
      vm.providers = res.data.providers;
    }, function(err){
      console.log(err);
    });

    vm.matchProvider = function(input, id){
      var returning = false;
      vm.providers.forEach(function(item){
        if(item.id === id){
          if (item.name.toLowerCase() === input){
            returning = true;
          }
        }
      });
      return returning;
    };

    vm.deleteZone = function (id) {
      $cookies.put('view', 'zone');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Zone, are you sure?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        Api.deleteZone(id, venueid).success(function (data) {
          $log.info(data);
          getZones(venueid);
        }).error(function () {
          vm.modalMessage = {
            ok: 'OK',
            messageHeader: 'Cannot delete zones with accesspoints attached',
          };

          var modalInstance = $modal.open({
            templateUrl: 'modalservice/views/modalservice.tpl.html',
            controller: 'ModalserviceCtrl as modalService',
            resolve: {
              message: function () {
                return vm.modalMessage;
              }
            }
          });
          modalInstance.result.then(function () {});
        });
      });
    };

    vm.deleteAccesspoint = function (id) {
      $cookies.put('view', 'apoints');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Access Point?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        Api.deleteAccessPoint(id, venueid).success(function (data) {
          $log.info(data);
          getAccessPoints(venueid);
        }).error(function () {
          $log.info('get Zones error');
        });
      });
    };

    vm.createZone = function () {
      $cookies.put('view', 'zone');
      if (vm.matchProvider('unifi', vm.infrastructures[0].provider) || vm.matchProvider('ruckus', vm.infrastructures[0].provider)) {
        var newModalInstance = $modal.open({
          templateUrl: 'modalservice/views/createfromcontroller.tpl.html',
          controller: 'CreateFromControllerCtrl as createFromController',
          resolve: {
            infrastructure: function(){
              return vm.infrastructures[0];
            }
          }
        });

        newModalInstance.result.then(function(data) {
          if (data.choice) {
            var newModalInstance = $modal.open({
              templateUrl: 'modalservice/views/bulkzonecreatemodal.tpl.html',
              controller: 'BulkZoneCreateModalCtrl as bulkZoneCreateModal',
              resolve: {
                infrastructure: function(){
                  return data.infrastructure;
                }
              }
            });
            newModalInstance.result.then(function() {
              getZones(venueid);
            });
          } else {
            $state.go('admin.addzone', {
              venueid: venueid,
              provider: vm.infrastructures[0].provider
            });
          }
        });
      } else {
        $state.go('admin.addzone', {
          venueid: venueid,
          provider: vm.infrastructures[0].provider
        });
      }
    };

    vm.createAccesspoint = function () {
      $cookies.put('view', 'apoints');
      if (vm.matchProvider('unifi', vm.infrastructures[0].provider) || vm.matchProvider('ruckus', vm.infrastructures[0].provider) || vm.matchProvider('xirrus', vm.infrastructures[0].provider) || vm.matchProvider('meraki', vm.infrastructures[0].provider)) {
        var newModalInstance = $modal.open({
          templateUrl: 'modalservice/views/createfromcontroller.tpl.html',
          controller: 'CreateFromControllerCtrl as createFromController',
          resolve: {
            infrastructure: function(){
              return vm.infrastructures[0];
            }
          }
        });

        newModalInstance.result.then(function(data) {
          if (data.choice) {
            var newModalInstance;
            if (data.aps) {
              newModalInstance = $modal.open({
                templateUrl: 'modalservice/views/bulkapcreatemodal.tpl.html',
                controller: 'BulkApCreateModalCtrl as bulkApCreateModal',
                resolve: {
                  infrastructure: function(){
                    return data.infrastructure;
                  },
                  aps: function(){
                    return data.aps;
                  }
                }
              });
            } else {
              newModalInstance = $modal.open({
                templateUrl: 'modalservice/views/bulkapcreatemodal.tpl.html',
                controller: 'BulkApCreateModalCtrl as bulkApCreateModal',
                resolve: {
                  infrastructure: function(){
                    return data.infrastructure;
                  },
                  aps: function(){
                    return {};
                  }
                }
              });
            }
            newModalInstance.result.then(function() {
              getAccessPoints(venueid);
            });
          } else {
            $state.go('admin.addap', {
              venueid: venueid,
              provider: vm.infrastructures[0].provider
            });
          }
        });
      } else {
        $state.go('admin.addap', {
          venueid: venueid,
          provider: vm.infrastructures[0].provider
        });
      }
    };

    vm.createInfrastructure = function () {
      $cookies.put('view', 'infra');
      $state.go('admin.addInfrastructure', {
        venueid: venueid
      });
    };

    vm.editInfrastructure = function (infraid) {
      $cookies.put('view', 'infra');
      $state.go('admin.editinfrastructure', {
        infraid: infraid,
        venueid: venueid
      });
    };

    vm.editZone = function (zoneid) {
      $cookies.put('view', 'zone');
      $state.go('admin.editzone', {
        zoneid: zoneid,
        venueid: venueid,
        orgid: orgid,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.editAccesspoint = function (apid) {
      $cookies.put('view', 'apoints');
      $state.go('admin.editap', {
        apid: apid,
        venueid: venueid
      });
    };

    vm.createNetworkType = function () {
      $cookies.put('view', 'types');
      if (vm.matchProvider('unifi', vm.infrastructures[0].provider)) {
        var newModalInstance = $modal.open({
          templateUrl: 'modalservice/views/createfromcontroller.tpl.html',
          controller: 'CreateFromControllerCtrl as createFromController',
          resolve: {
            infrastructure: function(){
              return vm.infrastructures[0];
            }
          }
        });

        newModalInstance.result.then(function(data) {
          if (data.choice) {
            var newModalInstance = $modal.open({
              templateUrl: 'modalservice/views/bulknetworktypecreatemodal.tpl.html',
              controller: 'BulkNetworkTypeCreateModalCtrl as bulkNetworkTypeCreateModal',
              resolve: {
                infrastructure: function(){
                  return data.infrastructure;
                }
              }
            });
            newModalInstance.result.then(function() {
              getNetworkTypes(venueid);
            });
          } else {
            $state.go('admin.networktype', {
              venueid: venueid,
              provider: vm.infrastructures[0].provider
            });
          }
        });
      } else {
        $state.go('admin.networktype', {
          venueid: venueid,
          provider: vm.infrastructures[0].provider
        });
      }
    };

    vm.editNetworkType = function (id) {
      $cookies.put('view', 'types');
      $state.go('admin.editnetworktype', {
        venueid: venueid,
        typeid: id,
        provider: vm.infrastructures[0].provider
      });
    };

    vm.deleteNetworkType = function (id) {
      $cookies.put('view', 'types');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Network Type?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        Api.deleteNetworkType(id, venueid).then(function () {
          getNetworkTypes(venueid);
        }, function (err) {
          $log.info(err);
        });
      });
    };

    // map calls
    vm.createVenueMap = function () {
      $cookies.put('view', 'venuemap');
      $state.go('admin.addvenuemap', {
        venueid: venueid
      });
    };
    vm.editMap = function (id) {
      $cookies.put('view', 'venuemap');
      $state.go('admin.editvenuemap', {
        venueid: venueid,
        mapId: id,
      });
    };
    vm.deleteMap = function (id) {
      $cookies.put('view', 'venuemap');
      vm.modalMessage = {
        ok: 'Delete',
        cancel: 'Cancel',
        messageHeader: 'Delete Network Type?',
      };

      var modalInstance = $modal.open({
        templateUrl: 'modalservice/views/modalservice.tpl.html',
        controller: 'ModalserviceCtrl as modalService',
        resolve: {
          message: function () {
            return vm.modalMessage;
          }
        }
      });
      modalInstance.result.then(function () {
        ApiAnalytic.deleteMap(venueid, id).then(function () {
          getMapsByVenue(venueid);
        }, function (err) {
          $log.info(err);
        });
      });
    };

    getZones(venueid);
    getVenue(venueid);
    getAccessPoints(venueid);
    getNetworkTypes(venueid);
    getMapsByVenue(venueid);
    getInfrastructures(venueid);
  }
}());
