(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name venues.controller:VenuesCtrl
   *
   * @description
   *
   */
  angular
    .module('venues')
    .controller('VenuesCtrl', VenuesCtrl);

  function VenuesCtrl(Auth, Api, $log, $rootScope, $state, $modal) {

    if($rootScope.activeUser.role === 2){
      $state.go('admin.events');
    }
    var orgid = Auth.getAuthData().operator.organisation;
    var vm = this;
    vm.venues = [];
    vm.zones = [];
    vm.aps = [];
    vm.limits = [1, 5, 10, 15, 20, 50, 'all'];
    vm.all = 0;
    vm.limit = 0;

    vm.venueSelected = {
      selected: false,
      venue: 0,
      zone: ''
    };

    vm.newvenue = function() {
      var newModalInstance = $modal.open({
        templateUrl: 'venues/views/venuecreatemodal.tpl.html',
        controller: 'VenuecreatemodalCtrl as venueCreateModal'
      });

      newModalInstance.result.then(function(data) {
        if (data.choice) {
          var newModalInstance = $modal.open({
            templateUrl: 'modalservice/views/bulkVenueCreateModal.tpl.html',
            controller: 'BulkVenueCreateModalCtrl as bulkVenueCreateModal',
            resolve: {
              infrastructure: function(){
                return data.infrastructure;
              }
            }
          });
          newModalInstance.result.then(function() {
            var modalMessage = {
              ok: 'Ok',
              messageHeader: 'Your venue has been queued for creation, it will appear in the venue list on completion.',
            };

            var modalInstance = $modal.open({
              templateUrl: 'modalservice/views/modalservice.tpl.html',
              controller: 'ModalserviceCtrl as modalService',
              resolve: {
                message: function () {
                  return modalMessage;
                }
              }
            });
            modalInstance.result.then(function () {
              getVenues();
            });
          });
        } else {
          $state.go('admin.newvenue');
        }
      });

    };

    vm.getZones = function (id) {
      if (vm.venueSelected.venue === id) {
        vm.venueSelected.selected = false;
        vm.venueSelected.venue = 0;
        vm.venueSelected.zone = '';
        vm.zones =[];
        vm.aps = [];
      } else {
        vm.zones =[];
        vm.aps = [];
        Api.getZones(id).then(function (res) {
          vm.venueSelected.selected = true;
          vm.venueSelected.venue = id;
          vm.zones = res.data.zones;
          console.log(res);
        }, function(err){
          console.log(err);
        });
      }
    };

    vm.getAccessPoints = function (zone) {
      vm.aps = [];
      vm.venueSelected.zone = zone.name;
      Api.getApByZone(vm.venueSelected.venue, zone.id).then(function (res) {
        vm.aps = res.data.apoints;
      }, function(err){
        console.log(err);
      });
    };

    vm.setLimit = function (limit) {
      if (limit === 'all') {
        vm.limit = vm.all;
      }
    };

    vm.viewAnalytics = function(id) {
      $state.go('admin.venueanalytics', {
        venueid: id
      });
    };

    vm.viewNewAnalytics = function(id) {
      $state.go('admin.newvenueanalytics', {
        venueid: id
      });
    };

    vm.viewCustomerSeg = function(id) {
      $state.go('admin.tags', {
        venueid: id
      });
    };

    vm.manageNetwork = function(id) {
      $state.go('admin.managenetwork', {
        venueid: id,
        orgid: orgid
      });
    };

    vm.externalServices = function(id) {
      console.log('venueid: ' + id);
      $state.go('admin.cloudservices', {
        venueid: id
      });
    };

    vm.editvenue = function(id) {
      $state.go('admin.editvenue', {
        venueid: id
      });
    };

    vm.deleteVenue = function(id) {

      var newModalInstance = $modal.open({
        templateUrl: 'venues/views/deletevenuemodal.tpl.html',
        controller: 'DeletevenuemodalCtrl as deleteVenueModal'
      });

      newModalInstance.result.then(function() {
        Api.deleteVenue(id).success(function() {
          getVenues();
        }).error(function(err) {
          var failedModalInstance = $modal.open({
            templateUrl: 'venues/views/deletefailedmodal.tpl.html',
            controller: 'DeletevenuemodalCtrl as deleteVenueModal'
          });

          failedModalInstance.result.then(function() {
            $log.error(err);
          });
        });
      });
    };

    function getVenues() {
      Api.getVenues().success(function (data) {
        vm.venues = data.venues;
        vm.limit = vm.venues.length;
        vm.all = vm.venues.length;
        $log.info(vm.venues);
      }).error(function () {
        $log.info('get venues error');
      });
    }

    getVenues();
  }
}());
