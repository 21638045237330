(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name newlist.controller:NewlistCtrl
   *
   * @description
   *
   */
  angular
    .module('newlist')
    .controller('NewlistCtrl', NewlistCtrl);

  function NewlistCtrl(Api, $state, $stateParams) {
    var vm = this;
    vm.ctrlName = 'NewlistCtrl';
    vm.list = {};
    vm.submitted = false;
    vm.venueid = $stateParams.venueid;
    vm.createList = function(valid) {
      vm.submitted = true;
      if (valid) {
        vm.list.apiKey = $stateParams.apiKey;
        Api.createList(vm.list).success(function(data) {
          console.log(data);
          $state.go('admin.mailchimp', {
            venueid: vm.venueid
          });
        }, function(err) {
          console.log(err);
        });
      }
    };
  }
}());
