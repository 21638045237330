(function () {
  'use strict';

  angular
    .module('userprofile')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.userprofile', {
        url: '/userprofile/:venueid/:userid/:providerid',
        templateUrl: 'userprofile/views/userprofile.tpl.html',
        controller: 'UserprofileCtrl',
        controllerAs: 'userprofile'
      });
  }
}());
