(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name addclservice.controller:AddclserviceCtrl
   *
   * @description
   *
   */
  angular
    .module('addclservice')
    .controller('AddclserviceCtrl', AddclserviceCtrl);

  function AddclserviceCtrl($state, $log, $scope, Cloudservices, $stateParams, Cloudservicetype, Api) {
    var vm = this;
    vm.ctrlName = 'AddclserviceCtrl';
    var venueid = $stateParams.venueid;

    // errors and messages
    vm.updatedApi = false;
    vm.errorMessage = false;
    vm.apiMessage = 'API Updated';
    vm.error = '';

    // Objects
    vm.venueid = $stateParams.venueid;
    vm.cloudservicetypes = [];
    vm.selectedType = {};
    vm.cloudserviceDetails = {};
    vm.lists = [];

    Cloudservicetype.getCloudServiceTypes().success(function (data) {
      if (data.cloudServiceTypes) {
        vm.cloudservicetypes = data.cloudServiceTypes;
      } else {
        vm.error = 'Cloud Service types could not be located, if this message persists please submit a support ticket.';
        vm.errorMessage = true;
      }
    }).error(function (err) {
      vm.errorMessage = true;
      vm.error = 'Cloud Services error on-load, if this message persists please submit a support ticket.';
      console.log('Error: ', err);
    });

    vm.getLists = function () {
      vm.lists = [];
      Api.getLists(vm.apiKey).then(function (resp) {
        for (var i = 0; i < resp.data.lists.length; i++) {
          vm.lists.push({
            id: resp.data.lists[i].id,
            name: resp.data.lists[i].name
          });
        }
      }, function (err) {
        vm.errorMessage = true;
        vm.error = 'Cloud Services could not find valid list, check your api key is correct. If this message persists please submit a support ticket.';
        console.log('Error: ', err);
      });
    };

    vm.updateApiKey = function () {
      if (vm.apiKey) {
        vm.updatedApi = true;
        vm.getLists();
      } else {
        vm.newListId = '';
        vm.lists = [];
      }
    };

    vm.createCloudService = function (isValid, cloudService) {
      vm.submitted = true;
      var thisCldSrv = cloudService;
      thisCldSrv.typeid = vm.selectedType.id;
      if (cloudService.phonenumber) {
        thisCldSrv.phonenumber = cloudService.phonenumber.replace('+', '');
      }
      // console.log(thisCldSrv);
      if (isValid) {
        Cloudservices.createCloudService($stateParams.venueid, thisCldSrv).then(function (resp) {
          console.log(resp);
          $state.go('admin.cloudservices', {
            'venueid': $stateParams.venueid
          });
        }, function (err) {
          console.log(err);
          vm.errorMessage = true;
          vm.error = 'Cloud Services could not create, check you have completed the required fields. If this message persists please submit a support ticket.';
        });
      }
    };

    vm.createNewList = function () {
      $state.go('admin.newlist', {
        apiKey: vm.apiKey,
        venueid: venueid
      });
    };

    vm.dismiss = function () {
      vm.updatedApi = false;
      vm.errorMessage = false;
      vm.error = '';
    };

  }
}());
