(function () {
  'use strict';

  angular
    .module('tags')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.tags', {
        url: '/segmentation/:venueid',
        templateUrl: 'tags/views/tags.tpl.html',
        controller: 'TagsCtrl',
        controllerAs: 'tags'
      });
  }
}());
