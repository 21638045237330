(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editclservice.controller:EditclserviceCtrl
   *
   * @description
   *
   */
  angular
    .module('editclservice')
    .controller('EditclserviceCtrl', EditclserviceCtrl);

  function EditclserviceCtrl($state, $log, $scope, $stateParams, Cloudservices, Cloudservicetype, Api) {
    var vm = this;
    vm.ctrlName = 'EditclserviceCtrl';

    var venueid = $stateParams.venueid;
    var cloudservicesid = $stateParams.cloudservicesid;

    // errors and messages
    vm.updatedApi = false;
    vm.errorMessage = false;
    vm.apiMessage = 'API Updated';
    vm.error = '';

    // Objects
    vm.venueid = $stateParams.venueid;
    vm.cloudserviceDetails = {};
    vm.lists = [];

    Cloudservices.getCloudServiceById(venueid, cloudservicesid).success(function (data) {
      vm.cloudserviceDetails = data.cloudService;
      console.log(vm.cloudserviceDetails);
      if(vm.cloudserviceDetails.type === 'MailChimp'){
        vm.getLists();
      }
    }).error(function (err) {
      console.log('Error: ', err);
    });

    vm.getLists = function(){
      vm.lists = [];
      Api.getLists(vm.cloudserviceDetails.apikey).then(function(resp) {
        for(var i=0; i<resp.data.lists.length; i++){
          vm.lists.push({
            id: resp.data.lists[i].id,
            name: resp.data.lists[i].name});
        }
      }, function(err) {
        vm.errorMessage = true;
        vm.error = 'Cloud Services could not find valid list, check your api key is correct. If this message persists please submit a support ticket.';
        console.log('Error: ', err);
      });
    };

    vm.updateApiKey = function() {
      if (vm.cloudserviceDetails.apikey) {
        vm.updatedApi = true;
        vm.getLists();
      } else {
        vm.cloudserviceDetails.listidentifier = '';
        vm.lists = [];
      }
    };

    vm.udpateCloudService = function (isValid, cloudService) {
      vm.submitted = true;
      var thisCldSrv = cloudService;
      if (cloudService.phonenumber) {
        thisCldSrv.phonenumber = cloudService.phonenumber.replace('+', '');
      }
      console.log(thisCldSrv);
      if (isValid) {
        Cloudservices.udpateCloudService($stateParams.venueid, $stateParams.cloudservicesid, thisCldSrv).then(function (resp) {
          console.log(resp);
          $state.go('admin.cloudservices', {
            'venueid': $stateParams.venueid
          });
        }, function (err) {
          console.log(err);
          vm.errorMessage = true;
          vm.error = 'Cloud Services could not update, check you have completed the required fields. If this message persists please submit a support ticket.';
        });
      }
    };

    vm.createNewList = function () {
      $state.go('admin.newlist', {
        apiKey: vm.cloudserviceDetails.apikey,
        venueid: venueid
      });
    };

    vm.dismiss = function () {
      vm.updatedApi = false;
      vm.errorMessage = false;
      vm.error = '';
    };

  }
}());
