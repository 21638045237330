(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name operators.controller:ModalCtrl
   *
   * @description
   *
   */
  angular
    .module('operators')
    .controller('EmailRetryModalCtrl', ModalCtrl);

  function ModalCtrl($modalInstance, user) {
    var vm = this;
    vm.user = user;
    vm.error = '';
    vm.ok = function () {
      if (vm.user.login === vm.user.compare) {
        $modalInstance.close();
      } else {
        vm.error = 'Email does not match operator.'
      }
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
