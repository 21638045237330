<form name="createEvent" novalidate>
  <h2 class="text-center">Create Event</h2>
  <div class="panel panel-default evcConf">
    <div class="panel-heading">
      <ul class="breadcrumb">
        <li ui-sref-active="active">
          <a ui-sref="admin.eventcreation.evcsetup" ng-show="eventcreation.setupPassed"><i class="fa fa-2x fa-sliders"></i></br>Setup</a>
          <a href="" ng-click="eventcreation.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, 'admin.eventcreation.evcsetup')" ng-show="!eventcreation.setupPassed"><i class="fa fa-2x fa-sliders"></i></br>Setup</a>
        </li>
        <li ui-sref-active="active">
          <a ui-sref="admin.eventcreation.evcnetwork" ng-show="eventcreation.netPassed"><i class="fa fa-2x fa-cogs"></i></br>Network</a>
          <a ng-click="eventcreation.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, 'admin.eventcreation.evcnetwork')" href="" ng-show="!eventcreation.netPassed"><i class="fa fa-2x fa-cogs"></i></br>Network</a>
        </li>
        <li ui-sref-active="active">
          <a ui-sref="admin.eventcreation.evcsplash" ng-hide="true"><i class="fa fa-2x fa-mobile"></i></br>Splash Page</a>
          <a ng-click="eventcreation.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, 'admin.eventcreation.evcsplash')" href=""><i class="fa fa-2x fa-mobile"></i></br>Splash Page</a>
        </li>
        <li ui-sref-active="active" ng-if="false">
          <a ui-sref="admin.eventcreation.evcfeature">
          Feature Page
        </a>
        </li>
        <li ui-sref-active="active" role="presentation">
          <a ui-sref="admin.eventcreation.evcconfirm" ng-hide="true"><i class="fa fa-2x fa-check-circle-o"></i></br>Confirm</a>
          <a href="" ng-click="eventcreation.nextStep({setUp : createEvent.eventname.$error, network : createEvent.networkName.$error, splash : createEvent.redirection.$error }, 'admin.eventcreation.evcconfirm')"><i class="fa fa-2x fa-check-circle-o"></i></br>Confirm</a>
        </li>
      </ul>
    </div>
    <div class="panel-body">
      <div data-ui-view autoscroll="true"></div>
    </div>
</form>
