(function () {
  'use strict';

  angular
    .module('duplicateevent')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.duplicateevent', {
        url: '/duplicateevent/:eventid/:venueid',
        templateUrl: 'duplicateevent/views/duplicateevent.tpl.html',
        controller: 'DuplicateeventCtrl',
        controllerAs: 'duplicateevent'
      }).state('admin.duplicateevent.duplicatesetup', {
        url: '/duplicatesetup',
        templateUrl: 'duplicateevent/views/duplicatesetup.tpl.html'
      })
      .state('admin.duplicateevent.duplicatenetwork', {
        url: '/duplicatenetwork',
        templateUrl: 'duplicateevent/views/duplicatenetwork.tpl.html'
      })
      .state('admin.duplicateevent.duplicatesplash', {
        url: '/duplicatesplash',
        templateUrl: 'duplicateevent/views/duplicatesplash.tpl.html'
      })
      .state('admin.duplicateevent.confirm', {
        url: '/duplicateconfirm',
        templateUrl: 'duplicateevent/views/duplicateconfirm.tpl.html'
      });
  }
}());
