<div class="panel-heading">
  <ul class="breadcrumb" role="tablist">
    <li role="presentation" class="active">
      <a href="#">
        Setup
      </a>
    </li>
    <li role="presentation" class="active">
      <a href="#">
        Network
      </a>
    </li>
    <li role="presentation" class="active">
      <a href="#">
        Splash Page
      </a>
    </li>
    <li role="presentation" class="active">
      <a href="#">
        Feature Page
      </a>
    </li>
  </ul>
</div>
<div class="panel-body">
  <div class="row">
    <div class="col-xs-7 col-sm-5 col-ms-5 pillar">
      <tabset class="tab-content">
        <tab ng-repeat="tab in eventcreation.tabs" class="tab-pane" heading="{{tab.title}}">
          <div ng-include="tab.content"></div>
        </tab>
      </tabset>
    </div>
    <div class="col-xs-11 col-sm-7 col-ms-7">
      <div ng-repeat="row in eventcreation.featurePage track by $index">
        <div ng-include="row.template"> </div>
      </div>
      <div class="col-xs-12" data-drop="true" ng-model="eventcreation.featurePage" data-jqyoui-options="" jqyoui-droppable="{multiple:true,onDrop: 'eventcreation.dropped()'}" style="min-height: 300px;">
        <img class= "tool-image-add" src="images/plus.png" alt="Image404" width="42" height=-"42">
      </div>
    </div>
  </div>

  <div class="row row-align button-end">
    <div class="pull-left">
    </div>
    <div class="pull-right">
      <button class="btn btn-default" ng-click="eventcreation.cancelCreate()">Cancel</button>
      <button class="btn btn-danger" data-ui-sref="admin.eventcreation.evcconfirm">Next Step</button>
    </div>
  </div>
</div>
