(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name tags.controller:TagupdateCtrl
   *
   * @description
   *
   */
  angular
    .module('tags')
    .controller('TagupdateCtrl', TagupdateCtrl);

  function TagupdateCtrl(Tags, data, $modalInstance, Api) {
    var vm = this;
    var tagid = data.tagid;
    var venueid = data.venueid;
    vm.oldTag = {};
    vm.newTag = {};
    vm.zones = [];
    vm.selectedZones = [];
    vm.oldZones = [];

    var getTag = function () {
      Tags.getTag(tagid).then(function (result) {
        vm.oldTag = JSON.parse(JSON.stringify(result.data));
        vm.newTag = JSON.parse(JSON.stringify(result.data));
        for (var i = 0; i < vm.newTag.zones.length; i++) {
          vm.selectedZones.push({
            'id': vm.newTag.zones[i].zoneId
          });
          vm.oldZones.push({
            'zoneId': vm.newTag.zones[i].zoneId
          });
        }
      });
    };

    var getZones = function () {
      Api.getZones(venueid).then(function (result) {
        console.log(result);
        vm.zones = result.data.zones;
        console.log(vm.zones);
      });
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };

    vm.ok = function () {
      vm.newTag.zones = [];
      for (var i = 0; i < vm.selectedZones.length; i++) {
        vm.newTag.zones.push({
          zoneId: vm.selectedZones[i].id
        });
      }
      var update = {
        update: false,
        name: vm.newTag.name,
        description: vm.newTag.description,
        duration: vm.newTag.duration,
        zones: vm.newTag.zones
      };

      var tagid = vm.newTag._id;

      console.log(vm.newTag);
      console.log(JSON.stringify(vm.oldZones));
      console.log(JSON.stringify(vm.newTag.zones));

      console.log(JSON.stringify(vm.oldZones) === JSON.stringify(vm.newTag.zones));

      if(JSON.stringify(vm.oldZones) !== JSON.stringify(vm.newTag.zones)){
        update.update = true;
      }

      if(vm.oldTag.duration !== vm.newTag.duration){
        update.update = true;
      }

      var result ={
        tagid:tagid,
        tag: update
      };
      $modalInstance.close(result);
    };

    getTag();
    getZones();
  }
}());
