(function () {
  'use strict';

  angular
    .module('cloudservices')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.cloudservices', {
        url: '/cloudservices/:venueid/:cloudservicesid',
        templateUrl: 'cloudservices/views/cloudservices.tpl.html',
        controller: 'CloudservicesCtrl',
        controllerAs: 'cloudservices'
      });
  }
}());
