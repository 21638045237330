(function () {
  'use strict';

  angular
    .module('mailchimp')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.mailchimp', {
        url: '/mailchimp/:venueid',
        templateUrl: 'mailchimp/views/mailchimp.tpl.html',
        controller: 'MailchimpCtrl',
        controllerAs: 'mailchimp'
      });
  }
}());
