(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name leoperations.factory:Twilio
   *
   * @description
   *
   */
  angular
    .module('leoperations')
    .factory('Twilio', Twilio);

  function Twilio(baseurl, baseendpoint, $http, Auth) {
    var TwilioBase = {};
    var token = Auth.getAuthData().token;
    var orgid = Auth.getAuthData().operator.organisation;

    TwilioBase.sendSMS = function(message){
      return $http.post(baseurl + baseendpoint + '/sms/sendmessage?org=' + orgid, message, {
        headers: {
          'x-access-token': token
        }
      });



    };

    return TwilioBase;
  }
}());
