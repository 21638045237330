(function () {
  'use strict';

  angular
    .module('editvenuemap')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.editvenuemap', {
        url: '/editvenuemap/:venueid/map/:mapId',
        templateUrl: 'editvenuemap/views/editvenuemap.tpl.html',
        controller: 'EditvenuemapCtrl',
        controllerAs: 'editvenuemap'
      });
  }
}());
