(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name editnetworktype.controller:EditnetworktypeCtrl
   *
   * @description
   *
   */
  angular
    .module('editnetworktype')
    .controller('EditnetworktypeCtrl', EditnetworktypeCtrl);

  function EditnetworktypeCtrl($stateParams, Api, $log, $state) {
    var vm = this;
    var venueid = $stateParams.venueid;
    var typeid = $stateParams.typeid;

    vm.networkType = {};
    vm.submitted = false;
    vm.selectedStrategies = [];

    vm.setProvider = function () {
      vm.provider = $stateParams.provider;
    };

    vm.updateNetworkType = function (valid) {
      vm.submitted = true;
      console.log(vm.networkType);
      if (valid) {
        var thisNetworkType = {};
        thisNetworkType.name = vm.networkType.name;
        thisNetworkType.vlanId = vm.networkType.vlanid;
        thisNetworkType.details = vm.networkType.details;
        thisNetworkType.networkStrategies = vm.selectedStrategies;
        Api.updateNetworkType(typeid, venueid, thisNetworkType).then(function (success) {
          $log.info(success);
          $state.go('admin.managenetwork', {
            venueid: venueid
          });
        }, function (err) {
          $log.info(err);
        });
      }
    };

    Api.getNetworkType(typeid, venueid).then(function (success) {
      $log.info(success);
      vm.networkType = success.data.networkType;
      for (var j = 0; j < vm.networkType.networkStrategies.length; j++) {
        vm.selectedStrategies.push(vm.networkType.networkStrategies[j].id);
      }
      console.log(vm.networkType);
      // console.log(vm.networkType.networkStrategies);
      // console.log(vm.ntstrategies);
    }, function (err) {
      $log.info(err);
    });
    vm.setProvider();
    Api.getCategories(vm.provider).then(function (data) {
      vm.ntstrategies = data.data.categories;
      $log.info(vm.ntstrategies);
    }, function (err) {
      $log.info('Error: ', err);
    });

    vm.cancel =function(){
      $state.go('admin.managenetwork', {
        venueid: venueid
      });
    };

  }
}());
