<div class="row">
  <div class="col-xs-9 col-sm-6 col-ms-6">
    <div class="panel panel-default panel-form">
      <div class="panel-heading clearfix">
        <h4>Survey</h4>
      </div>
      <div class="panel-body">
        <div class="form-group" ng-class="{ 'has-error' : eventcreation.stepThree}">
           Survey Enabled <input type="checkbox" id="surveyCheckbox" name="surveyCheckbox" ng-model="addsurvey.selectedSurvey"/>
        </div>
        <div class="form-group" ng-show="addsurvey.selectedSurvey">
          <div ng-repeat="survey in addsurvey.surveyObjects">
            <div class="panel panel-default panel-form" ng-show="addsurvey.surveyObjects[survey.id].show">
              <div class="panel-heading clearfix">
                <h5>Question:</h5>
                <input type="text" class="form-control" ng-model="addsurvey.surveyObjects[survey.id].survey.question" placeholder="eg. Which event are you on?" required>
              </div>
              <div class="panel-body">
                <div class="form-group">
                  <input type="text" class="form-control" ng-model="addsurvey.surveyObjects[survey.id].survey.answer1" placeholder="Option A">
                  <input type="text" class="form-control" ng-model="addsurvey.surveyObjects[survey.id].survey.answer2" placeholder="Option B">
                  <input type="text" class="form-control" ng-model="addsurvey.surveyObjects[survey.id].survey.answer3" placeholder="Option C">
                  <input type="text" class="form-control" ng-model="addsurvey.surveyObjects[survey.id].survey.answer4" placeholder="Option D">
               </div>
              </div>
            </div>
          </div>
          <div >
            <button ng-if="addsurvey.numberOfQuestions < addsurvey.numberOfQuestionsTotal" ng-click="addsurvey.addQuestion()" class="btn btn-danger"><i class="fa fa-plus" aria-hidden="true"></i></button>
            <button ng-if="addsurvey.numberOfQuestions > 0" ng-click="addsurvey.removeQuestion()" class="btn btn-danger"><i class="fa fa-minus" aria-hidden="true"></i></button>
            <button ng-if="addsurvey.numberOfQuestions > 0" ng-click="addsurvey.createSurvey()" class="btn btn-danger">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
