<div class="modal-header">
  <h4>{{bulkZoneCreateModal.controller.controlleraddress}}</h4>
</div>
<div class="modal-body">
  <div ng-show="bulkZoneCreateModal.error" class="center-error">
    <h5>{{bulkVenueCreateModal.error}}</h5>
  </div>
  <div ng-if="bulkZoneCreateModal.wlans.length < 1 && !bulkZoneCreateModal.error" class="centered">
    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    <span class="sr-only">Loading...</span>
  </div>
  <div ng-show="bulkZoneCreateModal.wlans.length > 0">
    <div class="scrollbox">
      <table class="table table-responsive eventsTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>WLAN</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody ng-repeat="wlan in bulkZoneCreateModal.wlans">
          <tr>
            <td>
              {{wlan._id}}
            </td>
            <td>
              {{wlan.name}}
            </td>
            <td>
              <button class="btn btn-danger" ng-show="wlan.status === 'Select'" ng-click="bulkZoneCreateModal.selectedSite(wlan)">{{wlan.status}}</button>
              <button class="btn btn-success" ng-show="wlan.status === 'Selected'" ng-click="bulkZoneCreateModal.deselectSite(wlan)">Select <i class="fa fa-check" aria-hidden="true"></i></button>
              <button class="btn btn-success" ng-show="wlan.status === 'Created'" disabled>{{wlan.status}}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr width="100%">
    <button class="btn btn-danger pull-right" ng-click="bulkZoneCreateModal.selectAll()">
      {{bulkZoneCreateModal.selectAllButton}}
    </button>
    </br>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" type="button" ng-click="bulkZoneCreateModal.bulkCreate()" ng-disabled="bulkZoneCreateModal.selected.length < 1">Create Selected</button>
  <button class="btn btn-warning" type="button" ng-click="bulkZoneCreateModal.cancel()">Cancel</button>
</div>
