(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name eventcreation.controller:CancelmodalCtrl
   *
   * @description
   *
   */
  angular
    .module('eventcreation')
    .controller('CancelmodalCtrl', CancelmodalCtrl);

  function CancelmodalCtrl($modalInstance) {
    var vm = this;
    vm.ctrlName = 'CancelmodalCtrl';

    vm.ok = function () {
      $modalInstance.close();
    };

    vm.cancel = function () {
      $modalInstance.dismiss();
    };
  }
}());
