(function () {
  'use strict';

  angular
    .module('eventanalytics')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('admin.eventanalytics', {
        url: '/eventanalytics/:venueid/:eventid/:class',
        templateUrl: 'eventanalytics/views/eventanalytics.tpl.html',
        controller: 'EventanalyticsCtrl',
        controllerAs: 'eventanalytics'
      });
  }
}());
