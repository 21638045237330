<div class="modal-header">
  <img class="modal-img" src="./images/login/modal_image.png">
</div>
<div class="modal-body">
  <h4>{{modal.mailList.listlength}} emails for this query</h4>
  <h4>add to mailchimp</h4>
  <div class="form-group">
    <label for="exampleInputEmail1">New List</label>
    <input type="text" class="form-control" id="list" placeholder="List Name">
  </div>
  <p>Or</p>
  <div class="form-group">
    <select class="form-control">
      <option value="">Choose Existing List</option>
      <option ng-repeat="list in modal.chimpList" value="{{list.id}}">{{list.name}}</option>
    </select>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-warning" type="button" ng-click="modal.cancel()">Cancel</button>
  <button class="btn btn-danger" type="button" ng-click="modal.ok()">Confirm</button>
</div>
